/**
 * MySQL error constants
 *
 * Extracted from version 5.7.29
 *
 * !! Generated by generate-error-constants.js, do not modify by hand !!
 */

exports.EE_CANTCREATEFILE                                                                = 1;
exports.EE_READ                                                                          = 2;
exports.EE_WRITE                                                                         = 3;
exports.EE_BADCLOSE                                                                      = 4;
exports.EE_OUTOFMEMORY                                                                   = 5;
exports.EE_DELETE                                                                        = 6;
exports.EE_LINK                                                                          = 7;
exports.EE_EOFERR                                                                        = 9;
exports.EE_CANTLOCK                                                                      = 10;
exports.EE_CANTUNLOCK                                                                    = 11;
exports.EE_DIR                                                                           = 12;
exports.EE_STAT                                                                          = 13;
exports.EE_CANT_CHSIZE                                                                   = 14;
exports.EE_CANT_OPEN_STREAM                                                              = 15;
exports.EE_GETWD                                                                         = 16;
exports.EE_SETWD                                                                         = 17;
exports.EE_LINK_WARNING                                                                  = 18;
exports.EE_OPEN_WARNING                                                                  = 19;
exports.EE_DISK_FULL                                                                     = 20;
exports.EE_CANT_MKDIR                                                                    = 21;
exports.EE_UNKNOWN_CHARSET                                                               = 22;
exports.EE_OUT_OF_FILERESOURCES                                                          = 23;
exports.EE_CANT_READLINK                                                                 = 24;
exports.EE_CANT_SYMLINK                                                                  = 25;
exports.EE_REALPATH                                                                      = 26;
exports.EE_SYNC                                                                          = 27;
exports.EE_UNKNOWN_COLLATION                                                             = 28;
exports.EE_FILENOTFOUND                                                                  = 29;
exports.EE_FILE_NOT_CLOSED                                                               = 30;
exports.EE_CHANGE_OWNERSHIP                                                              = 31;
exports.EE_CHANGE_PERMISSIONS                                                            = 32;
exports.EE_CANT_SEEK                                                                     = 33;
exports.EE_CAPACITY_EXCEEDED                                                             = 34;
exports.HA_ERR_KEY_NOT_FOUND                                                             = 120;
exports.HA_ERR_FOUND_DUPP_KEY                                                            = 121;
exports.HA_ERR_INTERNAL_ERROR                                                            = 122;
exports.HA_ERR_RECORD_CHANGED                                                            = 123;
exports.HA_ERR_WRONG_INDEX                                                               = 124;
exports.HA_ERR_CRASHED                                                                   = 126;
exports.HA_ERR_WRONG_IN_RECORD                                                           = 127;
exports.HA_ERR_OUT_OF_MEM                                                                = 128;
exports.HA_ERR_NOT_A_TABLE                                                               = 130;
exports.HA_ERR_WRONG_COMMAND                                                             = 131;
exports.HA_ERR_OLD_FILE                                                                  = 132;
exports.HA_ERR_NO_ACTIVE_RECORD                                                          = 133;
exports.HA_ERR_RECORD_DELETED                                                            = 134;
exports.HA_ERR_RECORD_FILE_FULL                                                          = 135;
exports.HA_ERR_INDEX_FILE_FULL                                                           = 136;
exports.HA_ERR_END_OF_FILE                                                               = 137;
exports.HA_ERR_UNSUPPORTED                                                               = 138;
exports.HA_ERR_TOO_BIG_ROW                                                               = 139;
exports.HA_WRONG_CREATE_OPTION                                                           = 140;
exports.HA_ERR_FOUND_DUPP_UNIQUE                                                         = 141;
exports.HA_ERR_UNKNOWN_CHARSET                                                           = 142;
exports.HA_ERR_WRONG_MRG_TABLE_DEF                                                       = 143;
exports.HA_ERR_CRASHED_ON_REPAIR                                                         = 144;
exports.HA_ERR_CRASHED_ON_USAGE                                                          = 145;
exports.HA_ERR_LOCK_WAIT_TIMEOUT                                                         = 146;
exports.HA_ERR_LOCK_TABLE_FULL                                                           = 147;
exports.HA_ERR_READ_ONLY_TRANSACTION                                                     = 148;
exports.HA_ERR_LOCK_DEADLOCK                                                             = 149;
exports.HA_ERR_CANNOT_ADD_FOREIGN                                                        = 150;
exports.HA_ERR_NO_REFERENCED_ROW                                                         = 151;
exports.HA_ERR_ROW_IS_REFERENCED                                                         = 152;
exports.HA_ERR_NO_SAVEPOINT                                                              = 153;
exports.HA_ERR_NON_UNIQUE_BLOCK_SIZE                                                     = 154;
exports.HA_ERR_NO_SUCH_TABLE                                                             = 155;
exports.HA_ERR_TABLE_EXIST                                                               = 156;
exports.HA_ERR_NO_CONNECTION                                                             = 157;
exports.HA_ERR_NULL_IN_SPATIAL                                                           = 158;
exports.HA_ERR_TABLE_DEF_CHANGED                                                         = 159;
exports.HA_ERR_NO_PARTITION_FOUND                                                        = 160;
exports.HA_ERR_RBR_LOGGING_FAILED                                                        = 161;
exports.HA_ERR_DROP_INDEX_FK                                                             = 162;
exports.HA_ERR_FOREIGN_DUPLICATE_KEY                                                     = 163;
exports.HA_ERR_TABLE_NEEDS_UPGRADE                                                       = 164;
exports.HA_ERR_TABLE_READONLY                                                            = 165;
exports.HA_ERR_AUTOINC_READ_FAILED                                                       = 166;
exports.HA_ERR_AUTOINC_ERANGE                                                            = 167;
exports.HA_ERR_GENERIC                                                                   = 168;
exports.HA_ERR_RECORD_IS_THE_SAME                                                        = 169;
exports.HA_ERR_LOGGING_IMPOSSIBLE                                                        = 170;
exports.HA_ERR_CORRUPT_EVENT                                                             = 171;
exports.HA_ERR_NEW_FILE                                                                  = 172;
exports.HA_ERR_ROWS_EVENT_APPLY                                                          = 173;
exports.HA_ERR_INITIALIZATION                                                            = 174;
exports.HA_ERR_FILE_TOO_SHORT                                                            = 175;
exports.HA_ERR_WRONG_CRC                                                                 = 176;
exports.HA_ERR_TOO_MANY_CONCURRENT_TRXS                                                  = 177;
exports.HA_ERR_NOT_IN_LOCK_PARTITIONS                                                    = 178;
exports.HA_ERR_INDEX_COL_TOO_LONG                                                        = 179;
exports.HA_ERR_INDEX_CORRUPT                                                             = 180;
exports.HA_ERR_UNDO_REC_TOO_BIG                                                          = 181;
exports.HA_FTS_INVALID_DOCID                                                             = 182;
exports.HA_ERR_TABLE_IN_FK_CHECK                                                         = 183;
exports.HA_ERR_TABLESPACE_EXISTS                                                         = 184;
exports.HA_ERR_TOO_MANY_FIELDS                                                           = 185;
exports.HA_ERR_ROW_IN_WRONG_PARTITION                                                    = 186;
exports.HA_ERR_INNODB_READ_ONLY                                                          = 187;
exports.HA_ERR_FTS_EXCEED_RESULT_CACHE_LIMIT                                             = 188;
exports.HA_ERR_TEMP_FILE_WRITE_FAILURE                                                   = 189;
exports.HA_ERR_INNODB_FORCED_RECOVERY                                                    = 190;
exports.HA_ERR_FTS_TOO_MANY_WORDS_IN_PHRASE                                              = 191;
exports.HA_ERR_FK_DEPTH_EXCEEDED                                                         = 192;
exports.HA_MISSING_CREATE_OPTION                                                         = 193;
exports.HA_ERR_SE_OUT_OF_MEMORY                                                          = 194;
exports.HA_ERR_TABLE_CORRUPT                                                             = 195;
exports.HA_ERR_QUERY_INTERRUPTED                                                         = 196;
exports.HA_ERR_TABLESPACE_MISSING                                                        = 197;
exports.HA_ERR_TABLESPACE_IS_NOT_EMPTY                                                   = 198;
exports.HA_ERR_WRONG_FILE_NAME                                                           = 199;
exports.HA_ERR_NOT_ALLOWED_COMMAND                                                       = 200;
exports.HA_ERR_COMPUTE_FAILED                                                            = 201;
exports.ER_HASHCHK                                                                       = 1000;
exports.ER_NISAMCHK                                                                      = 1001;
exports.ER_NO                                                                            = 1002;
exports.ER_YES                                                                           = 1003;
exports.ER_CANT_CREATE_FILE                                                              = 1004;
exports.ER_CANT_CREATE_TABLE                                                             = 1005;
exports.ER_CANT_CREATE_DB                                                                = 1006;
exports.ER_DB_CREATE_EXISTS                                                              = 1007;
exports.ER_DB_DROP_EXISTS                                                                = 1008;
exports.ER_DB_DROP_DELETE                                                                = 1009;
exports.ER_DB_DROP_RMDIR                                                                 = 1010;
exports.ER_CANT_DELETE_FILE                                                              = 1011;
exports.ER_CANT_FIND_SYSTEM_REC                                                          = 1012;
exports.ER_CANT_GET_STAT                                                                 = 1013;
exports.ER_CANT_GET_WD                                                                   = 1014;
exports.ER_CANT_LOCK                                                                     = 1015;
exports.ER_CANT_OPEN_FILE                                                                = 1016;
exports.ER_FILE_NOT_FOUND                                                                = 1017;
exports.ER_CANT_READ_DIR                                                                 = 1018;
exports.ER_CANT_SET_WD                                                                   = 1019;
exports.ER_CHECKREAD                                                                     = 1020;
exports.ER_DISK_FULL                                                                     = 1021;
exports.ER_DUP_KEY                                                                       = 1022;
exports.ER_ERROR_ON_CLOSE                                                                = 1023;
exports.ER_ERROR_ON_READ                                                                 = 1024;
exports.ER_ERROR_ON_RENAME                                                               = 1025;
exports.ER_ERROR_ON_WRITE                                                                = 1026;
exports.ER_FILE_USED                                                                     = 1027;
exports.ER_FILSORT_ABORT                                                                 = 1028;
exports.ER_FORM_NOT_FOUND                                                                = 1029;
exports.ER_GET_ERRNO                                                                     = 1030;
exports.ER_ILLEGAL_HA                                                                    = 1031;
exports.ER_KEY_NOT_FOUND                                                                 = 1032;
exports.ER_NOT_FORM_FILE                                                                 = 1033;
exports.ER_NOT_KEYFILE                                                                   = 1034;
exports.ER_OLD_KEYFILE                                                                   = 1035;
exports.ER_OPEN_AS_READONLY                                                              = 1036;
exports.ER_OUTOFMEMORY                                                                   = 1037;
exports.ER_OUT_OF_SORTMEMORY                                                             = 1038;
exports.ER_UNEXPECTED_EOF                                                                = 1039;
exports.ER_CON_COUNT_ERROR                                                               = 1040;
exports.ER_OUT_OF_RESOURCES                                                              = 1041;
exports.ER_BAD_HOST_ERROR                                                                = 1042;
exports.ER_HANDSHAKE_ERROR                                                               = 1043;
exports.ER_DBACCESS_DENIED_ERROR                                                         = 1044;
exports.ER_ACCESS_DENIED_ERROR                                                           = 1045;
exports.ER_NO_DB_ERROR                                                                   = 1046;
exports.ER_UNKNOWN_COM_ERROR                                                             = 1047;
exports.ER_BAD_NULL_ERROR                                                                = 1048;
exports.ER_BAD_DB_ERROR                                                                  = 1049;
exports.ER_TABLE_EXISTS_ERROR                                                            = 1050;
exports.ER_BAD_TABLE_ERROR                                                               = 1051;
exports.ER_NON_UNIQ_ERROR                                                                = 1052;
exports.ER_SERVER_SHUTDOWN                                                               = 1053;
exports.ER_BAD_FIELD_ERROR                                                               = 1054;
exports.ER_WRONG_FIELD_WITH_GROUP                                                        = 1055;
exports.ER_WRONG_GROUP_FIELD                                                             = 1056;
exports.ER_WRONG_SUM_SELECT                                                              = 1057;
exports.ER_WRONG_VALUE_COUNT                                                             = 1058;
exports.ER_TOO_LONG_IDENT                                                                = 1059;
exports.ER_DUP_FIELDNAME                                                                 = 1060;
exports.ER_DUP_KEYNAME                                                                   = 1061;
exports.ER_DUP_ENTRY                                                                     = 1062;
exports.ER_WRONG_FIELD_SPEC                                                              = 1063;
exports.ER_PARSE_ERROR                                                                   = 1064;
exports.ER_EMPTY_QUERY                                                                   = 1065;
exports.ER_NONUNIQ_TABLE                                                                 = 1066;
exports.ER_INVALID_DEFAULT                                                               = 1067;
exports.ER_MULTIPLE_PRI_KEY                                                              = 1068;
exports.ER_TOO_MANY_KEYS                                                                 = 1069;
exports.ER_TOO_MANY_KEY_PARTS                                                            = 1070;
exports.ER_TOO_LONG_KEY                                                                  = 1071;
exports.ER_KEY_COLUMN_DOES_NOT_EXITS                                                     = 1072;
exports.ER_BLOB_USED_AS_KEY                                                              = 1073;
exports.ER_TOO_BIG_FIELDLENGTH                                                           = 1074;
exports.ER_WRONG_AUTO_KEY                                                                = 1075;
exports.ER_READY                                                                         = 1076;
exports.ER_NORMAL_SHUTDOWN                                                               = 1077;
exports.ER_GOT_SIGNAL                                                                    = 1078;
exports.ER_SHUTDOWN_COMPLETE                                                             = 1079;
exports.ER_FORCING_CLOSE                                                                 = 1080;
exports.ER_IPSOCK_ERROR                                                                  = 1081;
exports.ER_NO_SUCH_INDEX                                                                 = 1082;
exports.ER_WRONG_FIELD_TERMINATORS                                                       = 1083;
exports.ER_BLOBS_AND_NO_TERMINATED                                                       = 1084;
exports.ER_TEXTFILE_NOT_READABLE                                                         = 1085;
exports.ER_FILE_EXISTS_ERROR                                                             = 1086;
exports.ER_LOAD_INFO                                                                     = 1087;
exports.ER_ALTER_INFO                                                                    = 1088;
exports.ER_WRONG_SUB_KEY                                                                 = 1089;
exports.ER_CANT_REMOVE_ALL_FIELDS                                                        = 1090;
exports.ER_CANT_DROP_FIELD_OR_KEY                                                        = 1091;
exports.ER_INSERT_INFO                                                                   = 1092;
exports.ER_UPDATE_TABLE_USED                                                             = 1093;
exports.ER_NO_SUCH_THREAD                                                                = 1094;
exports.ER_KILL_DENIED_ERROR                                                             = 1095;
exports.ER_NO_TABLES_USED                                                                = 1096;
exports.ER_TOO_BIG_SET                                                                   = 1097;
exports.ER_NO_UNIQUE_LOGFILE                                                             = 1098;
exports.ER_TABLE_NOT_LOCKED_FOR_WRITE                                                    = 1099;
exports.ER_TABLE_NOT_LOCKED                                                              = 1100;
exports.ER_BLOB_CANT_HAVE_DEFAULT                                                        = 1101;
exports.ER_WRONG_DB_NAME                                                                 = 1102;
exports.ER_WRONG_TABLE_NAME                                                              = 1103;
exports.ER_TOO_BIG_SELECT                                                                = 1104;
exports.ER_UNKNOWN_ERROR                                                                 = 1105;
exports.ER_UNKNOWN_PROCEDURE                                                             = 1106;
exports.ER_WRONG_PARAMCOUNT_TO_PROCEDURE                                                 = 1107;
exports.ER_WRONG_PARAMETERS_TO_PROCEDURE                                                 = 1108;
exports.ER_UNKNOWN_TABLE                                                                 = 1109;
exports.ER_FIELD_SPECIFIED_TWICE                                                         = 1110;
exports.ER_INVALID_GROUP_FUNC_USE                                                        = 1111;
exports.ER_UNSUPPORTED_EXTENSION                                                         = 1112;
exports.ER_TABLE_MUST_HAVE_COLUMNS                                                       = 1113;
exports.ER_RECORD_FILE_FULL                                                              = 1114;
exports.ER_UNKNOWN_CHARACTER_SET                                                         = 1115;
exports.ER_TOO_MANY_TABLES                                                               = 1116;
exports.ER_TOO_MANY_FIELDS                                                               = 1117;
exports.ER_TOO_BIG_ROWSIZE                                                               = 1118;
exports.ER_STACK_OVERRUN                                                                 = 1119;
exports.ER_WRONG_OUTER_JOIN                                                              = 1120;
exports.ER_NULL_COLUMN_IN_INDEX                                                          = 1121;
exports.ER_CANT_FIND_UDF                                                                 = 1122;
exports.ER_CANT_INITIALIZE_UDF                                                           = 1123;
exports.ER_UDF_NO_PATHS                                                                  = 1124;
exports.ER_UDF_EXISTS                                                                    = 1125;
exports.ER_CANT_OPEN_LIBRARY                                                             = 1126;
exports.ER_CANT_FIND_DL_ENTRY                                                            = 1127;
exports.ER_FUNCTION_NOT_DEFINED                                                          = 1128;
exports.ER_HOST_IS_BLOCKED                                                               = 1129;
exports.ER_HOST_NOT_PRIVILEGED                                                           = 1130;
exports.ER_PASSWORD_ANONYMOUS_USER                                                       = 1131;
exports.ER_PASSWORD_NOT_ALLOWED                                                          = 1132;
exports.ER_PASSWORD_NO_MATCH                                                             = 1133;
exports.ER_UPDATE_INFO                                                                   = 1134;
exports.ER_CANT_CREATE_THREAD                                                            = 1135;
exports.ER_WRONG_VALUE_COUNT_ON_ROW                                                      = 1136;
exports.ER_CANT_REOPEN_TABLE                                                             = 1137;
exports.ER_INVALID_USE_OF_NULL                                                           = 1138;
exports.ER_REGEXP_ERROR                                                                  = 1139;
exports.ER_MIX_OF_GROUP_FUNC_AND_FIELDS                                                  = 1140;
exports.ER_NONEXISTING_GRANT                                                             = 1141;
exports.ER_TABLEACCESS_DENIED_ERROR                                                      = 1142;
exports.ER_COLUMNACCESS_DENIED_ERROR                                                     = 1143;
exports.ER_ILLEGAL_GRANT_FOR_TABLE                                                       = 1144;
exports.ER_GRANT_WRONG_HOST_OR_USER                                                      = 1145;
exports.ER_NO_SUCH_TABLE                                                                 = 1146;
exports.ER_NONEXISTING_TABLE_GRANT                                                       = 1147;
exports.ER_NOT_ALLOWED_COMMAND                                                           = 1148;
exports.ER_SYNTAX_ERROR                                                                  = 1149;
exports.ER_DELAYED_CANT_CHANGE_LOCK                                                      = 1150;
exports.ER_TOO_MANY_DELAYED_THREADS                                                      = 1151;
exports.ER_ABORTING_CONNECTION                                                           = 1152;
exports.ER_NET_PACKET_TOO_LARGE                                                          = 1153;
exports.ER_NET_READ_ERROR_FROM_PIPE                                                      = 1154;
exports.ER_NET_FCNTL_ERROR                                                               = 1155;
exports.ER_NET_PACKETS_OUT_OF_ORDER                                                      = 1156;
exports.ER_NET_UNCOMPRESS_ERROR                                                          = 1157;
exports.ER_NET_READ_ERROR                                                                = 1158;
exports.ER_NET_READ_INTERRUPTED                                                          = 1159;
exports.ER_NET_ERROR_ON_WRITE                                                            = 1160;
exports.ER_NET_WRITE_INTERRUPTED                                                         = 1161;
exports.ER_TOO_LONG_STRING                                                               = 1162;
exports.ER_TABLE_CANT_HANDLE_BLOB                                                        = 1163;
exports.ER_TABLE_CANT_HANDLE_AUTO_INCREMENT                                              = 1164;
exports.ER_DELAYED_INSERT_TABLE_LOCKED                                                   = 1165;
exports.ER_WRONG_COLUMN_NAME                                                             = 1166;
exports.ER_WRONG_KEY_COLUMN                                                              = 1167;
exports.ER_WRONG_MRG_TABLE                                                               = 1168;
exports.ER_DUP_UNIQUE                                                                    = 1169;
exports.ER_BLOB_KEY_WITHOUT_LENGTH                                                       = 1170;
exports.ER_PRIMARY_CANT_HAVE_NULL                                                        = 1171;
exports.ER_TOO_MANY_ROWS                                                                 = 1172;
exports.ER_REQUIRES_PRIMARY_KEY                                                          = 1173;
exports.ER_NO_RAID_COMPILED                                                              = 1174;
exports.ER_UPDATE_WITHOUT_KEY_IN_SAFE_MODE                                               = 1175;
exports.ER_KEY_DOES_NOT_EXITS                                                            = 1176;
exports.ER_CHECK_NO_SUCH_TABLE                                                           = 1177;
exports.ER_CHECK_NOT_IMPLEMENTED                                                         = 1178;
exports.ER_CANT_DO_THIS_DURING_AN_TRANSACTION                                            = 1179;
exports.ER_ERROR_DURING_COMMIT                                                           = 1180;
exports.ER_ERROR_DURING_ROLLBACK                                                         = 1181;
exports.ER_ERROR_DURING_FLUSH_LOGS                                                       = 1182;
exports.ER_ERROR_DURING_CHECKPOINT                                                       = 1183;
exports.ER_NEW_ABORTING_CONNECTION                                                       = 1184;
exports.ER_DUMP_NOT_IMPLEMENTED                                                          = 1185;
exports.ER_FLUSH_MASTER_BINLOG_CLOSED                                                    = 1186;
exports.ER_INDEX_REBUILD                                                                 = 1187;
exports.ER_MASTER                                                                        = 1188;
exports.ER_MASTER_NET_READ                                                               = 1189;
exports.ER_MASTER_NET_WRITE                                                              = 1190;
exports.ER_FT_MATCHING_KEY_NOT_FOUND                                                     = 1191;
exports.ER_LOCK_OR_ACTIVE_TRANSACTION                                                    = 1192;
exports.ER_UNKNOWN_SYSTEM_VARIABLE                                                       = 1193;
exports.ER_CRASHED_ON_USAGE                                                              = 1194;
exports.ER_CRASHED_ON_REPAIR                                                             = 1195;
exports.ER_WARNING_NOT_COMPLETE_ROLLBACK                                                 = 1196;
exports.ER_TRANS_CACHE_FULL                                                              = 1197;
exports.ER_SLAVE_MUST_STOP                                                               = 1198;
exports.ER_SLAVE_NOT_RUNNING                                                             = 1199;
exports.ER_BAD_SLAVE                                                                     = 1200;
exports.ER_MASTER_INFO                                                                   = 1201;
exports.ER_SLAVE_THREAD                                                                  = 1202;
exports.ER_TOO_MANY_USER_CONNECTIONS                                                     = 1203;
exports.ER_SET_CONSTANTS_ONLY                                                            = 1204;
exports.ER_LOCK_WAIT_TIMEOUT                                                             = 1205;
exports.ER_LOCK_TABLE_FULL                                                               = 1206;
exports.ER_READ_ONLY_TRANSACTION                                                         = 1207;
exports.ER_DROP_DB_WITH_READ_LOCK                                                        = 1208;
exports.ER_CREATE_DB_WITH_READ_LOCK                                                      = 1209;
exports.ER_WRONG_ARGUMENTS                                                               = 1210;
exports.ER_NO_PERMISSION_TO_CREATE_USER                                                  = 1211;
exports.ER_UNION_TABLES_IN_DIFFERENT_DIR                                                 = 1212;
exports.ER_LOCK_DEADLOCK                                                                 = 1213;
exports.ER_TABLE_CANT_HANDLE_FT                                                          = 1214;
exports.ER_CANNOT_ADD_FOREIGN                                                            = 1215;
exports.ER_NO_REFERENCED_ROW                                                             = 1216;
exports.ER_ROW_IS_REFERENCED                                                             = 1217;
exports.ER_CONNECT_TO_MASTER                                                             = 1218;
exports.ER_QUERY_ON_MASTER                                                               = 1219;
exports.ER_ERROR_WHEN_EXECUTING_COMMAND                                                  = 1220;
exports.ER_WRONG_USAGE                                                                   = 1221;
exports.ER_WRONG_NUMBER_OF_COLUMNS_IN_SELECT                                             = 1222;
exports.ER_CANT_UPDATE_WITH_READLOCK                                                     = 1223;
exports.ER_MIXING_NOT_ALLOWED                                                            = 1224;
exports.ER_DUP_ARGUMENT                                                                  = 1225;
exports.ER_USER_LIMIT_REACHED                                                            = 1226;
exports.ER_SPECIFIC_ACCESS_DENIED_ERROR                                                  = 1227;
exports.ER_LOCAL_VARIABLE                                                                = 1228;
exports.ER_GLOBAL_VARIABLE                                                               = 1229;
exports.ER_NO_DEFAULT                                                                    = 1230;
exports.ER_WRONG_VALUE_FOR_VAR                                                           = 1231;
exports.ER_WRONG_TYPE_FOR_VAR                                                            = 1232;
exports.ER_VAR_CANT_BE_READ                                                              = 1233;
exports.ER_CANT_USE_OPTION_HERE                                                          = 1234;
exports.ER_NOT_SUPPORTED_YET                                                             = 1235;
exports.ER_MASTER_FATAL_ERROR_READING_BINLOG                                             = 1236;
exports.ER_SLAVE_IGNORED_TABLE                                                           = 1237;
exports.ER_INCORRECT_GLOBAL_LOCAL_VAR                                                    = 1238;
exports.ER_WRONG_FK_DEF                                                                  = 1239;
exports.ER_KEY_REF_DO_NOT_MATCH_TABLE_REF                                                = 1240;
exports.ER_OPERAND_COLUMNS                                                               = 1241;
exports.ER_SUBQUERY_NO_1_ROW                                                             = 1242;
exports.ER_UNKNOWN_STMT_HANDLER                                                          = 1243;
exports.ER_CORRUPT_HELP_DB                                                               = 1244;
exports.ER_CYCLIC_REFERENCE                                                              = 1245;
exports.ER_AUTO_CONVERT                                                                  = 1246;
exports.ER_ILLEGAL_REFERENCE                                                             = 1247;
exports.ER_DERIVED_MUST_HAVE_ALIAS                                                       = 1248;
exports.ER_SELECT_REDUCED                                                                = 1249;
exports.ER_TABLENAME_NOT_ALLOWED_HERE                                                    = 1250;
exports.ER_NOT_SUPPORTED_AUTH_MODE                                                       = 1251;
exports.ER_SPATIAL_CANT_HAVE_NULL                                                        = 1252;
exports.ER_COLLATION_CHARSET_MISMATCH                                                    = 1253;
exports.ER_SLAVE_WAS_RUNNING                                                             = 1254;
exports.ER_SLAVE_WAS_NOT_RUNNING                                                         = 1255;
exports.ER_TOO_BIG_FOR_UNCOMPRESS                                                        = 1256;
exports.ER_ZLIB_Z_MEM_ERROR                                                              = 1257;
exports.ER_ZLIB_Z_BUF_ERROR                                                              = 1258;
exports.ER_ZLIB_Z_DATA_ERROR                                                             = 1259;
exports.ER_CUT_VALUE_GROUP_CONCAT                                                        = 1260;
exports.ER_WARN_TOO_FEW_RECORDS                                                          = 1261;
exports.ER_WARN_TOO_MANY_RECORDS                                                         = 1262;
exports.ER_WARN_NULL_TO_NOTNULL                                                          = 1263;
exports.ER_WARN_DATA_OUT_OF_RANGE                                                        = 1264;
exports.WARN_DATA_TRUNCATED                                                              = 1265;
exports.ER_WARN_USING_OTHER_HANDLER                                                      = 1266;
exports.ER_CANT_AGGREGATE_2COLLATIONS                                                    = 1267;
exports.ER_DROP_USER                                                                     = 1268;
exports.ER_REVOKE_GRANTS                                                                 = 1269;
exports.ER_CANT_AGGREGATE_3COLLATIONS                                                    = 1270;
exports.ER_CANT_AGGREGATE_NCOLLATIONS                                                    = 1271;
exports.ER_VARIABLE_IS_NOT_STRUCT                                                        = 1272;
exports.ER_UNKNOWN_COLLATION                                                             = 1273;
exports.ER_SLAVE_IGNORED_SSL_PARAMS                                                      = 1274;
exports.ER_SERVER_IS_IN_SECURE_AUTH_MODE                                                 = 1275;
exports.ER_WARN_FIELD_RESOLVED                                                           = 1276;
exports.ER_BAD_SLAVE_UNTIL_COND                                                          = 1277;
exports.ER_MISSING_SKIP_SLAVE                                                            = 1278;
exports.ER_UNTIL_COND_IGNORED                                                            = 1279;
exports.ER_WRONG_NAME_FOR_INDEX                                                          = 1280;
exports.ER_WRONG_NAME_FOR_CATALOG                                                        = 1281;
exports.ER_WARN_QC_RESIZE                                                                = 1282;
exports.ER_BAD_FT_COLUMN                                                                 = 1283;
exports.ER_UNKNOWN_KEY_CACHE                                                             = 1284;
exports.ER_WARN_HOSTNAME_WONT_WORK                                                       = 1285;
exports.ER_UNKNOWN_STORAGE_ENGINE                                                        = 1286;
exports.ER_WARN_DEPRECATED_SYNTAX                                                        = 1287;
exports.ER_NON_UPDATABLE_TABLE                                                           = 1288;
exports.ER_FEATURE_DISABLED                                                              = 1289;
exports.ER_OPTION_PREVENTS_STATEMENT                                                     = 1290;
exports.ER_DUPLICATED_VALUE_IN_TYPE                                                      = 1291;
exports.ER_TRUNCATED_WRONG_VALUE                                                         = 1292;
exports.ER_TOO_MUCH_AUTO_TIMESTAMP_COLS                                                  = 1293;
exports.ER_INVALID_ON_UPDATE                                                             = 1294;
exports.ER_UNSUPPORTED_PS                                                                = 1295;
exports.ER_GET_ERRMSG                                                                    = 1296;
exports.ER_GET_TEMPORARY_ERRMSG                                                          = 1297;
exports.ER_UNKNOWN_TIME_ZONE                                                             = 1298;
exports.ER_WARN_INVALID_TIMESTAMP                                                        = 1299;
exports.ER_INVALID_CHARACTER_STRING                                                      = 1300;
exports.ER_WARN_ALLOWED_PACKET_OVERFLOWED                                                = 1301;
exports.ER_CONFLICTING_DECLARATIONS                                                      = 1302;
exports.ER_SP_NO_RECURSIVE_CREATE                                                        = 1303;
exports.ER_SP_ALREADY_EXISTS                                                             = 1304;
exports.ER_SP_DOES_NOT_EXIST                                                             = 1305;
exports.ER_SP_DROP_FAILED                                                                = 1306;
exports.ER_SP_STORE_FAILED                                                               = 1307;
exports.ER_SP_LILABEL_MISMATCH                                                           = 1308;
exports.ER_SP_LABEL_REDEFINE                                                             = 1309;
exports.ER_SP_LABEL_MISMATCH                                                             = 1310;
exports.ER_SP_UNINIT_VAR                                                                 = 1311;
exports.ER_SP_BADSELECT                                                                  = 1312;
exports.ER_SP_BADRETURN                                                                  = 1313;
exports.ER_SP_BADSTATEMENT                                                               = 1314;
exports.ER_UPDATE_LOG_DEPRECATED_IGNORED                                                 = 1315;
exports.ER_UPDATE_LOG_DEPRECATED_TRANSLATED                                              = 1316;
exports.ER_QUERY_INTERRUPTED                                                             = 1317;
exports.ER_SP_WRONG_NO_OF_ARGS                                                           = 1318;
exports.ER_SP_COND_MISMATCH                                                              = 1319;
exports.ER_SP_NORETURN                                                                   = 1320;
exports.ER_SP_NORETURNEND                                                                = 1321;
exports.ER_SP_BAD_CURSOR_QUERY                                                           = 1322;
exports.ER_SP_BAD_CURSOR_SELECT                                                          = 1323;
exports.ER_SP_CURSOR_MISMATCH                                                            = 1324;
exports.ER_SP_CURSOR_ALREADY_OPEN                                                        = 1325;
exports.ER_SP_CURSOR_NOT_OPEN                                                            = 1326;
exports.ER_SP_UNDECLARED_VAR                                                             = 1327;
exports.ER_SP_WRONG_NO_OF_FETCH_ARGS                                                     = 1328;
exports.ER_SP_FETCH_NO_DATA                                                              = 1329;
exports.ER_SP_DUP_PARAM                                                                  = 1330;
exports.ER_SP_DUP_VAR                                                                    = 1331;
exports.ER_SP_DUP_COND                                                                   = 1332;
exports.ER_SP_DUP_CURS                                                                   = 1333;
exports.ER_SP_CANT_ALTER                                                                 = 1334;
exports.ER_SP_SUBSELECT_NYI                                                              = 1335;
exports.ER_STMT_NOT_ALLOWED_IN_SF_OR_TRG                                                 = 1336;
exports.ER_SP_VARCOND_AFTER_CURSHNDLR                                                    = 1337;
exports.ER_SP_CURSOR_AFTER_HANDLER                                                       = 1338;
exports.ER_SP_CASE_NOT_FOUND                                                             = 1339;
exports.ER_FPARSER_TOO_BIG_FILE                                                          = 1340;
exports.ER_FPARSER_BAD_HEADER                                                            = 1341;
exports.ER_FPARSER_EOF_IN_COMMENT                                                        = 1342;
exports.ER_FPARSER_ERROR_IN_PARAMETER                                                    = 1343;
exports.ER_FPARSER_EOF_IN_UNKNOWN_PARAMETER                                              = 1344;
exports.ER_VIEW_NO_EXPLAIN                                                               = 1345;
exports.ER_FRM_UNKNOWN_TYPE                                                              = 1346;
exports.ER_WRONG_OBJECT                                                                  = 1347;
exports.ER_NONUPDATEABLE_COLUMN                                                          = 1348;
exports.ER_VIEW_SELECT_DERIVED                                                           = 1349;
exports.ER_VIEW_SELECT_CLAUSE                                                            = 1350;
exports.ER_VIEW_SELECT_VARIABLE                                                          = 1351;
exports.ER_VIEW_SELECT_TMPTABLE                                                          = 1352;
exports.ER_VIEW_WRONG_LIST                                                               = 1353;
exports.ER_WARN_VIEW_MERGE                                                               = 1354;
exports.ER_WARN_VIEW_WITHOUT_KEY                                                         = 1355;
exports.ER_VIEW_INVALID                                                                  = 1356;
exports.ER_SP_NO_DROP_SP                                                                 = 1357;
exports.ER_SP_GOTO_IN_HNDLR                                                              = 1358;
exports.ER_TRG_ALREADY_EXISTS                                                            = 1359;
exports.ER_TRG_DOES_NOT_EXIST                                                            = 1360;
exports.ER_TRG_ON_VIEW_OR_TEMP_TABLE                                                     = 1361;
exports.ER_TRG_CANT_CHANGE_ROW                                                           = 1362;
exports.ER_TRG_NO_SUCH_ROW_IN_TRG                                                        = 1363;
exports.ER_NO_DEFAULT_FOR_FIELD                                                          = 1364;
exports.ER_DIVISION_BY_ZERO                                                              = 1365;
exports.ER_TRUNCATED_WRONG_VALUE_FOR_FIELD                                               = 1366;
exports.ER_ILLEGAL_VALUE_FOR_TYPE                                                        = 1367;
exports.ER_VIEW_NONUPD_CHECK                                                             = 1368;
exports.ER_VIEW_CHECK_FAILED                                                             = 1369;
exports.ER_PROCACCESS_DENIED_ERROR                                                       = 1370;
exports.ER_RELAY_LOG_FAIL                                                                = 1371;
exports.ER_PASSWD_LENGTH                                                                 = 1372;
exports.ER_UNKNOWN_TARGET_BINLOG                                                         = 1373;
exports.ER_IO_ERR_LOG_INDEX_READ                                                         = 1374;
exports.ER_BINLOG_PURGE_PROHIBITED                                                       = 1375;
exports.ER_FSEEK_FAIL                                                                    = 1376;
exports.ER_BINLOG_PURGE_FATAL_ERR                                                        = 1377;
exports.ER_LOG_IN_USE                                                                    = 1378;
exports.ER_LOG_PURGE_UNKNOWN_ERR                                                         = 1379;
exports.ER_RELAY_LOG_INIT                                                                = 1380;
exports.ER_NO_BINARY_LOGGING                                                             = 1381;
exports.ER_RESERVED_SYNTAX                                                               = 1382;
exports.ER_WSAS_FAILED                                                                   = 1383;
exports.ER_DIFF_GROUPS_PROC                                                              = 1384;
exports.ER_NO_GROUP_FOR_PROC                                                             = 1385;
exports.ER_ORDER_WITH_PROC                                                               = 1386;
exports.ER_LOGGING_PROHIBIT_CHANGING_OF                                                  = 1387;
exports.ER_NO_FILE_MAPPING                                                               = 1388;
exports.ER_WRONG_MAGIC                                                                   = 1389;
exports.ER_PS_MANY_PARAM                                                                 = 1390;
exports.ER_KEY_PART_0                                                                    = 1391;
exports.ER_VIEW_CHECKSUM                                                                 = 1392;
exports.ER_VIEW_MULTIUPDATE                                                              = 1393;
exports.ER_VIEW_NO_INSERT_FIELD_LIST                                                     = 1394;
exports.ER_VIEW_DELETE_MERGE_VIEW                                                        = 1395;
exports.ER_CANNOT_USER                                                                   = 1396;
exports.ER_XAER_NOTA                                                                     = 1397;
exports.ER_XAER_INVAL                                                                    = 1398;
exports.ER_XAER_RMFAIL                                                                   = 1399;
exports.ER_XAER_OUTSIDE                                                                  = 1400;
exports.ER_XAER_RMERR                                                                    = 1401;
exports.ER_XA_RBROLLBACK                                                                 = 1402;
exports.ER_NONEXISTING_PROC_GRANT                                                        = 1403;
exports.ER_PROC_AUTO_GRANT_FAIL                                                          = 1404;
exports.ER_PROC_AUTO_REVOKE_FAIL                                                         = 1405;
exports.ER_DATA_TOO_LONG                                                                 = 1406;
exports.ER_SP_BAD_SQLSTATE                                                               = 1407;
exports.ER_STARTUP                                                                       = 1408;
exports.ER_LOAD_FROM_FIXED_SIZE_ROWS_TO_VAR                                              = 1409;
exports.ER_CANT_CREATE_USER_WITH_GRANT                                                   = 1410;
exports.ER_WRONG_VALUE_FOR_TYPE                                                          = 1411;
exports.ER_TABLE_DEF_CHANGED                                                             = 1412;
exports.ER_SP_DUP_HANDLER                                                                = 1413;
exports.ER_SP_NOT_VAR_ARG                                                                = 1414;
exports.ER_SP_NO_RETSET                                                                  = 1415;
exports.ER_CANT_CREATE_GEOMETRY_OBJECT                                                   = 1416;
exports.ER_FAILED_ROUTINE_BREAK_BINLOG                                                   = 1417;
exports.ER_BINLOG_UNSAFE_ROUTINE                                                         = 1418;
exports.ER_BINLOG_CREATE_ROUTINE_NEED_SUPER                                              = 1419;
exports.ER_EXEC_STMT_WITH_OPEN_CURSOR                                                    = 1420;
exports.ER_STMT_HAS_NO_OPEN_CURSOR                                                       = 1421;
exports.ER_COMMIT_NOT_ALLOWED_IN_SF_OR_TRG                                               = 1422;
exports.ER_NO_DEFAULT_FOR_VIEW_FIELD                                                     = 1423;
exports.ER_SP_NO_RECURSION                                                               = 1424;
exports.ER_TOO_BIG_SCALE                                                                 = 1425;
exports.ER_TOO_BIG_PRECISION                                                             = 1426;
exports.ER_M_BIGGER_THAN_D                                                               = 1427;
exports.ER_WRONG_LOCK_OF_SYSTEM_TABLE                                                    = 1428;
exports.ER_CONNECT_TO_FOREIGN_DATA_SOURCE                                                = 1429;
exports.ER_QUERY_ON_FOREIGN_DATA_SOURCE                                                  = 1430;
exports.ER_FOREIGN_DATA_SOURCE_DOESNT_EXIST                                              = 1431;
exports.ER_FOREIGN_DATA_STRING_INVALID_CANT_CREATE                                       = 1432;
exports.ER_FOREIGN_DATA_STRING_INVALID                                                   = 1433;
exports.ER_CANT_CREATE_FEDERATED_TABLE                                                   = 1434;
exports.ER_TRG_IN_WRONG_SCHEMA                                                           = 1435;
exports.ER_STACK_OVERRUN_NEED_MORE                                                       = 1436;
exports.ER_TOO_LONG_BODY                                                                 = 1437;
exports.ER_WARN_CANT_DROP_DEFAULT_KEYCACHE                                               = 1438;
exports.ER_TOO_BIG_DISPLAYWIDTH                                                          = 1439;
exports.ER_XAER_DUPID                                                                    = 1440;
exports.ER_DATETIME_FUNCTION_OVERFLOW                                                    = 1441;
exports.ER_CANT_UPDATE_USED_TABLE_IN_SF_OR_TRG                                           = 1442;
exports.ER_VIEW_PREVENT_UPDATE                                                           = 1443;
exports.ER_PS_NO_RECURSION                                                               = 1444;
exports.ER_SP_CANT_SET_AUTOCOMMIT                                                        = 1445;
exports.ER_MALFORMED_DEFINER                                                             = 1446;
exports.ER_VIEW_FRM_NO_USER                                                              = 1447;
exports.ER_VIEW_OTHER_USER                                                               = 1448;
exports.ER_NO_SUCH_USER                                                                  = 1449;
exports.ER_FORBID_SCHEMA_CHANGE                                                          = 1450;
exports.ER_ROW_IS_REFERENCED_2                                                           = 1451;
exports.ER_NO_REFERENCED_ROW_2                                                           = 1452;
exports.ER_SP_BAD_VAR_SHADOW                                                             = 1453;
exports.ER_TRG_NO_DEFINER                                                                = 1454;
exports.ER_OLD_FILE_FORMAT                                                               = 1455;
exports.ER_SP_RECURSION_LIMIT                                                            = 1456;
exports.ER_SP_PROC_TABLE_CORRUPT                                                         = 1457;
exports.ER_SP_WRONG_NAME                                                                 = 1458;
exports.ER_TABLE_NEEDS_UPGRADE                                                           = 1459;
exports.ER_SP_NO_AGGREGATE                                                               = 1460;
exports.ER_MAX_PREPARED_STMT_COUNT_REACHED                                               = 1461;
exports.ER_VIEW_RECURSIVE                                                                = 1462;
exports.ER_NON_GROUPING_FIELD_USED                                                       = 1463;
exports.ER_TABLE_CANT_HANDLE_SPKEYS                                                      = 1464;
exports.ER_NO_TRIGGERS_ON_SYSTEM_SCHEMA                                                  = 1465;
exports.ER_REMOVED_SPACES                                                                = 1466;
exports.ER_AUTOINC_READ_FAILED                                                           = 1467;
exports.ER_USERNAME                                                                      = 1468;
exports.ER_HOSTNAME                                                                      = 1469;
exports.ER_WRONG_STRING_LENGTH                                                           = 1470;
exports.ER_NON_INSERTABLE_TABLE                                                          = 1471;
exports.ER_ADMIN_WRONG_MRG_TABLE                                                         = 1472;
exports.ER_TOO_HIGH_LEVEL_OF_NESTING_FOR_SELECT                                          = 1473;
exports.ER_NAME_BECOMES_EMPTY                                                            = 1474;
exports.ER_AMBIGUOUS_FIELD_TERM                                                          = 1475;
exports.ER_FOREIGN_SERVER_EXISTS                                                         = 1476;
exports.ER_FOREIGN_SERVER_DOESNT_EXIST                                                   = 1477;
exports.ER_ILLEGAL_HA_CREATE_OPTION                                                      = 1478;
exports.ER_PARTITION_REQUIRES_VALUES_ERROR                                               = 1479;
exports.ER_PARTITION_WRONG_VALUES_ERROR                                                  = 1480;
exports.ER_PARTITION_MAXVALUE_ERROR                                                      = 1481;
exports.ER_PARTITION_SUBPARTITION_ERROR                                                  = 1482;
exports.ER_PARTITION_SUBPART_MIX_ERROR                                                   = 1483;
exports.ER_PARTITION_WRONG_NO_PART_ERROR                                                 = 1484;
exports.ER_PARTITION_WRONG_NO_SUBPART_ERROR                                              = 1485;
exports.ER_WRONG_EXPR_IN_PARTITION_FUNC_ERROR                                            = 1486;
exports.ER_NO_CONST_EXPR_IN_RANGE_OR_LIST_ERROR                                          = 1487;
exports.ER_FIELD_NOT_FOUND_PART_ERROR                                                    = 1488;
exports.ER_LIST_OF_FIELDS_ONLY_IN_HASH_ERROR                                             = 1489;
exports.ER_INCONSISTENT_PARTITION_INFO_ERROR                                             = 1490;
exports.ER_PARTITION_FUNC_NOT_ALLOWED_ERROR                                              = 1491;
exports.ER_PARTITIONS_MUST_BE_DEFINED_ERROR                                              = 1492;
exports.ER_RANGE_NOT_INCREASING_ERROR                                                    = 1493;
exports.ER_INCONSISTENT_TYPE_OF_FUNCTIONS_ERROR                                          = 1494;
exports.ER_MULTIPLE_DEF_CONST_IN_LIST_PART_ERROR                                         = 1495;
exports.ER_PARTITION_ENTRY_ERROR                                                         = 1496;
exports.ER_MIX_HANDLER_ERROR                                                             = 1497;
exports.ER_PARTITION_NOT_DEFINED_ERROR                                                   = 1498;
exports.ER_TOO_MANY_PARTITIONS_ERROR                                                     = 1499;
exports.ER_SUBPARTITION_ERROR                                                            = 1500;
exports.ER_CANT_CREATE_HANDLER_FILE                                                      = 1501;
exports.ER_BLOB_FIELD_IN_PART_FUNC_ERROR                                                 = 1502;
exports.ER_UNIQUE_KEY_NEED_ALL_FIELDS_IN_PF                                              = 1503;
exports.ER_NO_PARTS_ERROR                                                                = 1504;
exports.ER_PARTITION_MGMT_ON_NONPARTITIONED                                              = 1505;
exports.ER_FOREIGN_KEY_ON_PARTITIONED                                                    = 1506;
exports.ER_DROP_PARTITION_NON_EXISTENT                                                   = 1507;
exports.ER_DROP_LAST_PARTITION                                                           = 1508;
exports.ER_COALESCE_ONLY_ON_HASH_PARTITION                                               = 1509;
exports.ER_REORG_HASH_ONLY_ON_SAME_NO                                                    = 1510;
exports.ER_REORG_NO_PARAM_ERROR                                                          = 1511;
exports.ER_ONLY_ON_RANGE_LIST_PARTITION                                                  = 1512;
exports.ER_ADD_PARTITION_SUBPART_ERROR                                                   = 1513;
exports.ER_ADD_PARTITION_NO_NEW_PARTITION                                                = 1514;
exports.ER_COALESCE_PARTITION_NO_PARTITION                                               = 1515;
exports.ER_REORG_PARTITION_NOT_EXIST                                                     = 1516;
exports.ER_SAME_NAME_PARTITION                                                           = 1517;
exports.ER_NO_BINLOG_ERROR                                                               = 1518;
exports.ER_CONSECUTIVE_REORG_PARTITIONS                                                  = 1519;
exports.ER_REORG_OUTSIDE_RANGE                                                           = 1520;
exports.ER_PARTITION_FUNCTION_FAILURE                                                    = 1521;
exports.ER_PART_STATE_ERROR                                                              = 1522;
exports.ER_LIMITED_PART_RANGE                                                            = 1523;
exports.ER_PLUGIN_IS_NOT_LOADED                                                          = 1524;
exports.ER_WRONG_VALUE                                                                   = 1525;
exports.ER_NO_PARTITION_FOR_GIVEN_VALUE                                                  = 1526;
exports.ER_FILEGROUP_OPTION_ONLY_ONCE                                                    = 1527;
exports.ER_CREATE_FILEGROUP_FAILED                                                       = 1528;
exports.ER_DROP_FILEGROUP_FAILED                                                         = 1529;
exports.ER_TABLESPACE_AUTO_EXTEND_ERROR                                                  = 1530;
exports.ER_WRONG_SIZE_NUMBER                                                             = 1531;
exports.ER_SIZE_OVERFLOW_ERROR                                                           = 1532;
exports.ER_ALTER_FILEGROUP_FAILED                                                        = 1533;
exports.ER_BINLOG_ROW_LOGGING_FAILED                                                     = 1534;
exports.ER_BINLOG_ROW_WRONG_TABLE_DEF                                                    = 1535;
exports.ER_BINLOG_ROW_RBR_TO_SBR                                                         = 1536;
exports.ER_EVENT_ALREADY_EXISTS                                                          = 1537;
exports.ER_EVENT_STORE_FAILED                                                            = 1538;
exports.ER_EVENT_DOES_NOT_EXIST                                                          = 1539;
exports.ER_EVENT_CANT_ALTER                                                              = 1540;
exports.ER_EVENT_DROP_FAILED                                                             = 1541;
exports.ER_EVENT_INTERVAL_NOT_POSITIVE_OR_TOO_BIG                                        = 1542;
exports.ER_EVENT_ENDS_BEFORE_STARTS                                                      = 1543;
exports.ER_EVENT_EXEC_TIME_IN_THE_PAST                                                   = 1544;
exports.ER_EVENT_OPEN_TABLE_FAILED                                                       = 1545;
exports.ER_EVENT_NEITHER_M_EXPR_NOR_M_AT                                                 = 1546;
exports.ER_COL_COUNT_DOESNT_MATCH_CORRUPTED                                              = 1547;
exports.ER_CANNOT_LOAD_FROM_TABLE                                                        = 1548;
exports.ER_EVENT_CANNOT_DELETE                                                           = 1549;
exports.ER_EVENT_COMPILE_ERROR                                                           = 1550;
exports.ER_EVENT_SAME_NAME                                                               = 1551;
exports.ER_EVENT_DATA_TOO_LONG                                                           = 1552;
exports.ER_DROP_INDEX_FK                                                                 = 1553;
exports.ER_WARN_DEPRECATED_SYNTAX_WITH_VER                                               = 1554;
exports.ER_CANT_WRITE_LOCK_LOG_TABLE                                                     = 1555;
exports.ER_CANT_LOCK_LOG_TABLE                                                           = 1556;
exports.ER_FOREIGN_DUPLICATE_KEY                                                         = 1557;
exports.ER_COL_COUNT_DOESNT_MATCH_PLEASE_UPDATE                                          = 1558;
exports.ER_TEMP_TABLE_PREVENTS_SWITCH_OUT_OF_RBR                                         = 1559;
exports.ER_STORED_FUNCTION_PREVENTS_SWITCH_BINLOG_FORMAT                                 = 1560;
exports.ER_NDB_CANT_SWITCH_BINLOG_FORMAT                                                 = 1561;
exports.ER_PARTITION_NO_TEMPORARY                                                        = 1562;
exports.ER_PARTITION_CONST_DOMAIN_ERROR                                                  = 1563;
exports.ER_PARTITION_FUNCTION_IS_NOT_ALLOWED                                             = 1564;
exports.ER_DDL_LOG_ERROR                                                                 = 1565;
exports.ER_NULL_IN_VALUES_LESS_THAN                                                      = 1566;
exports.ER_WRONG_PARTITION_NAME                                                          = 1567;
exports.ER_CANT_CHANGE_TX_CHARACTERISTICS                                                = 1568;
exports.ER_DUP_ENTRY_AUTOINCREMENT_CASE                                                  = 1569;
exports.ER_EVENT_MODIFY_QUEUE_ERROR                                                      = 1570;
exports.ER_EVENT_SET_VAR_ERROR                                                           = 1571;
exports.ER_PARTITION_MERGE_ERROR                                                         = 1572;
exports.ER_CANT_ACTIVATE_LOG                                                             = 1573;
exports.ER_RBR_NOT_AVAILABLE                                                             = 1574;
exports.ER_BASE64_DECODE_ERROR                                                           = 1575;
exports.ER_EVENT_RECURSION_FORBIDDEN                                                     = 1576;
exports.ER_EVENTS_DB_ERROR                                                               = 1577;
exports.ER_ONLY_INTEGERS_ALLOWED                                                         = 1578;
exports.ER_UNSUPORTED_LOG_ENGINE                                                         = 1579;
exports.ER_BAD_LOG_STATEMENT                                                             = 1580;
exports.ER_CANT_RENAME_LOG_TABLE                                                         = 1581;
exports.ER_WRONG_PARAMCOUNT_TO_NATIVE_FCT                                                = 1582;
exports.ER_WRONG_PARAMETERS_TO_NATIVE_FCT                                                = 1583;
exports.ER_WRONG_PARAMETERS_TO_STORED_FCT                                                = 1584;
exports.ER_NATIVE_FCT_NAME_COLLISION                                                     = 1585;
exports.ER_DUP_ENTRY_WITH_KEY_NAME                                                       = 1586;
exports.ER_BINLOG_PURGE_EMFILE                                                           = 1587;
exports.ER_EVENT_CANNOT_CREATE_IN_THE_PAST                                               = 1588;
exports.ER_EVENT_CANNOT_ALTER_IN_THE_PAST                                                = 1589;
exports.ER_SLAVE_INCIDENT                                                                = 1590;
exports.ER_NO_PARTITION_FOR_GIVEN_VALUE_SILENT                                           = 1591;
exports.ER_BINLOG_UNSAFE_STATEMENT                                                       = 1592;
exports.ER_SLAVE_FATAL_ERROR                                                             = 1593;
exports.ER_SLAVE_RELAY_LOG_READ_FAILURE                                                  = 1594;
exports.ER_SLAVE_RELAY_LOG_WRITE_FAILURE                                                 = 1595;
exports.ER_SLAVE_CREATE_EVENT_FAILURE                                                    = 1596;
exports.ER_SLAVE_MASTER_COM_FAILURE                                                      = 1597;
exports.ER_BINLOG_LOGGING_IMPOSSIBLE                                                     = 1598;
exports.ER_VIEW_NO_CREATION_CTX                                                          = 1599;
exports.ER_VIEW_INVALID_CREATION_CTX                                                     = 1600;
exports.ER_SR_INVALID_CREATION_CTX                                                       = 1601;
exports.ER_TRG_CORRUPTED_FILE                                                            = 1602;
exports.ER_TRG_NO_CREATION_CTX                                                           = 1603;
exports.ER_TRG_INVALID_CREATION_CTX                                                      = 1604;
exports.ER_EVENT_INVALID_CREATION_CTX                                                    = 1605;
exports.ER_TRG_CANT_OPEN_TABLE                                                           = 1606;
exports.ER_CANT_CREATE_SROUTINE                                                          = 1607;
exports.ER_NEVER_USED                                                                    = 1608;
exports.ER_NO_FORMAT_DESCRIPTION_EVENT_BEFORE_BINLOG_STATEMENT                           = 1609;
exports.ER_SLAVE_CORRUPT_EVENT                                                           = 1610;
exports.ER_LOAD_DATA_INVALID_COLUMN                                                      = 1611;
exports.ER_LOG_PURGE_NO_FILE                                                             = 1612;
exports.ER_XA_RBTIMEOUT                                                                  = 1613;
exports.ER_XA_RBDEADLOCK                                                                 = 1614;
exports.ER_NEED_REPREPARE                                                                = 1615;
exports.ER_DELAYED_NOT_SUPPORTED                                                         = 1616;
exports.WARN_NO_MASTER_INFO                                                              = 1617;
exports.WARN_OPTION_IGNORED                                                              = 1618;
exports.ER_PLUGIN_DELETE_BUILTIN                                                         = 1619;
exports.WARN_PLUGIN_BUSY                                                                 = 1620;
exports.ER_VARIABLE_IS_READONLY                                                          = 1621;
exports.ER_WARN_ENGINE_TRANSACTION_ROLLBACK                                              = 1622;
exports.ER_SLAVE_HEARTBEAT_FAILURE                                                       = 1623;
exports.ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE                                            = 1624;
exports.ER_NDB_REPLICATION_SCHEMA_ERROR                                                  = 1625;
exports.ER_CONFLICT_FN_PARSE_ERROR                                                       = 1626;
exports.ER_EXCEPTIONS_WRITE_ERROR                                                        = 1627;
exports.ER_TOO_LONG_TABLE_COMMENT                                                        = 1628;
exports.ER_TOO_LONG_FIELD_COMMENT                                                        = 1629;
exports.ER_FUNC_INEXISTENT_NAME_COLLISION                                                = 1630;
exports.ER_DATABASE_NAME                                                                 = 1631;
exports.ER_TABLE_NAME                                                                    = 1632;
exports.ER_PARTITION_NAME                                                                = 1633;
exports.ER_SUBPARTITION_NAME                                                             = 1634;
exports.ER_TEMPORARY_NAME                                                                = 1635;
exports.ER_RENAMED_NAME                                                                  = 1636;
exports.ER_TOO_MANY_CONCURRENT_TRXS                                                      = 1637;
exports.WARN_NON_ASCII_SEPARATOR_NOT_IMPLEMENTED                                         = 1638;
exports.ER_DEBUG_SYNC_TIMEOUT                                                            = 1639;
exports.ER_DEBUG_SYNC_HIT_LIMIT                                                          = 1640;
exports.ER_DUP_SIGNAL_SET                                                                = 1641;
exports.ER_SIGNAL_WARN                                                                   = 1642;
exports.ER_SIGNAL_NOT_FOUND                                                              = 1643;
exports.ER_SIGNAL_EXCEPTION                                                              = 1644;
exports.ER_RESIGNAL_WITHOUT_ACTIVE_HANDLER                                               = 1645;
exports.ER_SIGNAL_BAD_CONDITION_TYPE                                                     = 1646;
exports.WARN_COND_ITEM_TRUNCATED                                                         = 1647;
exports.ER_COND_ITEM_TOO_LONG                                                            = 1648;
exports.ER_UNKNOWN_LOCALE                                                                = 1649;
exports.ER_SLAVE_IGNORE_SERVER_IDS                                                       = 1650;
exports.ER_QUERY_CACHE_DISABLED                                                          = 1651;
exports.ER_SAME_NAME_PARTITION_FIELD                                                     = 1652;
exports.ER_PARTITION_COLUMN_LIST_ERROR                                                   = 1653;
exports.ER_WRONG_TYPE_COLUMN_VALUE_ERROR                                                 = 1654;
exports.ER_TOO_MANY_PARTITION_FUNC_FIELDS_ERROR                                          = 1655;
exports.ER_MAXVALUE_IN_VALUES_IN                                                         = 1656;
exports.ER_TOO_MANY_VALUES_ERROR                                                         = 1657;
exports.ER_ROW_SINGLE_PARTITION_FIELD_ERROR                                              = 1658;
exports.ER_FIELD_TYPE_NOT_ALLOWED_AS_PARTITION_FIELD                                     = 1659;
exports.ER_PARTITION_FIELDS_TOO_LONG                                                     = 1660;
exports.ER_BINLOG_ROW_ENGINE_AND_STMT_ENGINE                                             = 1661;
exports.ER_BINLOG_ROW_MODE_AND_STMT_ENGINE                                               = 1662;
exports.ER_BINLOG_UNSAFE_AND_STMT_ENGINE                                                 = 1663;
exports.ER_BINLOG_ROW_INJECTION_AND_STMT_ENGINE                                          = 1664;
exports.ER_BINLOG_STMT_MODE_AND_ROW_ENGINE                                               = 1665;
exports.ER_BINLOG_ROW_INJECTION_AND_STMT_MODE                                            = 1666;
exports.ER_BINLOG_MULTIPLE_ENGINES_AND_SELF_LOGGING_ENGINE                               = 1667;
exports.ER_BINLOG_UNSAFE_LIMIT                                                           = 1668;
exports.ER_BINLOG_UNSAFE_INSERT_DELAYED                                                  = 1669;
exports.ER_BINLOG_UNSAFE_SYSTEM_TABLE                                                    = 1670;
exports.ER_BINLOG_UNSAFE_AUTOINC_COLUMNS                                                 = 1671;
exports.ER_BINLOG_UNSAFE_UDF                                                             = 1672;
exports.ER_BINLOG_UNSAFE_SYSTEM_VARIABLE                                                 = 1673;
exports.ER_BINLOG_UNSAFE_SYSTEM_FUNCTION                                                 = 1674;
exports.ER_BINLOG_UNSAFE_NONTRANS_AFTER_TRANS                                            = 1675;
exports.ER_MESSAGE_AND_STATEMENT                                                         = 1676;
exports.ER_SLAVE_CONVERSION_FAILED                                                       = 1677;
exports.ER_SLAVE_CANT_CREATE_CONVERSION                                                  = 1678;
exports.ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_BINLOG_FORMAT                              = 1679;
exports.ER_PATH_LENGTH                                                                   = 1680;
exports.ER_WARN_DEPRECATED_SYNTAX_NO_REPLACEMENT                                         = 1681;
exports.ER_WRONG_NATIVE_TABLE_STRUCTURE                                                  = 1682;
exports.ER_WRONG_PERFSCHEMA_USAGE                                                        = 1683;
exports.ER_WARN_I_S_SKIPPED_TABLE                                                        = 1684;
exports.ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_BINLOG_DIRECT                              = 1685;
exports.ER_STORED_FUNCTION_PREVENTS_SWITCH_BINLOG_DIRECT                                 = 1686;
exports.ER_SPATIAL_MUST_HAVE_GEOM_COL                                                    = 1687;
exports.ER_TOO_LONG_INDEX_COMMENT                                                        = 1688;
exports.ER_LOCK_ABORTED                                                                  = 1689;
exports.ER_DATA_OUT_OF_RANGE                                                             = 1690;
exports.ER_WRONG_SPVAR_TYPE_IN_LIMIT                                                     = 1691;
exports.ER_BINLOG_UNSAFE_MULTIPLE_ENGINES_AND_SELF_LOGGING_ENGINE                        = 1692;
exports.ER_BINLOG_UNSAFE_MIXED_STATEMENT                                                 = 1693;
exports.ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_SQL_LOG_BIN                                = 1694;
exports.ER_STORED_FUNCTION_PREVENTS_SWITCH_SQL_LOG_BIN                                   = 1695;
exports.ER_FAILED_READ_FROM_PAR_FILE                                                     = 1696;
exports.ER_VALUES_IS_NOT_INT_TYPE_ERROR                                                  = 1697;
exports.ER_ACCESS_DENIED_NO_PASSWORD_ERROR                                               = 1698;
exports.ER_SET_PASSWORD_AUTH_PLUGIN                                                      = 1699;
exports.ER_GRANT_PLUGIN_USER_EXISTS                                                      = 1700;
exports.ER_TRUNCATE_ILLEGAL_FK                                                           = 1701;
exports.ER_PLUGIN_IS_PERMANENT                                                           = 1702;
exports.ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE_MIN                                        = 1703;
exports.ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE_MAX                                        = 1704;
exports.ER_STMT_CACHE_FULL                                                               = 1705;
exports.ER_MULTI_UPDATE_KEY_CONFLICT                                                     = 1706;
exports.ER_TABLE_NEEDS_REBUILD                                                           = 1707;
exports.WARN_OPTION_BELOW_LIMIT                                                          = 1708;
exports.ER_INDEX_COLUMN_TOO_LONG                                                         = 1709;
exports.ER_ERROR_IN_TRIGGER_BODY                                                         = 1710;
exports.ER_ERROR_IN_UNKNOWN_TRIGGER_BODY                                                 = 1711;
exports.ER_INDEX_CORRUPT                                                                 = 1712;
exports.ER_UNDO_RECORD_TOO_BIG                                                           = 1713;
exports.ER_BINLOG_UNSAFE_INSERT_IGNORE_SELECT                                            = 1714;
exports.ER_BINLOG_UNSAFE_INSERT_SELECT_UPDATE                                            = 1715;
exports.ER_BINLOG_UNSAFE_REPLACE_SELECT                                                  = 1716;
exports.ER_BINLOG_UNSAFE_CREATE_IGNORE_SELECT                                            = 1717;
exports.ER_BINLOG_UNSAFE_CREATE_REPLACE_SELECT                                           = 1718;
exports.ER_BINLOG_UNSAFE_UPDATE_IGNORE                                                   = 1719;
exports.ER_PLUGIN_NO_UNINSTALL                                                           = 1720;
exports.ER_PLUGIN_NO_INSTALL                                                             = 1721;
exports.ER_BINLOG_UNSAFE_WRITE_AUTOINC_SELECT                                            = 1722;
exports.ER_BINLOG_UNSAFE_CREATE_SELECT_AUTOINC                                           = 1723;
exports.ER_BINLOG_UNSAFE_INSERT_TWO_KEYS                                                 = 1724;
exports.ER_TABLE_IN_FK_CHECK                                                             = 1725;
exports.ER_UNSUPPORTED_ENGINE                                                            = 1726;
exports.ER_BINLOG_UNSAFE_AUTOINC_NOT_FIRST                                               = 1727;
exports.ER_CANNOT_LOAD_FROM_TABLE_V2                                                     = 1728;
exports.ER_MASTER_DELAY_VALUE_OUT_OF_RANGE                                               = 1729;
exports.ER_ONLY_FD_AND_RBR_EVENTS_ALLOWED_IN_BINLOG_STATEMENT                            = 1730;
exports.ER_PARTITION_EXCHANGE_DIFFERENT_OPTION                                           = 1731;
exports.ER_PARTITION_EXCHANGE_PART_TABLE                                                 = 1732;
exports.ER_PARTITION_EXCHANGE_TEMP_TABLE                                                 = 1733;
exports.ER_PARTITION_INSTEAD_OF_SUBPARTITION                                             = 1734;
exports.ER_UNKNOWN_PARTITION                                                             = 1735;
exports.ER_TABLES_DIFFERENT_METADATA                                                     = 1736;
exports.ER_ROW_DOES_NOT_MATCH_PARTITION                                                  = 1737;
exports.ER_BINLOG_CACHE_SIZE_GREATER_THAN_MAX                                            = 1738;
exports.ER_WARN_INDEX_NOT_APPLICABLE                                                     = 1739;
exports.ER_PARTITION_EXCHANGE_FOREIGN_KEY                                                = 1740;
exports.ER_NO_SUCH_KEY_VALUE                                                             = 1741;
exports.ER_RPL_INFO_DATA_TOO_LONG                                                        = 1742;
exports.ER_NETWORK_READ_EVENT_CHECKSUM_FAILURE                                           = 1743;
exports.ER_BINLOG_READ_EVENT_CHECKSUM_FAILURE                                            = 1744;
exports.ER_BINLOG_STMT_CACHE_SIZE_GREATER_THAN_MAX                                       = 1745;
exports.ER_CANT_UPDATE_TABLE_IN_CREATE_TABLE_SELECT                                      = 1746;
exports.ER_PARTITION_CLAUSE_ON_NONPARTITIONED                                            = 1747;
exports.ER_ROW_DOES_NOT_MATCH_GIVEN_PARTITION_SET                                        = 1748;
exports.ER_NO_SUCH_PARTITION                                                             = 1749;
exports.ER_CHANGE_RPL_INFO_REPOSITORY_FAILURE                                            = 1750;
exports.ER_WARNING_NOT_COMPLETE_ROLLBACK_WITH_CREATED_TEMP_TABLE                         = 1751;
exports.ER_WARNING_NOT_COMPLETE_ROLLBACK_WITH_DROPPED_TEMP_TABLE                         = 1752;
exports.ER_MTS_FEATURE_IS_NOT_SUPPORTED                                                  = 1753;
exports.ER_MTS_UPDATED_DBS_GREATER_MAX                                                   = 1754;
exports.ER_MTS_CANT_PARALLEL                                                             = 1755;
exports.ER_MTS_INCONSISTENT_DATA                                                         = 1756;
exports.ER_FULLTEXT_NOT_SUPPORTED_WITH_PARTITIONING                                      = 1757;
exports.ER_DA_INVALID_CONDITION_NUMBER                                                   = 1758;
exports.ER_INSECURE_PLAIN_TEXT                                                           = 1759;
exports.ER_INSECURE_CHANGE_MASTER                                                        = 1760;
exports.ER_FOREIGN_DUPLICATE_KEY_WITH_CHILD_INFO                                         = 1761;
exports.ER_FOREIGN_DUPLICATE_KEY_WITHOUT_CHILD_INFO                                      = 1762;
exports.ER_SQLTHREAD_WITH_SECURE_SLAVE                                                   = 1763;
exports.ER_TABLE_HAS_NO_FT                                                               = 1764;
exports.ER_VARIABLE_NOT_SETTABLE_IN_SF_OR_TRIGGER                                        = 1765;
exports.ER_VARIABLE_NOT_SETTABLE_IN_TRANSACTION                                          = 1766;
exports.ER_GTID_NEXT_IS_NOT_IN_GTID_NEXT_LIST                                            = 1767;
exports.ER_CANT_CHANGE_GTID_NEXT_IN_TRANSACTION                                          = 1768;
exports.ER_SET_STATEMENT_CANNOT_INVOKE_FUNCTION                                          = 1769;
exports.ER_GTID_NEXT_CANT_BE_AUTOMATIC_IF_GTID_NEXT_LIST_IS_NON_NULL                     = 1770;
exports.ER_SKIPPING_LOGGED_TRANSACTION                                                   = 1771;
exports.ER_MALFORMED_GTID_SET_SPECIFICATION                                              = 1772;
exports.ER_MALFORMED_GTID_SET_ENCODING                                                   = 1773;
exports.ER_MALFORMED_GTID_SPECIFICATION                                                  = 1774;
exports.ER_GNO_EXHAUSTED                                                                 = 1775;
exports.ER_BAD_SLAVE_AUTO_POSITION                                                       = 1776;
exports.ER_AUTO_POSITION_REQUIRES_GTID_MODE_NOT_OFF                                      = 1777;
exports.ER_CANT_DO_IMPLICIT_COMMIT_IN_TRX_WHEN_GTID_NEXT_IS_SET                          = 1778;
exports.ER_GTID_MODE_ON_REQUIRES_ENFORCE_GTID_CONSISTENCY_ON                             = 1779;
exports.ER_GTID_MODE_REQUIRES_BINLOG                                                     = 1780;
exports.ER_CANT_SET_GTID_NEXT_TO_GTID_WHEN_GTID_MODE_IS_OFF                              = 1781;
exports.ER_CANT_SET_GTID_NEXT_TO_ANONYMOUS_WHEN_GTID_MODE_IS_ON                          = 1782;
exports.ER_CANT_SET_GTID_NEXT_LIST_TO_NON_NULL_WHEN_GTID_MODE_IS_OFF                     = 1783;
exports.ER_FOUND_GTID_EVENT_WHEN_GTID_MODE_IS_OFF                                        = 1784;
exports.ER_GTID_UNSAFE_NON_TRANSACTIONAL_TABLE                                           = 1785;
exports.ER_GTID_UNSAFE_CREATE_SELECT                                                     = 1786;
exports.ER_GTID_UNSAFE_CREATE_DROP_TEMPORARY_TABLE_IN_TRANSACTION                        = 1787;
exports.ER_GTID_MODE_CAN_ONLY_CHANGE_ONE_STEP_AT_A_TIME                                  = 1788;
exports.ER_MASTER_HAS_PURGED_REQUIRED_GTIDS                                              = 1789;
exports.ER_CANT_SET_GTID_NEXT_WHEN_OWNING_GTID                                           = 1790;
exports.ER_UNKNOWN_EXPLAIN_FORMAT                                                        = 1791;
exports.ER_CANT_EXECUTE_IN_READ_ONLY_TRANSACTION                                         = 1792;
exports.ER_TOO_LONG_TABLE_PARTITION_COMMENT                                              = 1793;
exports.ER_SLAVE_CONFIGURATION                                                           = 1794;
exports.ER_INNODB_FT_LIMIT                                                               = 1795;
exports.ER_INNODB_NO_FT_TEMP_TABLE                                                       = 1796;
exports.ER_INNODB_FT_WRONG_DOCID_COLUMN                                                  = 1797;
exports.ER_INNODB_FT_WRONG_DOCID_INDEX                                                   = 1798;
exports.ER_INNODB_ONLINE_LOG_TOO_BIG                                                     = 1799;
exports.ER_UNKNOWN_ALTER_ALGORITHM                                                       = 1800;
exports.ER_UNKNOWN_ALTER_LOCK                                                            = 1801;
exports.ER_MTS_CHANGE_MASTER_CANT_RUN_WITH_GAPS                                          = 1802;
exports.ER_MTS_RECOVERY_FAILURE                                                          = 1803;
exports.ER_MTS_RESET_WORKERS                                                             = 1804;
exports.ER_COL_COUNT_DOESNT_MATCH_CORRUPTED_V2                                           = 1805;
exports.ER_SLAVE_SILENT_RETRY_TRANSACTION                                                = 1806;
exports.ER_DISCARD_FK_CHECKS_RUNNING                                                     = 1807;
exports.ER_TABLE_SCHEMA_MISMATCH                                                         = 1808;
exports.ER_TABLE_IN_SYSTEM_TABLESPACE                                                    = 1809;
exports.ER_IO_READ_ERROR                                                                 = 1810;
exports.ER_IO_WRITE_ERROR                                                                = 1811;
exports.ER_TABLESPACE_MISSING                                                            = 1812;
exports.ER_TABLESPACE_EXISTS                                                             = 1813;
exports.ER_TABLESPACE_DISCARDED                                                          = 1814;
exports.ER_INTERNAL_ERROR                                                                = 1815;
exports.ER_INNODB_IMPORT_ERROR                                                           = 1816;
exports.ER_INNODB_INDEX_CORRUPT                                                          = 1817;
exports.ER_INVALID_YEAR_COLUMN_LENGTH                                                    = 1818;
exports.ER_NOT_VALID_PASSWORD                                                            = 1819;
exports.ER_MUST_CHANGE_PASSWORD                                                          = 1820;
exports.ER_FK_NO_INDEX_CHILD                                                             = 1821;
exports.ER_FK_NO_INDEX_PARENT                                                            = 1822;
exports.ER_FK_FAIL_ADD_SYSTEM                                                            = 1823;
exports.ER_FK_CANNOT_OPEN_PARENT                                                         = 1824;
exports.ER_FK_INCORRECT_OPTION                                                           = 1825;
exports.ER_FK_DUP_NAME                                                                   = 1826;
exports.ER_PASSWORD_FORMAT                                                               = 1827;
exports.ER_FK_COLUMN_CANNOT_DROP                                                         = 1828;
exports.ER_FK_COLUMN_CANNOT_DROP_CHILD                                                   = 1829;
exports.ER_FK_COLUMN_NOT_NULL                                                            = 1830;
exports.ER_DUP_INDEX                                                                     = 1831;
exports.ER_FK_COLUMN_CANNOT_CHANGE                                                       = 1832;
exports.ER_FK_COLUMN_CANNOT_CHANGE_CHILD                                                 = 1833;
exports.ER_FK_CANNOT_DELETE_PARENT                                                       = 1834;
exports.ER_MALFORMED_PACKET                                                              = 1835;
exports.ER_READ_ONLY_MODE                                                                = 1836;
exports.ER_GTID_NEXT_TYPE_UNDEFINED_GROUP                                                = 1837;
exports.ER_VARIABLE_NOT_SETTABLE_IN_SP                                                   = 1838;
exports.ER_CANT_SET_GTID_PURGED_WHEN_GTID_MODE_IS_OFF                                    = 1839;
exports.ER_CANT_SET_GTID_PURGED_WHEN_GTID_EXECUTED_IS_NOT_EMPTY                          = 1840;
exports.ER_CANT_SET_GTID_PURGED_WHEN_OWNED_GTIDS_IS_NOT_EMPTY                            = 1841;
exports.ER_GTID_PURGED_WAS_CHANGED                                                       = 1842;
exports.ER_GTID_EXECUTED_WAS_CHANGED                                                     = 1843;
exports.ER_BINLOG_STMT_MODE_AND_NO_REPL_TABLES                                           = 1844;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED                                                 = 1845;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON                                          = 1846;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_COPY                                     = 1847;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_PARTITION                                = 1848;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FK_RENAME                                = 1849;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_COLUMN_TYPE                              = 1850;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FK_CHECK                                 = 1851;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_IGNORE                                   = 1852;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_NOPK                                     = 1853;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_AUTOINC                                  = 1854;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_HIDDEN_FTS                               = 1855;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_CHANGE_FTS                               = 1856;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FTS                                      = 1857;
exports.ER_SQL_SLAVE_SKIP_COUNTER_NOT_SETTABLE_IN_GTID_MODE                              = 1858;
exports.ER_DUP_UNKNOWN_IN_INDEX                                                          = 1859;
exports.ER_IDENT_CAUSES_TOO_LONG_PATH                                                    = 1860;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_NOT_NULL                                 = 1861;
exports.ER_MUST_CHANGE_PASSWORD_LOGIN                                                    = 1862;
exports.ER_ROW_IN_WRONG_PARTITION                                                        = 1863;
exports.ER_MTS_EVENT_BIGGER_PENDING_JOBS_SIZE_MAX                                        = 1864;
exports.ER_INNODB_NO_FT_USES_PARSER                                                      = 1865;
exports.ER_BINLOG_LOGICAL_CORRUPTION                                                     = 1866;
exports.ER_WARN_PURGE_LOG_IN_USE                                                         = 1867;
exports.ER_WARN_PURGE_LOG_IS_ACTIVE                                                      = 1868;
exports.ER_AUTO_INCREMENT_CONFLICT                                                       = 1869;
exports.WARN_ON_BLOCKHOLE_IN_RBR                                                         = 1870;
exports.ER_SLAVE_MI_INIT_REPOSITORY                                                      = 1871;
exports.ER_SLAVE_RLI_INIT_REPOSITORY                                                     = 1872;
exports.ER_ACCESS_DENIED_CHANGE_USER_ERROR                                               = 1873;
exports.ER_INNODB_READ_ONLY                                                              = 1874;
exports.ER_STOP_SLAVE_SQL_THREAD_TIMEOUT                                                 = 1875;
exports.ER_STOP_SLAVE_IO_THREAD_TIMEOUT                                                  = 1876;
exports.ER_TABLE_CORRUPT                                                                 = 1877;
exports.ER_TEMP_FILE_WRITE_FAILURE                                                       = 1878;
exports.ER_INNODB_FT_AUX_NOT_HEX_ID                                                      = 1879;
exports.ER_OLD_TEMPORALS_UPGRADED                                                        = 1880;
exports.ER_INNODB_FORCED_RECOVERY                                                        = 1881;
exports.ER_AES_INVALID_IV                                                                = 1882;
exports.ER_PLUGIN_CANNOT_BE_UNINSTALLED                                                  = 1883;
exports.ER_GTID_UNSAFE_BINLOG_SPLITTABLE_STATEMENT_AND_GTID_GROUP                        = 1884;
exports.ER_SLAVE_HAS_MORE_GTIDS_THAN_MASTER                                              = 1885;
exports.ER_MISSING_KEY                                                                   = 1886;
exports.WARN_NAMED_PIPE_ACCESS_EVERYONE                                                  = 1887;
exports.ER_FOUND_MISSING_GTIDS                                                           = 1888;
exports.ER_FILE_CORRUPT                                                                  = 3000;
exports.ER_ERROR_ON_MASTER                                                               = 3001;
exports.ER_INCONSISTENT_ERROR                                                            = 3002;
exports.ER_STORAGE_ENGINE_NOT_LOADED                                                     = 3003;
exports.ER_GET_STACKED_DA_WITHOUT_ACTIVE_HANDLER                                         = 3004;
exports.ER_WARN_LEGACY_SYNTAX_CONVERTED                                                  = 3005;
exports.ER_BINLOG_UNSAFE_FULLTEXT_PLUGIN                                                 = 3006;
exports.ER_CANNOT_DISCARD_TEMPORARY_TABLE                                                = 3007;
exports.ER_FK_DEPTH_EXCEEDED                                                             = 3008;
exports.ER_COL_COUNT_DOESNT_MATCH_PLEASE_UPDATE_V2                                       = 3009;
exports.ER_WARN_TRIGGER_DOESNT_HAVE_CREATED                                              = 3010;
exports.ER_REFERENCED_TRG_DOES_NOT_EXIST                                                 = 3011;
exports.ER_EXPLAIN_NOT_SUPPORTED                                                         = 3012;
exports.ER_INVALID_FIELD_SIZE                                                            = 3013;
exports.ER_MISSING_HA_CREATE_OPTION                                                      = 3014;
exports.ER_ENGINE_OUT_OF_MEMORY                                                          = 3015;
exports.ER_PASSWORD_EXPIRE_ANONYMOUS_USER                                                = 3016;
exports.ER_SLAVE_SQL_THREAD_MUST_STOP                                                    = 3017;
exports.ER_NO_FT_MATERIALIZED_SUBQUERY                                                   = 3018;
exports.ER_INNODB_UNDO_LOG_FULL                                                          = 3019;
exports.ER_INVALID_ARGUMENT_FOR_LOGARITHM                                                = 3020;
exports.ER_SLAVE_CHANNEL_IO_THREAD_MUST_STOP                                             = 3021;
exports.ER_WARN_OPEN_TEMP_TABLES_MUST_BE_ZERO                                            = 3022;
exports.ER_WARN_ONLY_MASTER_LOG_FILE_NO_POS                                              = 3023;
exports.ER_QUERY_TIMEOUT                                                                 = 3024;
exports.ER_NON_RO_SELECT_DISABLE_TIMER                                                   = 3025;
exports.ER_DUP_LIST_ENTRY                                                                = 3026;
exports.ER_SQL_MODE_NO_EFFECT                                                            = 3027;
exports.ER_AGGREGATE_ORDER_FOR_UNION                                                     = 3028;
exports.ER_AGGREGATE_ORDER_NON_AGG_QUERY                                                 = 3029;
exports.ER_SLAVE_WORKER_STOPPED_PREVIOUS_THD_ERROR                                       = 3030;
exports.ER_DONT_SUPPORT_SLAVE_PRESERVE_COMMIT_ORDER                                      = 3031;
exports.ER_SERVER_OFFLINE_MODE                                                           = 3032;
exports.ER_GIS_DIFFERENT_SRIDS                                                           = 3033;
exports.ER_GIS_UNSUPPORTED_ARGUMENT                                                      = 3034;
exports.ER_GIS_UNKNOWN_ERROR                                                             = 3035;
exports.ER_GIS_UNKNOWN_EXCEPTION                                                         = 3036;
exports.ER_GIS_INVALID_DATA                                                              = 3037;
exports.ER_BOOST_GEOMETRY_EMPTY_INPUT_EXCEPTION                                          = 3038;
exports.ER_BOOST_GEOMETRY_CENTROID_EXCEPTION                                             = 3039;
exports.ER_BOOST_GEOMETRY_OVERLAY_INVALID_INPUT_EXCEPTION                                = 3040;
exports.ER_BOOST_GEOMETRY_TURN_INFO_EXCEPTION                                            = 3041;
exports.ER_BOOST_GEOMETRY_SELF_INTERSECTION_POINT_EXCEPTION                              = 3042;
exports.ER_BOOST_GEOMETRY_UNKNOWN_EXCEPTION                                              = 3043;
exports.ER_STD_BAD_ALLOC_ERROR                                                           = 3044;
exports.ER_STD_DOMAIN_ERROR                                                              = 3045;
exports.ER_STD_LENGTH_ERROR                                                              = 3046;
exports.ER_STD_INVALID_ARGUMENT                                                          = 3047;
exports.ER_STD_OUT_OF_RANGE_ERROR                                                        = 3048;
exports.ER_STD_OVERFLOW_ERROR                                                            = 3049;
exports.ER_STD_RANGE_ERROR                                                               = 3050;
exports.ER_STD_UNDERFLOW_ERROR                                                           = 3051;
exports.ER_STD_LOGIC_ERROR                                                               = 3052;
exports.ER_STD_RUNTIME_ERROR                                                             = 3053;
exports.ER_STD_UNKNOWN_EXCEPTION                                                         = 3054;
exports.ER_GIS_DATA_WRONG_ENDIANESS                                                      = 3055;
exports.ER_CHANGE_MASTER_PASSWORD_LENGTH                                                 = 3056;
exports.ER_USER_LOCK_WRONG_NAME                                                          = 3057;
exports.ER_USER_LOCK_DEADLOCK                                                            = 3058;
exports.ER_REPLACE_INACCESSIBLE_ROWS                                                     = 3059;
exports.ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_GIS                                      = 3060;
exports.ER_ILLEGAL_USER_VAR                                                              = 3061;
exports.ER_GTID_MODE_OFF                                                                 = 3062;
exports.ER_UNSUPPORTED_BY_REPLICATION_THREAD                                             = 3063;
exports.ER_INCORRECT_TYPE                                                                = 3064;
exports.ER_FIELD_IN_ORDER_NOT_SELECT                                                     = 3065;
exports.ER_AGGREGATE_IN_ORDER_NOT_SELECT                                                 = 3066;
exports.ER_INVALID_RPL_WILD_TABLE_FILTER_PATTERN                                         = 3067;
exports.ER_NET_OK_PACKET_TOO_LARGE                                                       = 3068;
exports.ER_INVALID_JSON_DATA                                                             = 3069;
exports.ER_INVALID_GEOJSON_MISSING_MEMBER                                                = 3070;
exports.ER_INVALID_GEOJSON_WRONG_TYPE                                                    = 3071;
exports.ER_INVALID_GEOJSON_UNSPECIFIED                                                   = 3072;
exports.ER_DIMENSION_UNSUPPORTED                                                         = 3073;
exports.ER_SLAVE_CHANNEL_DOES_NOT_EXIST                                                  = 3074;
exports.ER_SLAVE_MULTIPLE_CHANNELS_HOST_PORT                                             = 3075;
exports.ER_SLAVE_CHANNEL_NAME_INVALID_OR_TOO_LONG                                        = 3076;
exports.ER_SLAVE_NEW_CHANNEL_WRONG_REPOSITORY                                            = 3077;
exports.ER_SLAVE_CHANNEL_DELETE                                                          = 3078;
exports.ER_SLAVE_MULTIPLE_CHANNELS_CMD                                                   = 3079;
exports.ER_SLAVE_MAX_CHANNELS_EXCEEDED                                                   = 3080;
exports.ER_SLAVE_CHANNEL_MUST_STOP                                                       = 3081;
exports.ER_SLAVE_CHANNEL_NOT_RUNNING                                                     = 3082;
exports.ER_SLAVE_CHANNEL_WAS_RUNNING                                                     = 3083;
exports.ER_SLAVE_CHANNEL_WAS_NOT_RUNNING                                                 = 3084;
exports.ER_SLAVE_CHANNEL_SQL_THREAD_MUST_STOP                                            = 3085;
exports.ER_SLAVE_CHANNEL_SQL_SKIP_COUNTER                                                = 3086;
exports.ER_WRONG_FIELD_WITH_GROUP_V2                                                     = 3087;
exports.ER_MIX_OF_GROUP_FUNC_AND_FIELDS_V2                                               = 3088;
exports.ER_WARN_DEPRECATED_SYSVAR_UPDATE                                                 = 3089;
exports.ER_WARN_DEPRECATED_SQLMODE                                                       = 3090;
exports.ER_CANNOT_LOG_PARTIAL_DROP_DATABASE_WITH_GTID                                    = 3091;
exports.ER_GROUP_REPLICATION_CONFIGURATION                                               = 3092;
exports.ER_GROUP_REPLICATION_RUNNING                                                     = 3093;
exports.ER_GROUP_REPLICATION_APPLIER_INIT_ERROR                                          = 3094;
exports.ER_GROUP_REPLICATION_STOP_APPLIER_THREAD_TIMEOUT                                 = 3095;
exports.ER_GROUP_REPLICATION_COMMUNICATION_LAYER_SESSION_ERROR                           = 3096;
exports.ER_GROUP_REPLICATION_COMMUNICATION_LAYER_JOIN_ERROR                              = 3097;
exports.ER_BEFORE_DML_VALIDATION_ERROR                                                   = 3098;
exports.ER_PREVENTS_VARIABLE_WITHOUT_RBR                                                 = 3099;
exports.ER_RUN_HOOK_ERROR                                                                = 3100;
exports.ER_TRANSACTION_ROLLBACK_DURING_COMMIT                                            = 3101;
exports.ER_GENERATED_COLUMN_FUNCTION_IS_NOT_ALLOWED                                      = 3102;
exports.ER_UNSUPPORTED_ALTER_INPLACE_ON_VIRTUAL_COLUMN                                   = 3103;
exports.ER_WRONG_FK_OPTION_FOR_GENERATED_COLUMN                                          = 3104;
exports.ER_NON_DEFAULT_VALUE_FOR_GENERATED_COLUMN                                        = 3105;
exports.ER_UNSUPPORTED_ACTION_ON_GENERATED_COLUMN                                        = 3106;
exports.ER_GENERATED_COLUMN_NON_PRIOR                                                    = 3107;
exports.ER_DEPENDENT_BY_GENERATED_COLUMN                                                 = 3108;
exports.ER_GENERATED_COLUMN_REF_AUTO_INC                                                 = 3109;
exports.ER_FEATURE_NOT_AVAILABLE                                                         = 3110;
exports.ER_CANT_SET_GTID_MODE                                                            = 3111;
exports.ER_CANT_USE_AUTO_POSITION_WITH_GTID_MODE_OFF                                     = 3112;
exports.ER_CANT_REPLICATE_ANONYMOUS_WITH_AUTO_POSITION                                   = 3113;
exports.ER_CANT_REPLICATE_ANONYMOUS_WITH_GTID_MODE_ON                                    = 3114;
exports.ER_CANT_REPLICATE_GTID_WITH_GTID_MODE_OFF                                        = 3115;
exports.ER_CANT_SET_ENFORCE_GTID_CONSISTENCY_ON_WITH_ONGOING_GTID_VIOLATING_TRANSACTIONS = 3116;
exports.ER_SET_ENFORCE_GTID_CONSISTENCY_WARN_WITH_ONGOING_GTID_VIOLATING_TRANSACTIONS    = 3117;
exports.ER_ACCOUNT_HAS_BEEN_LOCKED                                                       = 3118;
exports.ER_WRONG_TABLESPACE_NAME                                                         = 3119;
exports.ER_TABLESPACE_IS_NOT_EMPTY                                                       = 3120;
exports.ER_WRONG_FILE_NAME                                                               = 3121;
exports.ER_BOOST_GEOMETRY_INCONSISTENT_TURNS_EXCEPTION                                   = 3122;
exports.ER_WARN_OPTIMIZER_HINT_SYNTAX_ERROR                                              = 3123;
exports.ER_WARN_BAD_MAX_EXECUTION_TIME                                                   = 3124;
exports.ER_WARN_UNSUPPORTED_MAX_EXECUTION_TIME                                           = 3125;
exports.ER_WARN_CONFLICTING_HINT                                                         = 3126;
exports.ER_WARN_UNKNOWN_QB_NAME                                                          = 3127;
exports.ER_UNRESOLVED_HINT_NAME                                                          = 3128;
exports.ER_WARN_ON_MODIFYING_GTID_EXECUTED_TABLE                                         = 3129;
exports.ER_PLUGGABLE_PROTOCOL_COMMAND_NOT_SUPPORTED                                      = 3130;
exports.ER_LOCKING_SERVICE_WRONG_NAME                                                    = 3131;
exports.ER_LOCKING_SERVICE_DEADLOCK                                                      = 3132;
exports.ER_LOCKING_SERVICE_TIMEOUT                                                       = 3133;
exports.ER_GIS_MAX_POINTS_IN_GEOMETRY_OVERFLOWED                                         = 3134;
exports.ER_SQL_MODE_MERGED                                                               = 3135;
exports.ER_VTOKEN_PLUGIN_TOKEN_MISMATCH                                                  = 3136;
exports.ER_VTOKEN_PLUGIN_TOKEN_NOT_FOUND                                                 = 3137;
exports.ER_CANT_SET_VARIABLE_WHEN_OWNING_GTID                                            = 3138;
exports.ER_SLAVE_CHANNEL_OPERATION_NOT_ALLOWED                                           = 3139;
exports.ER_INVALID_JSON_TEXT                                                             = 3140;
exports.ER_INVALID_JSON_TEXT_IN_PARAM                                                    = 3141;
exports.ER_INVALID_JSON_BINARY_DATA                                                      = 3142;
exports.ER_INVALID_JSON_PATH                                                             = 3143;
exports.ER_INVALID_JSON_CHARSET                                                          = 3144;
exports.ER_INVALID_JSON_CHARSET_IN_FUNCTION                                              = 3145;
exports.ER_INVALID_TYPE_FOR_JSON                                                         = 3146;
exports.ER_INVALID_CAST_TO_JSON                                                          = 3147;
exports.ER_INVALID_JSON_PATH_CHARSET                                                     = 3148;
exports.ER_INVALID_JSON_PATH_WILDCARD                                                    = 3149;
exports.ER_JSON_VALUE_TOO_BIG                                                            = 3150;
exports.ER_JSON_KEY_TOO_BIG                                                              = 3151;
exports.ER_JSON_USED_AS_KEY                                                              = 3152;
exports.ER_JSON_VACUOUS_PATH                                                             = 3153;
exports.ER_JSON_BAD_ONE_OR_ALL_ARG                                                       = 3154;
exports.ER_NUMERIC_JSON_VALUE_OUT_OF_RANGE                                               = 3155;
exports.ER_INVALID_JSON_VALUE_FOR_CAST                                                   = 3156;
exports.ER_JSON_DOCUMENT_TOO_DEEP                                                        = 3157;
exports.ER_JSON_DOCUMENT_NULL_KEY                                                        = 3158;
exports.ER_SECURE_TRANSPORT_REQUIRED                                                     = 3159;
exports.ER_NO_SECURE_TRANSPORTS_CONFIGURED                                               = 3160;
exports.ER_DISABLED_STORAGE_ENGINE                                                       = 3161;
exports.ER_USER_DOES_NOT_EXIST                                                           = 3162;
exports.ER_USER_ALREADY_EXISTS                                                           = 3163;
exports.ER_AUDIT_API_ABORT                                                               = 3164;
exports.ER_INVALID_JSON_PATH_ARRAY_CELL                                                  = 3165;
exports.ER_BUFPOOL_RESIZE_INPROGRESS                                                     = 3166;
exports.ER_FEATURE_DISABLED_SEE_DOC                                                      = 3167;
exports.ER_SERVER_ISNT_AVAILABLE                                                         = 3168;
exports.ER_SESSION_WAS_KILLED                                                            = 3169;
exports.ER_CAPACITY_EXCEEDED                                                             = 3170;
exports.ER_CAPACITY_EXCEEDED_IN_RANGE_OPTIMIZER                                          = 3171;
exports.ER_TABLE_NEEDS_UPG_PART                                                          = 3172;
exports.ER_CANT_WAIT_FOR_EXECUTED_GTID_SET_WHILE_OWNING_A_GTID                           = 3173;
exports.ER_CANNOT_ADD_FOREIGN_BASE_COL_VIRTUAL                                           = 3174;
exports.ER_CANNOT_CREATE_VIRTUAL_INDEX_CONSTRAINT                                        = 3175;
exports.ER_ERROR_ON_MODIFYING_GTID_EXECUTED_TABLE                                        = 3176;
exports.ER_LOCK_REFUSED_BY_ENGINE                                                        = 3177;
exports.ER_UNSUPPORTED_ALTER_ONLINE_ON_VIRTUAL_COLUMN                                    = 3178;
exports.ER_MASTER_KEY_ROTATION_NOT_SUPPORTED_BY_SE                                       = 3179;
exports.ER_MASTER_KEY_ROTATION_ERROR_BY_SE                                               = 3180;
exports.ER_MASTER_KEY_ROTATION_BINLOG_FAILED                                             = 3181;
exports.ER_MASTER_KEY_ROTATION_SE_UNAVAILABLE                                            = 3182;
exports.ER_TABLESPACE_CANNOT_ENCRYPT                                                     = 3183;
exports.ER_INVALID_ENCRYPTION_OPTION                                                     = 3184;
exports.ER_CANNOT_FIND_KEY_IN_KEYRING                                                    = 3185;
exports.ER_CAPACITY_EXCEEDED_IN_PARSER                                                   = 3186;
exports.ER_UNSUPPORTED_ALTER_ENCRYPTION_INPLACE                                          = 3187;
exports.ER_KEYRING_UDF_KEYRING_SERVICE_ERROR                                             = 3188;
exports.ER_USER_COLUMN_OLD_LENGTH                                                        = 3189;
exports.ER_CANT_RESET_MASTER                                                             = 3190;
exports.ER_GROUP_REPLICATION_MAX_GROUP_SIZE                                              = 3191;
exports.ER_CANNOT_ADD_FOREIGN_BASE_COL_STORED                                            = 3192;
exports.ER_TABLE_REFERENCED                                                              = 3193;
exports.ER_PARTITION_ENGINE_DEPRECATED_FOR_TABLE                                         = 3194;
exports.ER_WARN_USING_GEOMFROMWKB_TO_SET_SRID_ZERO                                       = 3195;
exports.ER_WARN_USING_GEOMFROMWKB_TO_SET_SRID                                            = 3196;
exports.ER_XA_RETRY                                                                      = 3197;
exports.ER_KEYRING_AWS_UDF_AWS_KMS_ERROR                                                 = 3198;
exports.ER_BINLOG_UNSAFE_XA                                                              = 3199;
exports.ER_UDF_ERROR                                                                     = 3200;
exports.ER_KEYRING_MIGRATION_FAILURE                                                     = 3201;
exports.ER_KEYRING_ACCESS_DENIED_ERROR                                                   = 3202;
exports.ER_KEYRING_MIGRATION_STATUS                                                      = 3203;
exports.ER_PLUGIN_FAILED_TO_OPEN_TABLES                                                  = 3204;
exports.ER_PLUGIN_FAILED_TO_OPEN_TABLE                                                   = 3205;
exports.ER_AUDIT_LOG_NO_KEYRING_PLUGIN_INSTALLED                                         = 3206;
exports.ER_AUDIT_LOG_ENCRYPTION_PASSWORD_HAS_NOT_BEEN_SET                                = 3207;
exports.ER_AUDIT_LOG_COULD_NOT_CREATE_AES_KEY                                            = 3208;
exports.ER_AUDIT_LOG_ENCRYPTION_PASSWORD_CANNOT_BE_FETCHED                               = 3209;
exports.ER_AUDIT_LOG_JSON_FILTERING_NOT_ENABLED                                          = 3210;
exports.ER_AUDIT_LOG_UDF_INSUFFICIENT_PRIVILEGE                                          = 3211;
exports.ER_AUDIT_LOG_SUPER_PRIVILEGE_REQUIRED                                            = 3212;
exports.ER_COULD_NOT_REINITIALIZE_AUDIT_LOG_FILTERS                                      = 3213;
exports.ER_AUDIT_LOG_UDF_INVALID_ARGUMENT_TYPE                                           = 3214;
exports.ER_AUDIT_LOG_UDF_INVALID_ARGUMENT_COUNT                                          = 3215;
exports.ER_AUDIT_LOG_HAS_NOT_BEEN_INSTALLED                                              = 3216;
exports.ER_AUDIT_LOG_UDF_READ_INVALID_MAX_ARRAY_LENGTH_ARG_TYPE                          = 3217;
exports.ER_AUDIT_LOG_UDF_READ_INVALID_MAX_ARRAY_LENGTH_ARG_VALUE                         = 3218;
exports.ER_AUDIT_LOG_JSON_FILTER_PARSING_ERROR                                           = 3219;
exports.ER_AUDIT_LOG_JSON_FILTER_NAME_CANNOT_BE_EMPTY                                    = 3220;
exports.ER_AUDIT_LOG_JSON_USER_NAME_CANNOT_BE_EMPTY                                      = 3221;
exports.ER_AUDIT_LOG_JSON_FILTER_DOES_NOT_EXISTS                                         = 3222;
exports.ER_AUDIT_LOG_USER_FIRST_CHARACTER_MUST_BE_ALPHANUMERIC                           = 3223;
exports.ER_AUDIT_LOG_USER_NAME_INVALID_CHARACTER                                         = 3224;
exports.ER_AUDIT_LOG_HOST_NAME_INVALID_CHARACTER                                         = 3225;
exports.WARN_DEPRECATED_MAXDB_SQL_MODE_FOR_TIMESTAMP                                     = 3226;
exports.ER_XA_REPLICATION_FILTERS                                                        = 3227;
exports.ER_CANT_OPEN_ERROR_LOG                                                           = 3228;
exports.ER_GROUPING_ON_TIMESTAMP_IN_DST                                                  = 3229;
exports.ER_CANT_START_SERVER_NAMED_PIPE                                                  = 3230;

// Lookup-by-number table
exports[1]    = 'EE_CANTCREATEFILE';
exports[2]    = 'EE_READ';
exports[3]    = 'EE_WRITE';
exports[4]    = 'EE_BADCLOSE';
exports[5]    = 'EE_OUTOFMEMORY';
exports[6]    = 'EE_DELETE';
exports[7]    = 'EE_LINK';
exports[9]    = 'EE_EOFERR';
exports[10]   = 'EE_CANTLOCK';
exports[11]   = 'EE_CANTUNLOCK';
exports[12]   = 'EE_DIR';
exports[13]   = 'EE_STAT';
exports[14]   = 'EE_CANT_CHSIZE';
exports[15]   = 'EE_CANT_OPEN_STREAM';
exports[16]   = 'EE_GETWD';
exports[17]   = 'EE_SETWD';
exports[18]   = 'EE_LINK_WARNING';
exports[19]   = 'EE_OPEN_WARNING';
exports[20]   = 'EE_DISK_FULL';
exports[21]   = 'EE_CANT_MKDIR';
exports[22]   = 'EE_UNKNOWN_CHARSET';
exports[23]   = 'EE_OUT_OF_FILERESOURCES';
exports[24]   = 'EE_CANT_READLINK';
exports[25]   = 'EE_CANT_SYMLINK';
exports[26]   = 'EE_REALPATH';
exports[27]   = 'EE_SYNC';
exports[28]   = 'EE_UNKNOWN_COLLATION';
exports[29]   = 'EE_FILENOTFOUND';
exports[30]   = 'EE_FILE_NOT_CLOSED';
exports[31]   = 'EE_CHANGE_OWNERSHIP';
exports[32]   = 'EE_CHANGE_PERMISSIONS';
exports[33]   = 'EE_CANT_SEEK';
exports[34]   = 'EE_CAPACITY_EXCEEDED';
exports[120]  = 'HA_ERR_KEY_NOT_FOUND';
exports[121]  = 'HA_ERR_FOUND_DUPP_KEY';
exports[122]  = 'HA_ERR_INTERNAL_ERROR';
exports[123]  = 'HA_ERR_RECORD_CHANGED';
exports[124]  = 'HA_ERR_WRONG_INDEX';
exports[126]  = 'HA_ERR_CRASHED';
exports[127]  = 'HA_ERR_WRONG_IN_RECORD';
exports[128]  = 'HA_ERR_OUT_OF_MEM';
exports[130]  = 'HA_ERR_NOT_A_TABLE';
exports[131]  = 'HA_ERR_WRONG_COMMAND';
exports[132]  = 'HA_ERR_OLD_FILE';
exports[133]  = 'HA_ERR_NO_ACTIVE_RECORD';
exports[134]  = 'HA_ERR_RECORD_DELETED';
exports[135]  = 'HA_ERR_RECORD_FILE_FULL';
exports[136]  = 'HA_ERR_INDEX_FILE_FULL';
exports[137]  = 'HA_ERR_END_OF_FILE';
exports[138]  = 'HA_ERR_UNSUPPORTED';
exports[139]  = 'HA_ERR_TOO_BIG_ROW';
exports[140]  = 'HA_WRONG_CREATE_OPTION';
exports[141]  = 'HA_ERR_FOUND_DUPP_UNIQUE';
exports[142]  = 'HA_ERR_UNKNOWN_CHARSET';
exports[143]  = 'HA_ERR_WRONG_MRG_TABLE_DEF';
exports[144]  = 'HA_ERR_CRASHED_ON_REPAIR';
exports[145]  = 'HA_ERR_CRASHED_ON_USAGE';
exports[146]  = 'HA_ERR_LOCK_WAIT_TIMEOUT';
exports[147]  = 'HA_ERR_LOCK_TABLE_FULL';
exports[148]  = 'HA_ERR_READ_ONLY_TRANSACTION';
exports[149]  = 'HA_ERR_LOCK_DEADLOCK';
exports[150]  = 'HA_ERR_CANNOT_ADD_FOREIGN';
exports[151]  = 'HA_ERR_NO_REFERENCED_ROW';
exports[152]  = 'HA_ERR_ROW_IS_REFERENCED';
exports[153]  = 'HA_ERR_NO_SAVEPOINT';
exports[154]  = 'HA_ERR_NON_UNIQUE_BLOCK_SIZE';
exports[155]  = 'HA_ERR_NO_SUCH_TABLE';
exports[156]  = 'HA_ERR_TABLE_EXIST';
exports[157]  = 'HA_ERR_NO_CONNECTION';
exports[158]  = 'HA_ERR_NULL_IN_SPATIAL';
exports[159]  = 'HA_ERR_TABLE_DEF_CHANGED';
exports[160]  = 'HA_ERR_NO_PARTITION_FOUND';
exports[161]  = 'HA_ERR_RBR_LOGGING_FAILED';
exports[162]  = 'HA_ERR_DROP_INDEX_FK';
exports[163]  = 'HA_ERR_FOREIGN_DUPLICATE_KEY';
exports[164]  = 'HA_ERR_TABLE_NEEDS_UPGRADE';
exports[165]  = 'HA_ERR_TABLE_READONLY';
exports[166]  = 'HA_ERR_AUTOINC_READ_FAILED';
exports[167]  = 'HA_ERR_AUTOINC_ERANGE';
exports[168]  = 'HA_ERR_GENERIC';
exports[169]  = 'HA_ERR_RECORD_IS_THE_SAME';
exports[170]  = 'HA_ERR_LOGGING_IMPOSSIBLE';
exports[171]  = 'HA_ERR_CORRUPT_EVENT';
exports[172]  = 'HA_ERR_NEW_FILE';
exports[173]  = 'HA_ERR_ROWS_EVENT_APPLY';
exports[174]  = 'HA_ERR_INITIALIZATION';
exports[175]  = 'HA_ERR_FILE_TOO_SHORT';
exports[176]  = 'HA_ERR_WRONG_CRC';
exports[177]  = 'HA_ERR_TOO_MANY_CONCURRENT_TRXS';
exports[178]  = 'HA_ERR_NOT_IN_LOCK_PARTITIONS';
exports[179]  = 'HA_ERR_INDEX_COL_TOO_LONG';
exports[180]  = 'HA_ERR_INDEX_CORRUPT';
exports[181]  = 'HA_ERR_UNDO_REC_TOO_BIG';
exports[182]  = 'HA_FTS_INVALID_DOCID';
exports[183]  = 'HA_ERR_TABLE_IN_FK_CHECK';
exports[184]  = 'HA_ERR_TABLESPACE_EXISTS';
exports[185]  = 'HA_ERR_TOO_MANY_FIELDS';
exports[186]  = 'HA_ERR_ROW_IN_WRONG_PARTITION';
exports[187]  = 'HA_ERR_INNODB_READ_ONLY';
exports[188]  = 'HA_ERR_FTS_EXCEED_RESULT_CACHE_LIMIT';
exports[189]  = 'HA_ERR_TEMP_FILE_WRITE_FAILURE';
exports[190]  = 'HA_ERR_INNODB_FORCED_RECOVERY';
exports[191]  = 'HA_ERR_FTS_TOO_MANY_WORDS_IN_PHRASE';
exports[192]  = 'HA_ERR_FK_DEPTH_EXCEEDED';
exports[193]  = 'HA_MISSING_CREATE_OPTION';
exports[194]  = 'HA_ERR_SE_OUT_OF_MEMORY';
exports[195]  = 'HA_ERR_TABLE_CORRUPT';
exports[196]  = 'HA_ERR_QUERY_INTERRUPTED';
exports[197]  = 'HA_ERR_TABLESPACE_MISSING';
exports[198]  = 'HA_ERR_TABLESPACE_IS_NOT_EMPTY';
exports[199]  = 'HA_ERR_WRONG_FILE_NAME';
exports[200]  = 'HA_ERR_NOT_ALLOWED_COMMAND';
exports[201]  = 'HA_ERR_COMPUTE_FAILED';
exports[1000] = 'ER_HASHCHK';
exports[1001] = 'ER_NISAMCHK';
exports[1002] = 'ER_NO';
exports[1003] = 'ER_YES';
exports[1004] = 'ER_CANT_CREATE_FILE';
exports[1005] = 'ER_CANT_CREATE_TABLE';
exports[1006] = 'ER_CANT_CREATE_DB';
exports[1007] = 'ER_DB_CREATE_EXISTS';
exports[1008] = 'ER_DB_DROP_EXISTS';
exports[1009] = 'ER_DB_DROP_DELETE';
exports[1010] = 'ER_DB_DROP_RMDIR';
exports[1011] = 'ER_CANT_DELETE_FILE';
exports[1012] = 'ER_CANT_FIND_SYSTEM_REC';
exports[1013] = 'ER_CANT_GET_STAT';
exports[1014] = 'ER_CANT_GET_WD';
exports[1015] = 'ER_CANT_LOCK';
exports[1016] = 'ER_CANT_OPEN_FILE';
exports[1017] = 'ER_FILE_NOT_FOUND';
exports[1018] = 'ER_CANT_READ_DIR';
exports[1019] = 'ER_CANT_SET_WD';
exports[1020] = 'ER_CHECKREAD';
exports[1021] = 'ER_DISK_FULL';
exports[1022] = 'ER_DUP_KEY';
exports[1023] = 'ER_ERROR_ON_CLOSE';
exports[1024] = 'ER_ERROR_ON_READ';
exports[1025] = 'ER_ERROR_ON_RENAME';
exports[1026] = 'ER_ERROR_ON_WRITE';
exports[1027] = 'ER_FILE_USED';
exports[1028] = 'ER_FILSORT_ABORT';
exports[1029] = 'ER_FORM_NOT_FOUND';
exports[1030] = 'ER_GET_ERRNO';
exports[1031] = 'ER_ILLEGAL_HA';
exports[1032] = 'ER_KEY_NOT_FOUND';
exports[1033] = 'ER_NOT_FORM_FILE';
exports[1034] = 'ER_NOT_KEYFILE';
exports[1035] = 'ER_OLD_KEYFILE';
exports[1036] = 'ER_OPEN_AS_READONLY';
exports[1037] = 'ER_OUTOFMEMORY';
exports[1038] = 'ER_OUT_OF_SORTMEMORY';
exports[1039] = 'ER_UNEXPECTED_EOF';
exports[1040] = 'ER_CON_COUNT_ERROR';
exports[1041] = 'ER_OUT_OF_RESOURCES';
exports[1042] = 'ER_BAD_HOST_ERROR';
exports[1043] = 'ER_HANDSHAKE_ERROR';
exports[1044] = 'ER_DBACCESS_DENIED_ERROR';
exports[1045] = 'ER_ACCESS_DENIED_ERROR';
exports[1046] = 'ER_NO_DB_ERROR';
exports[1047] = 'ER_UNKNOWN_COM_ERROR';
exports[1048] = 'ER_BAD_NULL_ERROR';
exports[1049] = 'ER_BAD_DB_ERROR';
exports[1050] = 'ER_TABLE_EXISTS_ERROR';
exports[1051] = 'ER_BAD_TABLE_ERROR';
exports[1052] = 'ER_NON_UNIQ_ERROR';
exports[1053] = 'ER_SERVER_SHUTDOWN';
exports[1054] = 'ER_BAD_FIELD_ERROR';
exports[1055] = 'ER_WRONG_FIELD_WITH_GROUP';
exports[1056] = 'ER_WRONG_GROUP_FIELD';
exports[1057] = 'ER_WRONG_SUM_SELECT';
exports[1058] = 'ER_WRONG_VALUE_COUNT';
exports[1059] = 'ER_TOO_LONG_IDENT';
exports[1060] = 'ER_DUP_FIELDNAME';
exports[1061] = 'ER_DUP_KEYNAME';
exports[1062] = 'ER_DUP_ENTRY';
exports[1063] = 'ER_WRONG_FIELD_SPEC';
exports[1064] = 'ER_PARSE_ERROR';
exports[1065] = 'ER_EMPTY_QUERY';
exports[1066] = 'ER_NONUNIQ_TABLE';
exports[1067] = 'ER_INVALID_DEFAULT';
exports[1068] = 'ER_MULTIPLE_PRI_KEY';
exports[1069] = 'ER_TOO_MANY_KEYS';
exports[1070] = 'ER_TOO_MANY_KEY_PARTS';
exports[1071] = 'ER_TOO_LONG_KEY';
exports[1072] = 'ER_KEY_COLUMN_DOES_NOT_EXITS';
exports[1073] = 'ER_BLOB_USED_AS_KEY';
exports[1074] = 'ER_TOO_BIG_FIELDLENGTH';
exports[1075] = 'ER_WRONG_AUTO_KEY';
exports[1076] = 'ER_READY';
exports[1077] = 'ER_NORMAL_SHUTDOWN';
exports[1078] = 'ER_GOT_SIGNAL';
exports[1079] = 'ER_SHUTDOWN_COMPLETE';
exports[1080] = 'ER_FORCING_CLOSE';
exports[1081] = 'ER_IPSOCK_ERROR';
exports[1082] = 'ER_NO_SUCH_INDEX';
exports[1083] = 'ER_WRONG_FIELD_TERMINATORS';
exports[1084] = 'ER_BLOBS_AND_NO_TERMINATED';
exports[1085] = 'ER_TEXTFILE_NOT_READABLE';
exports[1086] = 'ER_FILE_EXISTS_ERROR';
exports[1087] = 'ER_LOAD_INFO';
exports[1088] = 'ER_ALTER_INFO';
exports[1089] = 'ER_WRONG_SUB_KEY';
exports[1090] = 'ER_CANT_REMOVE_ALL_FIELDS';
exports[1091] = 'ER_CANT_DROP_FIELD_OR_KEY';
exports[1092] = 'ER_INSERT_INFO';
exports[1093] = 'ER_UPDATE_TABLE_USED';
exports[1094] = 'ER_NO_SUCH_THREAD';
exports[1095] = 'ER_KILL_DENIED_ERROR';
exports[1096] = 'ER_NO_TABLES_USED';
exports[1097] = 'ER_TOO_BIG_SET';
exports[1098] = 'ER_NO_UNIQUE_LOGFILE';
exports[1099] = 'ER_TABLE_NOT_LOCKED_FOR_WRITE';
exports[1100] = 'ER_TABLE_NOT_LOCKED';
exports[1101] = 'ER_BLOB_CANT_HAVE_DEFAULT';
exports[1102] = 'ER_WRONG_DB_NAME';
exports[1103] = 'ER_WRONG_TABLE_NAME';
exports[1104] = 'ER_TOO_BIG_SELECT';
exports[1105] = 'ER_UNKNOWN_ERROR';
exports[1106] = 'ER_UNKNOWN_PROCEDURE';
exports[1107] = 'ER_WRONG_PARAMCOUNT_TO_PROCEDURE';
exports[1108] = 'ER_WRONG_PARAMETERS_TO_PROCEDURE';
exports[1109] = 'ER_UNKNOWN_TABLE';
exports[1110] = 'ER_FIELD_SPECIFIED_TWICE';
exports[1111] = 'ER_INVALID_GROUP_FUNC_USE';
exports[1112] = 'ER_UNSUPPORTED_EXTENSION';
exports[1113] = 'ER_TABLE_MUST_HAVE_COLUMNS';
exports[1114] = 'ER_RECORD_FILE_FULL';
exports[1115] = 'ER_UNKNOWN_CHARACTER_SET';
exports[1116] = 'ER_TOO_MANY_TABLES';
exports[1117] = 'ER_TOO_MANY_FIELDS';
exports[1118] = 'ER_TOO_BIG_ROWSIZE';
exports[1119] = 'ER_STACK_OVERRUN';
exports[1120] = 'ER_WRONG_OUTER_JOIN';
exports[1121] = 'ER_NULL_COLUMN_IN_INDEX';
exports[1122] = 'ER_CANT_FIND_UDF';
exports[1123] = 'ER_CANT_INITIALIZE_UDF';
exports[1124] = 'ER_UDF_NO_PATHS';
exports[1125] = 'ER_UDF_EXISTS';
exports[1126] = 'ER_CANT_OPEN_LIBRARY';
exports[1127] = 'ER_CANT_FIND_DL_ENTRY';
exports[1128] = 'ER_FUNCTION_NOT_DEFINED';
exports[1129] = 'ER_HOST_IS_BLOCKED';
exports[1130] = 'ER_HOST_NOT_PRIVILEGED';
exports[1131] = 'ER_PASSWORD_ANONYMOUS_USER';
exports[1132] = 'ER_PASSWORD_NOT_ALLOWED';
exports[1133] = 'ER_PASSWORD_NO_MATCH';
exports[1134] = 'ER_UPDATE_INFO';
exports[1135] = 'ER_CANT_CREATE_THREAD';
exports[1136] = 'ER_WRONG_VALUE_COUNT_ON_ROW';
exports[1137] = 'ER_CANT_REOPEN_TABLE';
exports[1138] = 'ER_INVALID_USE_OF_NULL';
exports[1139] = 'ER_REGEXP_ERROR';
exports[1140] = 'ER_MIX_OF_GROUP_FUNC_AND_FIELDS';
exports[1141] = 'ER_NONEXISTING_GRANT';
exports[1142] = 'ER_TABLEACCESS_DENIED_ERROR';
exports[1143] = 'ER_COLUMNACCESS_DENIED_ERROR';
exports[1144] = 'ER_ILLEGAL_GRANT_FOR_TABLE';
exports[1145] = 'ER_GRANT_WRONG_HOST_OR_USER';
exports[1146] = 'ER_NO_SUCH_TABLE';
exports[1147] = 'ER_NONEXISTING_TABLE_GRANT';
exports[1148] = 'ER_NOT_ALLOWED_COMMAND';
exports[1149] = 'ER_SYNTAX_ERROR';
exports[1150] = 'ER_DELAYED_CANT_CHANGE_LOCK';
exports[1151] = 'ER_TOO_MANY_DELAYED_THREADS';
exports[1152] = 'ER_ABORTING_CONNECTION';
exports[1153] = 'ER_NET_PACKET_TOO_LARGE';
exports[1154] = 'ER_NET_READ_ERROR_FROM_PIPE';
exports[1155] = 'ER_NET_FCNTL_ERROR';
exports[1156] = 'ER_NET_PACKETS_OUT_OF_ORDER';
exports[1157] = 'ER_NET_UNCOMPRESS_ERROR';
exports[1158] = 'ER_NET_READ_ERROR';
exports[1159] = 'ER_NET_READ_INTERRUPTED';
exports[1160] = 'ER_NET_ERROR_ON_WRITE';
exports[1161] = 'ER_NET_WRITE_INTERRUPTED';
exports[1162] = 'ER_TOO_LONG_STRING';
exports[1163] = 'ER_TABLE_CANT_HANDLE_BLOB';
exports[1164] = 'ER_TABLE_CANT_HANDLE_AUTO_INCREMENT';
exports[1165] = 'ER_DELAYED_INSERT_TABLE_LOCKED';
exports[1166] = 'ER_WRONG_COLUMN_NAME';
exports[1167] = 'ER_WRONG_KEY_COLUMN';
exports[1168] = 'ER_WRONG_MRG_TABLE';
exports[1169] = 'ER_DUP_UNIQUE';
exports[1170] = 'ER_BLOB_KEY_WITHOUT_LENGTH';
exports[1171] = 'ER_PRIMARY_CANT_HAVE_NULL';
exports[1172] = 'ER_TOO_MANY_ROWS';
exports[1173] = 'ER_REQUIRES_PRIMARY_KEY';
exports[1174] = 'ER_NO_RAID_COMPILED';
exports[1175] = 'ER_UPDATE_WITHOUT_KEY_IN_SAFE_MODE';
exports[1176] = 'ER_KEY_DOES_NOT_EXITS';
exports[1177] = 'ER_CHECK_NO_SUCH_TABLE';
exports[1178] = 'ER_CHECK_NOT_IMPLEMENTED';
exports[1179] = 'ER_CANT_DO_THIS_DURING_AN_TRANSACTION';
exports[1180] = 'ER_ERROR_DURING_COMMIT';
exports[1181] = 'ER_ERROR_DURING_ROLLBACK';
exports[1182] = 'ER_ERROR_DURING_FLUSH_LOGS';
exports[1183] = 'ER_ERROR_DURING_CHECKPOINT';
exports[1184] = 'ER_NEW_ABORTING_CONNECTION';
exports[1185] = 'ER_DUMP_NOT_IMPLEMENTED';
exports[1186] = 'ER_FLUSH_MASTER_BINLOG_CLOSED';
exports[1187] = 'ER_INDEX_REBUILD';
exports[1188] = 'ER_MASTER';
exports[1189] = 'ER_MASTER_NET_READ';
exports[1190] = 'ER_MASTER_NET_WRITE';
exports[1191] = 'ER_FT_MATCHING_KEY_NOT_FOUND';
exports[1192] = 'ER_LOCK_OR_ACTIVE_TRANSACTION';
exports[1193] = 'ER_UNKNOWN_SYSTEM_VARIABLE';
exports[1194] = 'ER_CRASHED_ON_USAGE';
exports[1195] = 'ER_CRASHED_ON_REPAIR';
exports[1196] = 'ER_WARNING_NOT_COMPLETE_ROLLBACK';
exports[1197] = 'ER_TRANS_CACHE_FULL';
exports[1198] = 'ER_SLAVE_MUST_STOP';
exports[1199] = 'ER_SLAVE_NOT_RUNNING';
exports[1200] = 'ER_BAD_SLAVE';
exports[1201] = 'ER_MASTER_INFO';
exports[1202] = 'ER_SLAVE_THREAD';
exports[1203] = 'ER_TOO_MANY_USER_CONNECTIONS';
exports[1204] = 'ER_SET_CONSTANTS_ONLY';
exports[1205] = 'ER_LOCK_WAIT_TIMEOUT';
exports[1206] = 'ER_LOCK_TABLE_FULL';
exports[1207] = 'ER_READ_ONLY_TRANSACTION';
exports[1208] = 'ER_DROP_DB_WITH_READ_LOCK';
exports[1209] = 'ER_CREATE_DB_WITH_READ_LOCK';
exports[1210] = 'ER_WRONG_ARGUMENTS';
exports[1211] = 'ER_NO_PERMISSION_TO_CREATE_USER';
exports[1212] = 'ER_UNION_TABLES_IN_DIFFERENT_DIR';
exports[1213] = 'ER_LOCK_DEADLOCK';
exports[1214] = 'ER_TABLE_CANT_HANDLE_FT';
exports[1215] = 'ER_CANNOT_ADD_FOREIGN';
exports[1216] = 'ER_NO_REFERENCED_ROW';
exports[1217] = 'ER_ROW_IS_REFERENCED';
exports[1218] = 'ER_CONNECT_TO_MASTER';
exports[1219] = 'ER_QUERY_ON_MASTER';
exports[1220] = 'ER_ERROR_WHEN_EXECUTING_COMMAND';
exports[1221] = 'ER_WRONG_USAGE';
exports[1222] = 'ER_WRONG_NUMBER_OF_COLUMNS_IN_SELECT';
exports[1223] = 'ER_CANT_UPDATE_WITH_READLOCK';
exports[1224] = 'ER_MIXING_NOT_ALLOWED';
exports[1225] = 'ER_DUP_ARGUMENT';
exports[1226] = 'ER_USER_LIMIT_REACHED';
exports[1227] = 'ER_SPECIFIC_ACCESS_DENIED_ERROR';
exports[1228] = 'ER_LOCAL_VARIABLE';
exports[1229] = 'ER_GLOBAL_VARIABLE';
exports[1230] = 'ER_NO_DEFAULT';
exports[1231] = 'ER_WRONG_VALUE_FOR_VAR';
exports[1232] = 'ER_WRONG_TYPE_FOR_VAR';
exports[1233] = 'ER_VAR_CANT_BE_READ';
exports[1234] = 'ER_CANT_USE_OPTION_HERE';
exports[1235] = 'ER_NOT_SUPPORTED_YET';
exports[1236] = 'ER_MASTER_FATAL_ERROR_READING_BINLOG';
exports[1237] = 'ER_SLAVE_IGNORED_TABLE';
exports[1238] = 'ER_INCORRECT_GLOBAL_LOCAL_VAR';
exports[1239] = 'ER_WRONG_FK_DEF';
exports[1240] = 'ER_KEY_REF_DO_NOT_MATCH_TABLE_REF';
exports[1241] = 'ER_OPERAND_COLUMNS';
exports[1242] = 'ER_SUBQUERY_NO_1_ROW';
exports[1243] = 'ER_UNKNOWN_STMT_HANDLER';
exports[1244] = 'ER_CORRUPT_HELP_DB';
exports[1245] = 'ER_CYCLIC_REFERENCE';
exports[1246] = 'ER_AUTO_CONVERT';
exports[1247] = 'ER_ILLEGAL_REFERENCE';
exports[1248] = 'ER_DERIVED_MUST_HAVE_ALIAS';
exports[1249] = 'ER_SELECT_REDUCED';
exports[1250] = 'ER_TABLENAME_NOT_ALLOWED_HERE';
exports[1251] = 'ER_NOT_SUPPORTED_AUTH_MODE';
exports[1252] = 'ER_SPATIAL_CANT_HAVE_NULL';
exports[1253] = 'ER_COLLATION_CHARSET_MISMATCH';
exports[1254] = 'ER_SLAVE_WAS_RUNNING';
exports[1255] = 'ER_SLAVE_WAS_NOT_RUNNING';
exports[1256] = 'ER_TOO_BIG_FOR_UNCOMPRESS';
exports[1257] = 'ER_ZLIB_Z_MEM_ERROR';
exports[1258] = 'ER_ZLIB_Z_BUF_ERROR';
exports[1259] = 'ER_ZLIB_Z_DATA_ERROR';
exports[1260] = 'ER_CUT_VALUE_GROUP_CONCAT';
exports[1261] = 'ER_WARN_TOO_FEW_RECORDS';
exports[1262] = 'ER_WARN_TOO_MANY_RECORDS';
exports[1263] = 'ER_WARN_NULL_TO_NOTNULL';
exports[1264] = 'ER_WARN_DATA_OUT_OF_RANGE';
exports[1265] = 'WARN_DATA_TRUNCATED';
exports[1266] = 'ER_WARN_USING_OTHER_HANDLER';
exports[1267] = 'ER_CANT_AGGREGATE_2COLLATIONS';
exports[1268] = 'ER_DROP_USER';
exports[1269] = 'ER_REVOKE_GRANTS';
exports[1270] = 'ER_CANT_AGGREGATE_3COLLATIONS';
exports[1271] = 'ER_CANT_AGGREGATE_NCOLLATIONS';
exports[1272] = 'ER_VARIABLE_IS_NOT_STRUCT';
exports[1273] = 'ER_UNKNOWN_COLLATION';
exports[1274] = 'ER_SLAVE_IGNORED_SSL_PARAMS';
exports[1275] = 'ER_SERVER_IS_IN_SECURE_AUTH_MODE';
exports[1276] = 'ER_WARN_FIELD_RESOLVED';
exports[1277] = 'ER_BAD_SLAVE_UNTIL_COND';
exports[1278] = 'ER_MISSING_SKIP_SLAVE';
exports[1279] = 'ER_UNTIL_COND_IGNORED';
exports[1280] = 'ER_WRONG_NAME_FOR_INDEX';
exports[1281] = 'ER_WRONG_NAME_FOR_CATALOG';
exports[1282] = 'ER_WARN_QC_RESIZE';
exports[1283] = 'ER_BAD_FT_COLUMN';
exports[1284] = 'ER_UNKNOWN_KEY_CACHE';
exports[1285] = 'ER_WARN_HOSTNAME_WONT_WORK';
exports[1286] = 'ER_UNKNOWN_STORAGE_ENGINE';
exports[1287] = 'ER_WARN_DEPRECATED_SYNTAX';
exports[1288] = 'ER_NON_UPDATABLE_TABLE';
exports[1289] = 'ER_FEATURE_DISABLED';
exports[1290] = 'ER_OPTION_PREVENTS_STATEMENT';
exports[1291] = 'ER_DUPLICATED_VALUE_IN_TYPE';
exports[1292] = 'ER_TRUNCATED_WRONG_VALUE';
exports[1293] = 'ER_TOO_MUCH_AUTO_TIMESTAMP_COLS';
exports[1294] = 'ER_INVALID_ON_UPDATE';
exports[1295] = 'ER_UNSUPPORTED_PS';
exports[1296] = 'ER_GET_ERRMSG';
exports[1297] = 'ER_GET_TEMPORARY_ERRMSG';
exports[1298] = 'ER_UNKNOWN_TIME_ZONE';
exports[1299] = 'ER_WARN_INVALID_TIMESTAMP';
exports[1300] = 'ER_INVALID_CHARACTER_STRING';
exports[1301] = 'ER_WARN_ALLOWED_PACKET_OVERFLOWED';
exports[1302] = 'ER_CONFLICTING_DECLARATIONS';
exports[1303] = 'ER_SP_NO_RECURSIVE_CREATE';
exports[1304] = 'ER_SP_ALREADY_EXISTS';
exports[1305] = 'ER_SP_DOES_NOT_EXIST';
exports[1306] = 'ER_SP_DROP_FAILED';
exports[1307] = 'ER_SP_STORE_FAILED';
exports[1308] = 'ER_SP_LILABEL_MISMATCH';
exports[1309] = 'ER_SP_LABEL_REDEFINE';
exports[1310] = 'ER_SP_LABEL_MISMATCH';
exports[1311] = 'ER_SP_UNINIT_VAR';
exports[1312] = 'ER_SP_BADSELECT';
exports[1313] = 'ER_SP_BADRETURN';
exports[1314] = 'ER_SP_BADSTATEMENT';
exports[1315] = 'ER_UPDATE_LOG_DEPRECATED_IGNORED';
exports[1316] = 'ER_UPDATE_LOG_DEPRECATED_TRANSLATED';
exports[1317] = 'ER_QUERY_INTERRUPTED';
exports[1318] = 'ER_SP_WRONG_NO_OF_ARGS';
exports[1319] = 'ER_SP_COND_MISMATCH';
exports[1320] = 'ER_SP_NORETURN';
exports[1321] = 'ER_SP_NORETURNEND';
exports[1322] = 'ER_SP_BAD_CURSOR_QUERY';
exports[1323] = 'ER_SP_BAD_CURSOR_SELECT';
exports[1324] = 'ER_SP_CURSOR_MISMATCH';
exports[1325] = 'ER_SP_CURSOR_ALREADY_OPEN';
exports[1326] = 'ER_SP_CURSOR_NOT_OPEN';
exports[1327] = 'ER_SP_UNDECLARED_VAR';
exports[1328] = 'ER_SP_WRONG_NO_OF_FETCH_ARGS';
exports[1329] = 'ER_SP_FETCH_NO_DATA';
exports[1330] = 'ER_SP_DUP_PARAM';
exports[1331] = 'ER_SP_DUP_VAR';
exports[1332] = 'ER_SP_DUP_COND';
exports[1333] = 'ER_SP_DUP_CURS';
exports[1334] = 'ER_SP_CANT_ALTER';
exports[1335] = 'ER_SP_SUBSELECT_NYI';
exports[1336] = 'ER_STMT_NOT_ALLOWED_IN_SF_OR_TRG';
exports[1337] = 'ER_SP_VARCOND_AFTER_CURSHNDLR';
exports[1338] = 'ER_SP_CURSOR_AFTER_HANDLER';
exports[1339] = 'ER_SP_CASE_NOT_FOUND';
exports[1340] = 'ER_FPARSER_TOO_BIG_FILE';
exports[1341] = 'ER_FPARSER_BAD_HEADER';
exports[1342] = 'ER_FPARSER_EOF_IN_COMMENT';
exports[1343] = 'ER_FPARSER_ERROR_IN_PARAMETER';
exports[1344] = 'ER_FPARSER_EOF_IN_UNKNOWN_PARAMETER';
exports[1345] = 'ER_VIEW_NO_EXPLAIN';
exports[1346] = 'ER_FRM_UNKNOWN_TYPE';
exports[1347] = 'ER_WRONG_OBJECT';
exports[1348] = 'ER_NONUPDATEABLE_COLUMN';
exports[1349] = 'ER_VIEW_SELECT_DERIVED';
exports[1350] = 'ER_VIEW_SELECT_CLAUSE';
exports[1351] = 'ER_VIEW_SELECT_VARIABLE';
exports[1352] = 'ER_VIEW_SELECT_TMPTABLE';
exports[1353] = 'ER_VIEW_WRONG_LIST';
exports[1354] = 'ER_WARN_VIEW_MERGE';
exports[1355] = 'ER_WARN_VIEW_WITHOUT_KEY';
exports[1356] = 'ER_VIEW_INVALID';
exports[1357] = 'ER_SP_NO_DROP_SP';
exports[1358] = 'ER_SP_GOTO_IN_HNDLR';
exports[1359] = 'ER_TRG_ALREADY_EXISTS';
exports[1360] = 'ER_TRG_DOES_NOT_EXIST';
exports[1361] = 'ER_TRG_ON_VIEW_OR_TEMP_TABLE';
exports[1362] = 'ER_TRG_CANT_CHANGE_ROW';
exports[1363] = 'ER_TRG_NO_SUCH_ROW_IN_TRG';
exports[1364] = 'ER_NO_DEFAULT_FOR_FIELD';
exports[1365] = 'ER_DIVISION_BY_ZERO';
exports[1366] = 'ER_TRUNCATED_WRONG_VALUE_FOR_FIELD';
exports[1367] = 'ER_ILLEGAL_VALUE_FOR_TYPE';
exports[1368] = 'ER_VIEW_NONUPD_CHECK';
exports[1369] = 'ER_VIEW_CHECK_FAILED';
exports[1370] = 'ER_PROCACCESS_DENIED_ERROR';
exports[1371] = 'ER_RELAY_LOG_FAIL';
exports[1372] = 'ER_PASSWD_LENGTH';
exports[1373] = 'ER_UNKNOWN_TARGET_BINLOG';
exports[1374] = 'ER_IO_ERR_LOG_INDEX_READ';
exports[1375] = 'ER_BINLOG_PURGE_PROHIBITED';
exports[1376] = 'ER_FSEEK_FAIL';
exports[1377] = 'ER_BINLOG_PURGE_FATAL_ERR';
exports[1378] = 'ER_LOG_IN_USE';
exports[1379] = 'ER_LOG_PURGE_UNKNOWN_ERR';
exports[1380] = 'ER_RELAY_LOG_INIT';
exports[1381] = 'ER_NO_BINARY_LOGGING';
exports[1382] = 'ER_RESERVED_SYNTAX';
exports[1383] = 'ER_WSAS_FAILED';
exports[1384] = 'ER_DIFF_GROUPS_PROC';
exports[1385] = 'ER_NO_GROUP_FOR_PROC';
exports[1386] = 'ER_ORDER_WITH_PROC';
exports[1387] = 'ER_LOGGING_PROHIBIT_CHANGING_OF';
exports[1388] = 'ER_NO_FILE_MAPPING';
exports[1389] = 'ER_WRONG_MAGIC';
exports[1390] = 'ER_PS_MANY_PARAM';
exports[1391] = 'ER_KEY_PART_0';
exports[1392] = 'ER_VIEW_CHECKSUM';
exports[1393] = 'ER_VIEW_MULTIUPDATE';
exports[1394] = 'ER_VIEW_NO_INSERT_FIELD_LIST';
exports[1395] = 'ER_VIEW_DELETE_MERGE_VIEW';
exports[1396] = 'ER_CANNOT_USER';
exports[1397] = 'ER_XAER_NOTA';
exports[1398] = 'ER_XAER_INVAL';
exports[1399] = 'ER_XAER_RMFAIL';
exports[1400] = 'ER_XAER_OUTSIDE';
exports[1401] = 'ER_XAER_RMERR';
exports[1402] = 'ER_XA_RBROLLBACK';
exports[1403] = 'ER_NONEXISTING_PROC_GRANT';
exports[1404] = 'ER_PROC_AUTO_GRANT_FAIL';
exports[1405] = 'ER_PROC_AUTO_REVOKE_FAIL';
exports[1406] = 'ER_DATA_TOO_LONG';
exports[1407] = 'ER_SP_BAD_SQLSTATE';
exports[1408] = 'ER_STARTUP';
exports[1409] = 'ER_LOAD_FROM_FIXED_SIZE_ROWS_TO_VAR';
exports[1410] = 'ER_CANT_CREATE_USER_WITH_GRANT';
exports[1411] = 'ER_WRONG_VALUE_FOR_TYPE';
exports[1412] = 'ER_TABLE_DEF_CHANGED';
exports[1413] = 'ER_SP_DUP_HANDLER';
exports[1414] = 'ER_SP_NOT_VAR_ARG';
exports[1415] = 'ER_SP_NO_RETSET';
exports[1416] = 'ER_CANT_CREATE_GEOMETRY_OBJECT';
exports[1417] = 'ER_FAILED_ROUTINE_BREAK_BINLOG';
exports[1418] = 'ER_BINLOG_UNSAFE_ROUTINE';
exports[1419] = 'ER_BINLOG_CREATE_ROUTINE_NEED_SUPER';
exports[1420] = 'ER_EXEC_STMT_WITH_OPEN_CURSOR';
exports[1421] = 'ER_STMT_HAS_NO_OPEN_CURSOR';
exports[1422] = 'ER_COMMIT_NOT_ALLOWED_IN_SF_OR_TRG';
exports[1423] = 'ER_NO_DEFAULT_FOR_VIEW_FIELD';
exports[1424] = 'ER_SP_NO_RECURSION';
exports[1425] = 'ER_TOO_BIG_SCALE';
exports[1426] = 'ER_TOO_BIG_PRECISION';
exports[1427] = 'ER_M_BIGGER_THAN_D';
exports[1428] = 'ER_WRONG_LOCK_OF_SYSTEM_TABLE';
exports[1429] = 'ER_CONNECT_TO_FOREIGN_DATA_SOURCE';
exports[1430] = 'ER_QUERY_ON_FOREIGN_DATA_SOURCE';
exports[1431] = 'ER_FOREIGN_DATA_SOURCE_DOESNT_EXIST';
exports[1432] = 'ER_FOREIGN_DATA_STRING_INVALID_CANT_CREATE';
exports[1433] = 'ER_FOREIGN_DATA_STRING_INVALID';
exports[1434] = 'ER_CANT_CREATE_FEDERATED_TABLE';
exports[1435] = 'ER_TRG_IN_WRONG_SCHEMA';
exports[1436] = 'ER_STACK_OVERRUN_NEED_MORE';
exports[1437] = 'ER_TOO_LONG_BODY';
exports[1438] = 'ER_WARN_CANT_DROP_DEFAULT_KEYCACHE';
exports[1439] = 'ER_TOO_BIG_DISPLAYWIDTH';
exports[1440] = 'ER_XAER_DUPID';
exports[1441] = 'ER_DATETIME_FUNCTION_OVERFLOW';
exports[1442] = 'ER_CANT_UPDATE_USED_TABLE_IN_SF_OR_TRG';
exports[1443] = 'ER_VIEW_PREVENT_UPDATE';
exports[1444] = 'ER_PS_NO_RECURSION';
exports[1445] = 'ER_SP_CANT_SET_AUTOCOMMIT';
exports[1446] = 'ER_MALFORMED_DEFINER';
exports[1447] = 'ER_VIEW_FRM_NO_USER';
exports[1448] = 'ER_VIEW_OTHER_USER';
exports[1449] = 'ER_NO_SUCH_USER';
exports[1450] = 'ER_FORBID_SCHEMA_CHANGE';
exports[1451] = 'ER_ROW_IS_REFERENCED_2';
exports[1452] = 'ER_NO_REFERENCED_ROW_2';
exports[1453] = 'ER_SP_BAD_VAR_SHADOW';
exports[1454] = 'ER_TRG_NO_DEFINER';
exports[1455] = 'ER_OLD_FILE_FORMAT';
exports[1456] = 'ER_SP_RECURSION_LIMIT';
exports[1457] = 'ER_SP_PROC_TABLE_CORRUPT';
exports[1458] = 'ER_SP_WRONG_NAME';
exports[1459] = 'ER_TABLE_NEEDS_UPGRADE';
exports[1460] = 'ER_SP_NO_AGGREGATE';
exports[1461] = 'ER_MAX_PREPARED_STMT_COUNT_REACHED';
exports[1462] = 'ER_VIEW_RECURSIVE';
exports[1463] = 'ER_NON_GROUPING_FIELD_USED';
exports[1464] = 'ER_TABLE_CANT_HANDLE_SPKEYS';
exports[1465] = 'ER_NO_TRIGGERS_ON_SYSTEM_SCHEMA';
exports[1466] = 'ER_REMOVED_SPACES';
exports[1467] = 'ER_AUTOINC_READ_FAILED';
exports[1468] = 'ER_USERNAME';
exports[1469] = 'ER_HOSTNAME';
exports[1470] = 'ER_WRONG_STRING_LENGTH';
exports[1471] = 'ER_NON_INSERTABLE_TABLE';
exports[1472] = 'ER_ADMIN_WRONG_MRG_TABLE';
exports[1473] = 'ER_TOO_HIGH_LEVEL_OF_NESTING_FOR_SELECT';
exports[1474] = 'ER_NAME_BECOMES_EMPTY';
exports[1475] = 'ER_AMBIGUOUS_FIELD_TERM';
exports[1476] = 'ER_FOREIGN_SERVER_EXISTS';
exports[1477] = 'ER_FOREIGN_SERVER_DOESNT_EXIST';
exports[1478] = 'ER_ILLEGAL_HA_CREATE_OPTION';
exports[1479] = 'ER_PARTITION_REQUIRES_VALUES_ERROR';
exports[1480] = 'ER_PARTITION_WRONG_VALUES_ERROR';
exports[1481] = 'ER_PARTITION_MAXVALUE_ERROR';
exports[1482] = 'ER_PARTITION_SUBPARTITION_ERROR';
exports[1483] = 'ER_PARTITION_SUBPART_MIX_ERROR';
exports[1484] = 'ER_PARTITION_WRONG_NO_PART_ERROR';
exports[1485] = 'ER_PARTITION_WRONG_NO_SUBPART_ERROR';
exports[1486] = 'ER_WRONG_EXPR_IN_PARTITION_FUNC_ERROR';
exports[1487] = 'ER_NO_CONST_EXPR_IN_RANGE_OR_LIST_ERROR';
exports[1488] = 'ER_FIELD_NOT_FOUND_PART_ERROR';
exports[1489] = 'ER_LIST_OF_FIELDS_ONLY_IN_HASH_ERROR';
exports[1490] = 'ER_INCONSISTENT_PARTITION_INFO_ERROR';
exports[1491] = 'ER_PARTITION_FUNC_NOT_ALLOWED_ERROR';
exports[1492] = 'ER_PARTITIONS_MUST_BE_DEFINED_ERROR';
exports[1493] = 'ER_RANGE_NOT_INCREASING_ERROR';
exports[1494] = 'ER_INCONSISTENT_TYPE_OF_FUNCTIONS_ERROR';
exports[1495] = 'ER_MULTIPLE_DEF_CONST_IN_LIST_PART_ERROR';
exports[1496] = 'ER_PARTITION_ENTRY_ERROR';
exports[1497] = 'ER_MIX_HANDLER_ERROR';
exports[1498] = 'ER_PARTITION_NOT_DEFINED_ERROR';
exports[1499] = 'ER_TOO_MANY_PARTITIONS_ERROR';
exports[1500] = 'ER_SUBPARTITION_ERROR';
exports[1501] = 'ER_CANT_CREATE_HANDLER_FILE';
exports[1502] = 'ER_BLOB_FIELD_IN_PART_FUNC_ERROR';
exports[1503] = 'ER_UNIQUE_KEY_NEED_ALL_FIELDS_IN_PF';
exports[1504] = 'ER_NO_PARTS_ERROR';
exports[1505] = 'ER_PARTITION_MGMT_ON_NONPARTITIONED';
exports[1506] = 'ER_FOREIGN_KEY_ON_PARTITIONED';
exports[1507] = 'ER_DROP_PARTITION_NON_EXISTENT';
exports[1508] = 'ER_DROP_LAST_PARTITION';
exports[1509] = 'ER_COALESCE_ONLY_ON_HASH_PARTITION';
exports[1510] = 'ER_REORG_HASH_ONLY_ON_SAME_NO';
exports[1511] = 'ER_REORG_NO_PARAM_ERROR';
exports[1512] = 'ER_ONLY_ON_RANGE_LIST_PARTITION';
exports[1513] = 'ER_ADD_PARTITION_SUBPART_ERROR';
exports[1514] = 'ER_ADD_PARTITION_NO_NEW_PARTITION';
exports[1515] = 'ER_COALESCE_PARTITION_NO_PARTITION';
exports[1516] = 'ER_REORG_PARTITION_NOT_EXIST';
exports[1517] = 'ER_SAME_NAME_PARTITION';
exports[1518] = 'ER_NO_BINLOG_ERROR';
exports[1519] = 'ER_CONSECUTIVE_REORG_PARTITIONS';
exports[1520] = 'ER_REORG_OUTSIDE_RANGE';
exports[1521] = 'ER_PARTITION_FUNCTION_FAILURE';
exports[1522] = 'ER_PART_STATE_ERROR';
exports[1523] = 'ER_LIMITED_PART_RANGE';
exports[1524] = 'ER_PLUGIN_IS_NOT_LOADED';
exports[1525] = 'ER_WRONG_VALUE';
exports[1526] = 'ER_NO_PARTITION_FOR_GIVEN_VALUE';
exports[1527] = 'ER_FILEGROUP_OPTION_ONLY_ONCE';
exports[1528] = 'ER_CREATE_FILEGROUP_FAILED';
exports[1529] = 'ER_DROP_FILEGROUP_FAILED';
exports[1530] = 'ER_TABLESPACE_AUTO_EXTEND_ERROR';
exports[1531] = 'ER_WRONG_SIZE_NUMBER';
exports[1532] = 'ER_SIZE_OVERFLOW_ERROR';
exports[1533] = 'ER_ALTER_FILEGROUP_FAILED';
exports[1534] = 'ER_BINLOG_ROW_LOGGING_FAILED';
exports[1535] = 'ER_BINLOG_ROW_WRONG_TABLE_DEF';
exports[1536] = 'ER_BINLOG_ROW_RBR_TO_SBR';
exports[1537] = 'ER_EVENT_ALREADY_EXISTS';
exports[1538] = 'ER_EVENT_STORE_FAILED';
exports[1539] = 'ER_EVENT_DOES_NOT_EXIST';
exports[1540] = 'ER_EVENT_CANT_ALTER';
exports[1541] = 'ER_EVENT_DROP_FAILED';
exports[1542] = 'ER_EVENT_INTERVAL_NOT_POSITIVE_OR_TOO_BIG';
exports[1543] = 'ER_EVENT_ENDS_BEFORE_STARTS';
exports[1544] = 'ER_EVENT_EXEC_TIME_IN_THE_PAST';
exports[1545] = 'ER_EVENT_OPEN_TABLE_FAILED';
exports[1546] = 'ER_EVENT_NEITHER_M_EXPR_NOR_M_AT';
exports[1547] = 'ER_COL_COUNT_DOESNT_MATCH_CORRUPTED';
exports[1548] = 'ER_CANNOT_LOAD_FROM_TABLE';
exports[1549] = 'ER_EVENT_CANNOT_DELETE';
exports[1550] = 'ER_EVENT_COMPILE_ERROR';
exports[1551] = 'ER_EVENT_SAME_NAME';
exports[1552] = 'ER_EVENT_DATA_TOO_LONG';
exports[1553] = 'ER_DROP_INDEX_FK';
exports[1554] = 'ER_WARN_DEPRECATED_SYNTAX_WITH_VER';
exports[1555] = 'ER_CANT_WRITE_LOCK_LOG_TABLE';
exports[1556] = 'ER_CANT_LOCK_LOG_TABLE';
exports[1557] = 'ER_FOREIGN_DUPLICATE_KEY';
exports[1558] = 'ER_COL_COUNT_DOESNT_MATCH_PLEASE_UPDATE';
exports[1559] = 'ER_TEMP_TABLE_PREVENTS_SWITCH_OUT_OF_RBR';
exports[1560] = 'ER_STORED_FUNCTION_PREVENTS_SWITCH_BINLOG_FORMAT';
exports[1561] = 'ER_NDB_CANT_SWITCH_BINLOG_FORMAT';
exports[1562] = 'ER_PARTITION_NO_TEMPORARY';
exports[1563] = 'ER_PARTITION_CONST_DOMAIN_ERROR';
exports[1564] = 'ER_PARTITION_FUNCTION_IS_NOT_ALLOWED';
exports[1565] = 'ER_DDL_LOG_ERROR';
exports[1566] = 'ER_NULL_IN_VALUES_LESS_THAN';
exports[1567] = 'ER_WRONG_PARTITION_NAME';
exports[1568] = 'ER_CANT_CHANGE_TX_CHARACTERISTICS';
exports[1569] = 'ER_DUP_ENTRY_AUTOINCREMENT_CASE';
exports[1570] = 'ER_EVENT_MODIFY_QUEUE_ERROR';
exports[1571] = 'ER_EVENT_SET_VAR_ERROR';
exports[1572] = 'ER_PARTITION_MERGE_ERROR';
exports[1573] = 'ER_CANT_ACTIVATE_LOG';
exports[1574] = 'ER_RBR_NOT_AVAILABLE';
exports[1575] = 'ER_BASE64_DECODE_ERROR';
exports[1576] = 'ER_EVENT_RECURSION_FORBIDDEN';
exports[1577] = 'ER_EVENTS_DB_ERROR';
exports[1578] = 'ER_ONLY_INTEGERS_ALLOWED';
exports[1579] = 'ER_UNSUPORTED_LOG_ENGINE';
exports[1580] = 'ER_BAD_LOG_STATEMENT';
exports[1581] = 'ER_CANT_RENAME_LOG_TABLE';
exports[1582] = 'ER_WRONG_PARAMCOUNT_TO_NATIVE_FCT';
exports[1583] = 'ER_WRONG_PARAMETERS_TO_NATIVE_FCT';
exports[1584] = 'ER_WRONG_PARAMETERS_TO_STORED_FCT';
exports[1585] = 'ER_NATIVE_FCT_NAME_COLLISION';
exports[1586] = 'ER_DUP_ENTRY_WITH_KEY_NAME';
exports[1587] = 'ER_BINLOG_PURGE_EMFILE';
exports[1588] = 'ER_EVENT_CANNOT_CREATE_IN_THE_PAST';
exports[1589] = 'ER_EVENT_CANNOT_ALTER_IN_THE_PAST';
exports[1590] = 'ER_SLAVE_INCIDENT';
exports[1591] = 'ER_NO_PARTITION_FOR_GIVEN_VALUE_SILENT';
exports[1592] = 'ER_BINLOG_UNSAFE_STATEMENT';
exports[1593] = 'ER_SLAVE_FATAL_ERROR';
exports[1594] = 'ER_SLAVE_RELAY_LOG_READ_FAILURE';
exports[1595] = 'ER_SLAVE_RELAY_LOG_WRITE_FAILURE';
exports[1596] = 'ER_SLAVE_CREATE_EVENT_FAILURE';
exports[1597] = 'ER_SLAVE_MASTER_COM_FAILURE';
exports[1598] = 'ER_BINLOG_LOGGING_IMPOSSIBLE';
exports[1599] = 'ER_VIEW_NO_CREATION_CTX';
exports[1600] = 'ER_VIEW_INVALID_CREATION_CTX';
exports[1601] = 'ER_SR_INVALID_CREATION_CTX';
exports[1602] = 'ER_TRG_CORRUPTED_FILE';
exports[1603] = 'ER_TRG_NO_CREATION_CTX';
exports[1604] = 'ER_TRG_INVALID_CREATION_CTX';
exports[1605] = 'ER_EVENT_INVALID_CREATION_CTX';
exports[1606] = 'ER_TRG_CANT_OPEN_TABLE';
exports[1607] = 'ER_CANT_CREATE_SROUTINE';
exports[1608] = 'ER_NEVER_USED';
exports[1609] = 'ER_NO_FORMAT_DESCRIPTION_EVENT_BEFORE_BINLOG_STATEMENT';
exports[1610] = 'ER_SLAVE_CORRUPT_EVENT';
exports[1611] = 'ER_LOAD_DATA_INVALID_COLUMN';
exports[1612] = 'ER_LOG_PURGE_NO_FILE';
exports[1613] = 'ER_XA_RBTIMEOUT';
exports[1614] = 'ER_XA_RBDEADLOCK';
exports[1615] = 'ER_NEED_REPREPARE';
exports[1616] = 'ER_DELAYED_NOT_SUPPORTED';
exports[1617] = 'WARN_NO_MASTER_INFO';
exports[1618] = 'WARN_OPTION_IGNORED';
exports[1619] = 'ER_PLUGIN_DELETE_BUILTIN';
exports[1620] = 'WARN_PLUGIN_BUSY';
exports[1621] = 'ER_VARIABLE_IS_READONLY';
exports[1622] = 'ER_WARN_ENGINE_TRANSACTION_ROLLBACK';
exports[1623] = 'ER_SLAVE_HEARTBEAT_FAILURE';
exports[1624] = 'ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE';
exports[1625] = 'ER_NDB_REPLICATION_SCHEMA_ERROR';
exports[1626] = 'ER_CONFLICT_FN_PARSE_ERROR';
exports[1627] = 'ER_EXCEPTIONS_WRITE_ERROR';
exports[1628] = 'ER_TOO_LONG_TABLE_COMMENT';
exports[1629] = 'ER_TOO_LONG_FIELD_COMMENT';
exports[1630] = 'ER_FUNC_INEXISTENT_NAME_COLLISION';
exports[1631] = 'ER_DATABASE_NAME';
exports[1632] = 'ER_TABLE_NAME';
exports[1633] = 'ER_PARTITION_NAME';
exports[1634] = 'ER_SUBPARTITION_NAME';
exports[1635] = 'ER_TEMPORARY_NAME';
exports[1636] = 'ER_RENAMED_NAME';
exports[1637] = 'ER_TOO_MANY_CONCURRENT_TRXS';
exports[1638] = 'WARN_NON_ASCII_SEPARATOR_NOT_IMPLEMENTED';
exports[1639] = 'ER_DEBUG_SYNC_TIMEOUT';
exports[1640] = 'ER_DEBUG_SYNC_HIT_LIMIT';
exports[1641] = 'ER_DUP_SIGNAL_SET';
exports[1642] = 'ER_SIGNAL_WARN';
exports[1643] = 'ER_SIGNAL_NOT_FOUND';
exports[1644] = 'ER_SIGNAL_EXCEPTION';
exports[1645] = 'ER_RESIGNAL_WITHOUT_ACTIVE_HANDLER';
exports[1646] = 'ER_SIGNAL_BAD_CONDITION_TYPE';
exports[1647] = 'WARN_COND_ITEM_TRUNCATED';
exports[1648] = 'ER_COND_ITEM_TOO_LONG';
exports[1649] = 'ER_UNKNOWN_LOCALE';
exports[1650] = 'ER_SLAVE_IGNORE_SERVER_IDS';
exports[1651] = 'ER_QUERY_CACHE_DISABLED';
exports[1652] = 'ER_SAME_NAME_PARTITION_FIELD';
exports[1653] = 'ER_PARTITION_COLUMN_LIST_ERROR';
exports[1654] = 'ER_WRONG_TYPE_COLUMN_VALUE_ERROR';
exports[1655] = 'ER_TOO_MANY_PARTITION_FUNC_FIELDS_ERROR';
exports[1656] = 'ER_MAXVALUE_IN_VALUES_IN';
exports[1657] = 'ER_TOO_MANY_VALUES_ERROR';
exports[1658] = 'ER_ROW_SINGLE_PARTITION_FIELD_ERROR';
exports[1659] = 'ER_FIELD_TYPE_NOT_ALLOWED_AS_PARTITION_FIELD';
exports[1660] = 'ER_PARTITION_FIELDS_TOO_LONG';
exports[1661] = 'ER_BINLOG_ROW_ENGINE_AND_STMT_ENGINE';
exports[1662] = 'ER_BINLOG_ROW_MODE_AND_STMT_ENGINE';
exports[1663] = 'ER_BINLOG_UNSAFE_AND_STMT_ENGINE';
exports[1664] = 'ER_BINLOG_ROW_INJECTION_AND_STMT_ENGINE';
exports[1665] = 'ER_BINLOG_STMT_MODE_AND_ROW_ENGINE';
exports[1666] = 'ER_BINLOG_ROW_INJECTION_AND_STMT_MODE';
exports[1667] = 'ER_BINLOG_MULTIPLE_ENGINES_AND_SELF_LOGGING_ENGINE';
exports[1668] = 'ER_BINLOG_UNSAFE_LIMIT';
exports[1669] = 'ER_BINLOG_UNSAFE_INSERT_DELAYED';
exports[1670] = 'ER_BINLOG_UNSAFE_SYSTEM_TABLE';
exports[1671] = 'ER_BINLOG_UNSAFE_AUTOINC_COLUMNS';
exports[1672] = 'ER_BINLOG_UNSAFE_UDF';
exports[1673] = 'ER_BINLOG_UNSAFE_SYSTEM_VARIABLE';
exports[1674] = 'ER_BINLOG_UNSAFE_SYSTEM_FUNCTION';
exports[1675] = 'ER_BINLOG_UNSAFE_NONTRANS_AFTER_TRANS';
exports[1676] = 'ER_MESSAGE_AND_STATEMENT';
exports[1677] = 'ER_SLAVE_CONVERSION_FAILED';
exports[1678] = 'ER_SLAVE_CANT_CREATE_CONVERSION';
exports[1679] = 'ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_BINLOG_FORMAT';
exports[1680] = 'ER_PATH_LENGTH';
exports[1681] = 'ER_WARN_DEPRECATED_SYNTAX_NO_REPLACEMENT';
exports[1682] = 'ER_WRONG_NATIVE_TABLE_STRUCTURE';
exports[1683] = 'ER_WRONG_PERFSCHEMA_USAGE';
exports[1684] = 'ER_WARN_I_S_SKIPPED_TABLE';
exports[1685] = 'ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_BINLOG_DIRECT';
exports[1686] = 'ER_STORED_FUNCTION_PREVENTS_SWITCH_BINLOG_DIRECT';
exports[1687] = 'ER_SPATIAL_MUST_HAVE_GEOM_COL';
exports[1688] = 'ER_TOO_LONG_INDEX_COMMENT';
exports[1689] = 'ER_LOCK_ABORTED';
exports[1690] = 'ER_DATA_OUT_OF_RANGE';
exports[1691] = 'ER_WRONG_SPVAR_TYPE_IN_LIMIT';
exports[1692] = 'ER_BINLOG_UNSAFE_MULTIPLE_ENGINES_AND_SELF_LOGGING_ENGINE';
exports[1693] = 'ER_BINLOG_UNSAFE_MIXED_STATEMENT';
exports[1694] = 'ER_INSIDE_TRANSACTION_PREVENTS_SWITCH_SQL_LOG_BIN';
exports[1695] = 'ER_STORED_FUNCTION_PREVENTS_SWITCH_SQL_LOG_BIN';
exports[1696] = 'ER_FAILED_READ_FROM_PAR_FILE';
exports[1697] = 'ER_VALUES_IS_NOT_INT_TYPE_ERROR';
exports[1698] = 'ER_ACCESS_DENIED_NO_PASSWORD_ERROR';
exports[1699] = 'ER_SET_PASSWORD_AUTH_PLUGIN';
exports[1700] = 'ER_GRANT_PLUGIN_USER_EXISTS';
exports[1701] = 'ER_TRUNCATE_ILLEGAL_FK';
exports[1702] = 'ER_PLUGIN_IS_PERMANENT';
exports[1703] = 'ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE_MIN';
exports[1704] = 'ER_SLAVE_HEARTBEAT_VALUE_OUT_OF_RANGE_MAX';
exports[1705] = 'ER_STMT_CACHE_FULL';
exports[1706] = 'ER_MULTI_UPDATE_KEY_CONFLICT';
exports[1707] = 'ER_TABLE_NEEDS_REBUILD';
exports[1708] = 'WARN_OPTION_BELOW_LIMIT';
exports[1709] = 'ER_INDEX_COLUMN_TOO_LONG';
exports[1710] = 'ER_ERROR_IN_TRIGGER_BODY';
exports[1711] = 'ER_ERROR_IN_UNKNOWN_TRIGGER_BODY';
exports[1712] = 'ER_INDEX_CORRUPT';
exports[1713] = 'ER_UNDO_RECORD_TOO_BIG';
exports[1714] = 'ER_BINLOG_UNSAFE_INSERT_IGNORE_SELECT';
exports[1715] = 'ER_BINLOG_UNSAFE_INSERT_SELECT_UPDATE';
exports[1716] = 'ER_BINLOG_UNSAFE_REPLACE_SELECT';
exports[1717] = 'ER_BINLOG_UNSAFE_CREATE_IGNORE_SELECT';
exports[1718] = 'ER_BINLOG_UNSAFE_CREATE_REPLACE_SELECT';
exports[1719] = 'ER_BINLOG_UNSAFE_UPDATE_IGNORE';
exports[1720] = 'ER_PLUGIN_NO_UNINSTALL';
exports[1721] = 'ER_PLUGIN_NO_INSTALL';
exports[1722] = 'ER_BINLOG_UNSAFE_WRITE_AUTOINC_SELECT';
exports[1723] = 'ER_BINLOG_UNSAFE_CREATE_SELECT_AUTOINC';
exports[1724] = 'ER_BINLOG_UNSAFE_INSERT_TWO_KEYS';
exports[1725] = 'ER_TABLE_IN_FK_CHECK';
exports[1726] = 'ER_UNSUPPORTED_ENGINE';
exports[1727] = 'ER_BINLOG_UNSAFE_AUTOINC_NOT_FIRST';
exports[1728] = 'ER_CANNOT_LOAD_FROM_TABLE_V2';
exports[1729] = 'ER_MASTER_DELAY_VALUE_OUT_OF_RANGE';
exports[1730] = 'ER_ONLY_FD_AND_RBR_EVENTS_ALLOWED_IN_BINLOG_STATEMENT';
exports[1731] = 'ER_PARTITION_EXCHANGE_DIFFERENT_OPTION';
exports[1732] = 'ER_PARTITION_EXCHANGE_PART_TABLE';
exports[1733] = 'ER_PARTITION_EXCHANGE_TEMP_TABLE';
exports[1734] = 'ER_PARTITION_INSTEAD_OF_SUBPARTITION';
exports[1735] = 'ER_UNKNOWN_PARTITION';
exports[1736] = 'ER_TABLES_DIFFERENT_METADATA';
exports[1737] = 'ER_ROW_DOES_NOT_MATCH_PARTITION';
exports[1738] = 'ER_BINLOG_CACHE_SIZE_GREATER_THAN_MAX';
exports[1739] = 'ER_WARN_INDEX_NOT_APPLICABLE';
exports[1740] = 'ER_PARTITION_EXCHANGE_FOREIGN_KEY';
exports[1741] = 'ER_NO_SUCH_KEY_VALUE';
exports[1742] = 'ER_RPL_INFO_DATA_TOO_LONG';
exports[1743] = 'ER_NETWORK_READ_EVENT_CHECKSUM_FAILURE';
exports[1744] = 'ER_BINLOG_READ_EVENT_CHECKSUM_FAILURE';
exports[1745] = 'ER_BINLOG_STMT_CACHE_SIZE_GREATER_THAN_MAX';
exports[1746] = 'ER_CANT_UPDATE_TABLE_IN_CREATE_TABLE_SELECT';
exports[1747] = 'ER_PARTITION_CLAUSE_ON_NONPARTITIONED';
exports[1748] = 'ER_ROW_DOES_NOT_MATCH_GIVEN_PARTITION_SET';
exports[1749] = 'ER_NO_SUCH_PARTITION';
exports[1750] = 'ER_CHANGE_RPL_INFO_REPOSITORY_FAILURE';
exports[1751] = 'ER_WARNING_NOT_COMPLETE_ROLLBACK_WITH_CREATED_TEMP_TABLE';
exports[1752] = 'ER_WARNING_NOT_COMPLETE_ROLLBACK_WITH_DROPPED_TEMP_TABLE';
exports[1753] = 'ER_MTS_FEATURE_IS_NOT_SUPPORTED';
exports[1754] = 'ER_MTS_UPDATED_DBS_GREATER_MAX';
exports[1755] = 'ER_MTS_CANT_PARALLEL';
exports[1756] = 'ER_MTS_INCONSISTENT_DATA';
exports[1757] = 'ER_FULLTEXT_NOT_SUPPORTED_WITH_PARTITIONING';
exports[1758] = 'ER_DA_INVALID_CONDITION_NUMBER';
exports[1759] = 'ER_INSECURE_PLAIN_TEXT';
exports[1760] = 'ER_INSECURE_CHANGE_MASTER';
exports[1761] = 'ER_FOREIGN_DUPLICATE_KEY_WITH_CHILD_INFO';
exports[1762] = 'ER_FOREIGN_DUPLICATE_KEY_WITHOUT_CHILD_INFO';
exports[1763] = 'ER_SQLTHREAD_WITH_SECURE_SLAVE';
exports[1764] = 'ER_TABLE_HAS_NO_FT';
exports[1765] = 'ER_VARIABLE_NOT_SETTABLE_IN_SF_OR_TRIGGER';
exports[1766] = 'ER_VARIABLE_NOT_SETTABLE_IN_TRANSACTION';
exports[1767] = 'ER_GTID_NEXT_IS_NOT_IN_GTID_NEXT_LIST';
exports[1768] = 'ER_CANT_CHANGE_GTID_NEXT_IN_TRANSACTION';
exports[1769] = 'ER_SET_STATEMENT_CANNOT_INVOKE_FUNCTION';
exports[1770] = 'ER_GTID_NEXT_CANT_BE_AUTOMATIC_IF_GTID_NEXT_LIST_IS_NON_NULL';
exports[1771] = 'ER_SKIPPING_LOGGED_TRANSACTION';
exports[1772] = 'ER_MALFORMED_GTID_SET_SPECIFICATION';
exports[1773] = 'ER_MALFORMED_GTID_SET_ENCODING';
exports[1774] = 'ER_MALFORMED_GTID_SPECIFICATION';
exports[1775] = 'ER_GNO_EXHAUSTED';
exports[1776] = 'ER_BAD_SLAVE_AUTO_POSITION';
exports[1777] = 'ER_AUTO_POSITION_REQUIRES_GTID_MODE_NOT_OFF';
exports[1778] = 'ER_CANT_DO_IMPLICIT_COMMIT_IN_TRX_WHEN_GTID_NEXT_IS_SET';
exports[1779] = 'ER_GTID_MODE_ON_REQUIRES_ENFORCE_GTID_CONSISTENCY_ON';
exports[1780] = 'ER_GTID_MODE_REQUIRES_BINLOG';
exports[1781] = 'ER_CANT_SET_GTID_NEXT_TO_GTID_WHEN_GTID_MODE_IS_OFF';
exports[1782] = 'ER_CANT_SET_GTID_NEXT_TO_ANONYMOUS_WHEN_GTID_MODE_IS_ON';
exports[1783] = 'ER_CANT_SET_GTID_NEXT_LIST_TO_NON_NULL_WHEN_GTID_MODE_IS_OFF';
exports[1784] = 'ER_FOUND_GTID_EVENT_WHEN_GTID_MODE_IS_OFF';
exports[1785] = 'ER_GTID_UNSAFE_NON_TRANSACTIONAL_TABLE';
exports[1786] = 'ER_GTID_UNSAFE_CREATE_SELECT';
exports[1787] = 'ER_GTID_UNSAFE_CREATE_DROP_TEMPORARY_TABLE_IN_TRANSACTION';
exports[1788] = 'ER_GTID_MODE_CAN_ONLY_CHANGE_ONE_STEP_AT_A_TIME';
exports[1789] = 'ER_MASTER_HAS_PURGED_REQUIRED_GTIDS';
exports[1790] = 'ER_CANT_SET_GTID_NEXT_WHEN_OWNING_GTID';
exports[1791] = 'ER_UNKNOWN_EXPLAIN_FORMAT';
exports[1792] = 'ER_CANT_EXECUTE_IN_READ_ONLY_TRANSACTION';
exports[1793] = 'ER_TOO_LONG_TABLE_PARTITION_COMMENT';
exports[1794] = 'ER_SLAVE_CONFIGURATION';
exports[1795] = 'ER_INNODB_FT_LIMIT';
exports[1796] = 'ER_INNODB_NO_FT_TEMP_TABLE';
exports[1797] = 'ER_INNODB_FT_WRONG_DOCID_COLUMN';
exports[1798] = 'ER_INNODB_FT_WRONG_DOCID_INDEX';
exports[1799] = 'ER_INNODB_ONLINE_LOG_TOO_BIG';
exports[1800] = 'ER_UNKNOWN_ALTER_ALGORITHM';
exports[1801] = 'ER_UNKNOWN_ALTER_LOCK';
exports[1802] = 'ER_MTS_CHANGE_MASTER_CANT_RUN_WITH_GAPS';
exports[1803] = 'ER_MTS_RECOVERY_FAILURE';
exports[1804] = 'ER_MTS_RESET_WORKERS';
exports[1805] = 'ER_COL_COUNT_DOESNT_MATCH_CORRUPTED_V2';
exports[1806] = 'ER_SLAVE_SILENT_RETRY_TRANSACTION';
exports[1807] = 'ER_DISCARD_FK_CHECKS_RUNNING';
exports[1808] = 'ER_TABLE_SCHEMA_MISMATCH';
exports[1809] = 'ER_TABLE_IN_SYSTEM_TABLESPACE';
exports[1810] = 'ER_IO_READ_ERROR';
exports[1811] = 'ER_IO_WRITE_ERROR';
exports[1812] = 'ER_TABLESPACE_MISSING';
exports[1813] = 'ER_TABLESPACE_EXISTS';
exports[1814] = 'ER_TABLESPACE_DISCARDED';
exports[1815] = 'ER_INTERNAL_ERROR';
exports[1816] = 'ER_INNODB_IMPORT_ERROR';
exports[1817] = 'ER_INNODB_INDEX_CORRUPT';
exports[1818] = 'ER_INVALID_YEAR_COLUMN_LENGTH';
exports[1819] = 'ER_NOT_VALID_PASSWORD';
exports[1820] = 'ER_MUST_CHANGE_PASSWORD';
exports[1821] = 'ER_FK_NO_INDEX_CHILD';
exports[1822] = 'ER_FK_NO_INDEX_PARENT';
exports[1823] = 'ER_FK_FAIL_ADD_SYSTEM';
exports[1824] = 'ER_FK_CANNOT_OPEN_PARENT';
exports[1825] = 'ER_FK_INCORRECT_OPTION';
exports[1826] = 'ER_FK_DUP_NAME';
exports[1827] = 'ER_PASSWORD_FORMAT';
exports[1828] = 'ER_FK_COLUMN_CANNOT_DROP';
exports[1829] = 'ER_FK_COLUMN_CANNOT_DROP_CHILD';
exports[1830] = 'ER_FK_COLUMN_NOT_NULL';
exports[1831] = 'ER_DUP_INDEX';
exports[1832] = 'ER_FK_COLUMN_CANNOT_CHANGE';
exports[1833] = 'ER_FK_COLUMN_CANNOT_CHANGE_CHILD';
exports[1834] = 'ER_FK_CANNOT_DELETE_PARENT';
exports[1835] = 'ER_MALFORMED_PACKET';
exports[1836] = 'ER_READ_ONLY_MODE';
exports[1837] = 'ER_GTID_NEXT_TYPE_UNDEFINED_GROUP';
exports[1838] = 'ER_VARIABLE_NOT_SETTABLE_IN_SP';
exports[1839] = 'ER_CANT_SET_GTID_PURGED_WHEN_GTID_MODE_IS_OFF';
exports[1840] = 'ER_CANT_SET_GTID_PURGED_WHEN_GTID_EXECUTED_IS_NOT_EMPTY';
exports[1841] = 'ER_CANT_SET_GTID_PURGED_WHEN_OWNED_GTIDS_IS_NOT_EMPTY';
exports[1842] = 'ER_GTID_PURGED_WAS_CHANGED';
exports[1843] = 'ER_GTID_EXECUTED_WAS_CHANGED';
exports[1844] = 'ER_BINLOG_STMT_MODE_AND_NO_REPL_TABLES';
exports[1845] = 'ER_ALTER_OPERATION_NOT_SUPPORTED';
exports[1846] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON';
exports[1847] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_COPY';
exports[1848] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_PARTITION';
exports[1849] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FK_RENAME';
exports[1850] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_COLUMN_TYPE';
exports[1851] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FK_CHECK';
exports[1852] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_IGNORE';
exports[1853] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_NOPK';
exports[1854] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_AUTOINC';
exports[1855] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_HIDDEN_FTS';
exports[1856] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_CHANGE_FTS';
exports[1857] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_FTS';
exports[1858] = 'ER_SQL_SLAVE_SKIP_COUNTER_NOT_SETTABLE_IN_GTID_MODE';
exports[1859] = 'ER_DUP_UNKNOWN_IN_INDEX';
exports[1860] = 'ER_IDENT_CAUSES_TOO_LONG_PATH';
exports[1861] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_NOT_NULL';
exports[1862] = 'ER_MUST_CHANGE_PASSWORD_LOGIN';
exports[1863] = 'ER_ROW_IN_WRONG_PARTITION';
exports[1864] = 'ER_MTS_EVENT_BIGGER_PENDING_JOBS_SIZE_MAX';
exports[1865] = 'ER_INNODB_NO_FT_USES_PARSER';
exports[1866] = 'ER_BINLOG_LOGICAL_CORRUPTION';
exports[1867] = 'ER_WARN_PURGE_LOG_IN_USE';
exports[1868] = 'ER_WARN_PURGE_LOG_IS_ACTIVE';
exports[1869] = 'ER_AUTO_INCREMENT_CONFLICT';
exports[1870] = 'WARN_ON_BLOCKHOLE_IN_RBR';
exports[1871] = 'ER_SLAVE_MI_INIT_REPOSITORY';
exports[1872] = 'ER_SLAVE_RLI_INIT_REPOSITORY';
exports[1873] = 'ER_ACCESS_DENIED_CHANGE_USER_ERROR';
exports[1874] = 'ER_INNODB_READ_ONLY';
exports[1875] = 'ER_STOP_SLAVE_SQL_THREAD_TIMEOUT';
exports[1876] = 'ER_STOP_SLAVE_IO_THREAD_TIMEOUT';
exports[1877] = 'ER_TABLE_CORRUPT';
exports[1878] = 'ER_TEMP_FILE_WRITE_FAILURE';
exports[1879] = 'ER_INNODB_FT_AUX_NOT_HEX_ID';
exports[1880] = 'ER_OLD_TEMPORALS_UPGRADED';
exports[1881] = 'ER_INNODB_FORCED_RECOVERY';
exports[1882] = 'ER_AES_INVALID_IV';
exports[1883] = 'ER_PLUGIN_CANNOT_BE_UNINSTALLED';
exports[1884] = 'ER_GTID_UNSAFE_BINLOG_SPLITTABLE_STATEMENT_AND_GTID_GROUP';
exports[1885] = 'ER_SLAVE_HAS_MORE_GTIDS_THAN_MASTER';
exports[1886] = 'ER_MISSING_KEY';
exports[1887] = 'WARN_NAMED_PIPE_ACCESS_EVERYONE';
exports[1888] = 'ER_FOUND_MISSING_GTIDS';
exports[3000] = 'ER_FILE_CORRUPT';
exports[3001] = 'ER_ERROR_ON_MASTER';
exports[3002] = 'ER_INCONSISTENT_ERROR';
exports[3003] = 'ER_STORAGE_ENGINE_NOT_LOADED';
exports[3004] = 'ER_GET_STACKED_DA_WITHOUT_ACTIVE_HANDLER';
exports[3005] = 'ER_WARN_LEGACY_SYNTAX_CONVERTED';
exports[3006] = 'ER_BINLOG_UNSAFE_FULLTEXT_PLUGIN';
exports[3007] = 'ER_CANNOT_DISCARD_TEMPORARY_TABLE';
exports[3008] = 'ER_FK_DEPTH_EXCEEDED';
exports[3009] = 'ER_COL_COUNT_DOESNT_MATCH_PLEASE_UPDATE_V2';
exports[3010] = 'ER_WARN_TRIGGER_DOESNT_HAVE_CREATED';
exports[3011] = 'ER_REFERENCED_TRG_DOES_NOT_EXIST';
exports[3012] = 'ER_EXPLAIN_NOT_SUPPORTED';
exports[3013] = 'ER_INVALID_FIELD_SIZE';
exports[3014] = 'ER_MISSING_HA_CREATE_OPTION';
exports[3015] = 'ER_ENGINE_OUT_OF_MEMORY';
exports[3016] = 'ER_PASSWORD_EXPIRE_ANONYMOUS_USER';
exports[3017] = 'ER_SLAVE_SQL_THREAD_MUST_STOP';
exports[3018] = 'ER_NO_FT_MATERIALIZED_SUBQUERY';
exports[3019] = 'ER_INNODB_UNDO_LOG_FULL';
exports[3020] = 'ER_INVALID_ARGUMENT_FOR_LOGARITHM';
exports[3021] = 'ER_SLAVE_CHANNEL_IO_THREAD_MUST_STOP';
exports[3022] = 'ER_WARN_OPEN_TEMP_TABLES_MUST_BE_ZERO';
exports[3023] = 'ER_WARN_ONLY_MASTER_LOG_FILE_NO_POS';
exports[3024] = 'ER_QUERY_TIMEOUT';
exports[3025] = 'ER_NON_RO_SELECT_DISABLE_TIMER';
exports[3026] = 'ER_DUP_LIST_ENTRY';
exports[3027] = 'ER_SQL_MODE_NO_EFFECT';
exports[3028] = 'ER_AGGREGATE_ORDER_FOR_UNION';
exports[3029] = 'ER_AGGREGATE_ORDER_NON_AGG_QUERY';
exports[3030] = 'ER_SLAVE_WORKER_STOPPED_PREVIOUS_THD_ERROR';
exports[3031] = 'ER_DONT_SUPPORT_SLAVE_PRESERVE_COMMIT_ORDER';
exports[3032] = 'ER_SERVER_OFFLINE_MODE';
exports[3033] = 'ER_GIS_DIFFERENT_SRIDS';
exports[3034] = 'ER_GIS_UNSUPPORTED_ARGUMENT';
exports[3035] = 'ER_GIS_UNKNOWN_ERROR';
exports[3036] = 'ER_GIS_UNKNOWN_EXCEPTION';
exports[3037] = 'ER_GIS_INVALID_DATA';
exports[3038] = 'ER_BOOST_GEOMETRY_EMPTY_INPUT_EXCEPTION';
exports[3039] = 'ER_BOOST_GEOMETRY_CENTROID_EXCEPTION';
exports[3040] = 'ER_BOOST_GEOMETRY_OVERLAY_INVALID_INPUT_EXCEPTION';
exports[3041] = 'ER_BOOST_GEOMETRY_TURN_INFO_EXCEPTION';
exports[3042] = 'ER_BOOST_GEOMETRY_SELF_INTERSECTION_POINT_EXCEPTION';
exports[3043] = 'ER_BOOST_GEOMETRY_UNKNOWN_EXCEPTION';
exports[3044] = 'ER_STD_BAD_ALLOC_ERROR';
exports[3045] = 'ER_STD_DOMAIN_ERROR';
exports[3046] = 'ER_STD_LENGTH_ERROR';
exports[3047] = 'ER_STD_INVALID_ARGUMENT';
exports[3048] = 'ER_STD_OUT_OF_RANGE_ERROR';
exports[3049] = 'ER_STD_OVERFLOW_ERROR';
exports[3050] = 'ER_STD_RANGE_ERROR';
exports[3051] = 'ER_STD_UNDERFLOW_ERROR';
exports[3052] = 'ER_STD_LOGIC_ERROR';
exports[3053] = 'ER_STD_RUNTIME_ERROR';
exports[3054] = 'ER_STD_UNKNOWN_EXCEPTION';
exports[3055] = 'ER_GIS_DATA_WRONG_ENDIANESS';
exports[3056] = 'ER_CHANGE_MASTER_PASSWORD_LENGTH';
exports[3057] = 'ER_USER_LOCK_WRONG_NAME';
exports[3058] = 'ER_USER_LOCK_DEADLOCK';
exports[3059] = 'ER_REPLACE_INACCESSIBLE_ROWS';
exports[3060] = 'ER_ALTER_OPERATION_NOT_SUPPORTED_REASON_GIS';
exports[3061] = 'ER_ILLEGAL_USER_VAR';
exports[3062] = 'ER_GTID_MODE_OFF';
exports[3063] = 'ER_UNSUPPORTED_BY_REPLICATION_THREAD';
exports[3064] = 'ER_INCORRECT_TYPE';
exports[3065] = 'ER_FIELD_IN_ORDER_NOT_SELECT';
exports[3066] = 'ER_AGGREGATE_IN_ORDER_NOT_SELECT';
exports[3067] = 'ER_INVALID_RPL_WILD_TABLE_FILTER_PATTERN';
exports[3068] = 'ER_NET_OK_PACKET_TOO_LARGE';
exports[3069] = 'ER_INVALID_JSON_DATA';
exports[3070] = 'ER_INVALID_GEOJSON_MISSING_MEMBER';
exports[3071] = 'ER_INVALID_GEOJSON_WRONG_TYPE';
exports[3072] = 'ER_INVALID_GEOJSON_UNSPECIFIED';
exports[3073] = 'ER_DIMENSION_UNSUPPORTED';
exports[3074] = 'ER_SLAVE_CHANNEL_DOES_NOT_EXIST';
exports[3075] = 'ER_SLAVE_MULTIPLE_CHANNELS_HOST_PORT';
exports[3076] = 'ER_SLAVE_CHANNEL_NAME_INVALID_OR_TOO_LONG';
exports[3077] = 'ER_SLAVE_NEW_CHANNEL_WRONG_REPOSITORY';
exports[3078] = 'ER_SLAVE_CHANNEL_DELETE';
exports[3079] = 'ER_SLAVE_MULTIPLE_CHANNELS_CMD';
exports[3080] = 'ER_SLAVE_MAX_CHANNELS_EXCEEDED';
exports[3081] = 'ER_SLAVE_CHANNEL_MUST_STOP';
exports[3082] = 'ER_SLAVE_CHANNEL_NOT_RUNNING';
exports[3083] = 'ER_SLAVE_CHANNEL_WAS_RUNNING';
exports[3084] = 'ER_SLAVE_CHANNEL_WAS_NOT_RUNNING';
exports[3085] = 'ER_SLAVE_CHANNEL_SQL_THREAD_MUST_STOP';
exports[3086] = 'ER_SLAVE_CHANNEL_SQL_SKIP_COUNTER';
exports[3087] = 'ER_WRONG_FIELD_WITH_GROUP_V2';
exports[3088] = 'ER_MIX_OF_GROUP_FUNC_AND_FIELDS_V2';
exports[3089] = 'ER_WARN_DEPRECATED_SYSVAR_UPDATE';
exports[3090] = 'ER_WARN_DEPRECATED_SQLMODE';
exports[3091] = 'ER_CANNOT_LOG_PARTIAL_DROP_DATABASE_WITH_GTID';
exports[3092] = 'ER_GROUP_REPLICATION_CONFIGURATION';
exports[3093] = 'ER_GROUP_REPLICATION_RUNNING';
exports[3094] = 'ER_GROUP_REPLICATION_APPLIER_INIT_ERROR';
exports[3095] = 'ER_GROUP_REPLICATION_STOP_APPLIER_THREAD_TIMEOUT';
exports[3096] = 'ER_GROUP_REPLICATION_COMMUNICATION_LAYER_SESSION_ERROR';
exports[3097] = 'ER_GROUP_REPLICATION_COMMUNICATION_LAYER_JOIN_ERROR';
exports[3098] = 'ER_BEFORE_DML_VALIDATION_ERROR';
exports[3099] = 'ER_PREVENTS_VARIABLE_WITHOUT_RBR';
exports[3100] = 'ER_RUN_HOOK_ERROR';
exports[3101] = 'ER_TRANSACTION_ROLLBACK_DURING_COMMIT';
exports[3102] = 'ER_GENERATED_COLUMN_FUNCTION_IS_NOT_ALLOWED';
exports[3103] = 'ER_UNSUPPORTED_ALTER_INPLACE_ON_VIRTUAL_COLUMN';
exports[3104] = 'ER_WRONG_FK_OPTION_FOR_GENERATED_COLUMN';
exports[3105] = 'ER_NON_DEFAULT_VALUE_FOR_GENERATED_COLUMN';
exports[3106] = 'ER_UNSUPPORTED_ACTION_ON_GENERATED_COLUMN';
exports[3107] = 'ER_GENERATED_COLUMN_NON_PRIOR';
exports[3108] = 'ER_DEPENDENT_BY_GENERATED_COLUMN';
exports[3109] = 'ER_GENERATED_COLUMN_REF_AUTO_INC';
exports[3110] = 'ER_FEATURE_NOT_AVAILABLE';
exports[3111] = 'ER_CANT_SET_GTID_MODE';
exports[3112] = 'ER_CANT_USE_AUTO_POSITION_WITH_GTID_MODE_OFF';
exports[3113] = 'ER_CANT_REPLICATE_ANONYMOUS_WITH_AUTO_POSITION';
exports[3114] = 'ER_CANT_REPLICATE_ANONYMOUS_WITH_GTID_MODE_ON';
exports[3115] = 'ER_CANT_REPLICATE_GTID_WITH_GTID_MODE_OFF';
exports[3116] = 'ER_CANT_SET_ENFORCE_GTID_CONSISTENCY_ON_WITH_ONGOING_GTID_VIOLATING_TRANSACTIONS';
exports[3117] = 'ER_SET_ENFORCE_GTID_CONSISTENCY_WARN_WITH_ONGOING_GTID_VIOLATING_TRANSACTIONS';
exports[3118] = 'ER_ACCOUNT_HAS_BEEN_LOCKED';
exports[3119] = 'ER_WRONG_TABLESPACE_NAME';
exports[3120] = 'ER_TABLESPACE_IS_NOT_EMPTY';
exports[3121] = 'ER_WRONG_FILE_NAME';
exports[3122] = 'ER_BOOST_GEOMETRY_INCONSISTENT_TURNS_EXCEPTION';
exports[3123] = 'ER_WARN_OPTIMIZER_HINT_SYNTAX_ERROR';
exports[3124] = 'ER_WARN_BAD_MAX_EXECUTION_TIME';
exports[3125] = 'ER_WARN_UNSUPPORTED_MAX_EXECUTION_TIME';
exports[3126] = 'ER_WARN_CONFLICTING_HINT';
exports[3127] = 'ER_WARN_UNKNOWN_QB_NAME';
exports[3128] = 'ER_UNRESOLVED_HINT_NAME';
exports[3129] = 'ER_WARN_ON_MODIFYING_GTID_EXECUTED_TABLE';
exports[3130] = 'ER_PLUGGABLE_PROTOCOL_COMMAND_NOT_SUPPORTED';
exports[3131] = 'ER_LOCKING_SERVICE_WRONG_NAME';
exports[3132] = 'ER_LOCKING_SERVICE_DEADLOCK';
exports[3133] = 'ER_LOCKING_SERVICE_TIMEOUT';
exports[3134] = 'ER_GIS_MAX_POINTS_IN_GEOMETRY_OVERFLOWED';
exports[3135] = 'ER_SQL_MODE_MERGED';
exports[3136] = 'ER_VTOKEN_PLUGIN_TOKEN_MISMATCH';
exports[3137] = 'ER_VTOKEN_PLUGIN_TOKEN_NOT_FOUND';
exports[3138] = 'ER_CANT_SET_VARIABLE_WHEN_OWNING_GTID';
exports[3139] = 'ER_SLAVE_CHANNEL_OPERATION_NOT_ALLOWED';
exports[3140] = 'ER_INVALID_JSON_TEXT';
exports[3141] = 'ER_INVALID_JSON_TEXT_IN_PARAM';
exports[3142] = 'ER_INVALID_JSON_BINARY_DATA';
exports[3143] = 'ER_INVALID_JSON_PATH';
exports[3144] = 'ER_INVALID_JSON_CHARSET';
exports[3145] = 'ER_INVALID_JSON_CHARSET_IN_FUNCTION';
exports[3146] = 'ER_INVALID_TYPE_FOR_JSON';
exports[3147] = 'ER_INVALID_CAST_TO_JSON';
exports[3148] = 'ER_INVALID_JSON_PATH_CHARSET';
exports[3149] = 'ER_INVALID_JSON_PATH_WILDCARD';
exports[3150] = 'ER_JSON_VALUE_TOO_BIG';
exports[3151] = 'ER_JSON_KEY_TOO_BIG';
exports[3152] = 'ER_JSON_USED_AS_KEY';
exports[3153] = 'ER_JSON_VACUOUS_PATH';
exports[3154] = 'ER_JSON_BAD_ONE_OR_ALL_ARG';
exports[3155] = 'ER_NUMERIC_JSON_VALUE_OUT_OF_RANGE';
exports[3156] = 'ER_INVALID_JSON_VALUE_FOR_CAST';
exports[3157] = 'ER_JSON_DOCUMENT_TOO_DEEP';
exports[3158] = 'ER_JSON_DOCUMENT_NULL_KEY';
exports[3159] = 'ER_SECURE_TRANSPORT_REQUIRED';
exports[3160] = 'ER_NO_SECURE_TRANSPORTS_CONFIGURED';
exports[3161] = 'ER_DISABLED_STORAGE_ENGINE';
exports[3162] = 'ER_USER_DOES_NOT_EXIST';
exports[3163] = 'ER_USER_ALREADY_EXISTS';
exports[3164] = 'ER_AUDIT_API_ABORT';
exports[3165] = 'ER_INVALID_JSON_PATH_ARRAY_CELL';
exports[3166] = 'ER_BUFPOOL_RESIZE_INPROGRESS';
exports[3167] = 'ER_FEATURE_DISABLED_SEE_DOC';
exports[3168] = 'ER_SERVER_ISNT_AVAILABLE';
exports[3169] = 'ER_SESSION_WAS_KILLED';
exports[3170] = 'ER_CAPACITY_EXCEEDED';
exports[3171] = 'ER_CAPACITY_EXCEEDED_IN_RANGE_OPTIMIZER';
exports[3172] = 'ER_TABLE_NEEDS_UPG_PART';
exports[3173] = 'ER_CANT_WAIT_FOR_EXECUTED_GTID_SET_WHILE_OWNING_A_GTID';
exports[3174] = 'ER_CANNOT_ADD_FOREIGN_BASE_COL_VIRTUAL';
exports[3175] = 'ER_CANNOT_CREATE_VIRTUAL_INDEX_CONSTRAINT';
exports[3176] = 'ER_ERROR_ON_MODIFYING_GTID_EXECUTED_TABLE';
exports[3177] = 'ER_LOCK_REFUSED_BY_ENGINE';
exports[3178] = 'ER_UNSUPPORTED_ALTER_ONLINE_ON_VIRTUAL_COLUMN';
exports[3179] = 'ER_MASTER_KEY_ROTATION_NOT_SUPPORTED_BY_SE';
exports[3180] = 'ER_MASTER_KEY_ROTATION_ERROR_BY_SE';
exports[3181] = 'ER_MASTER_KEY_ROTATION_BINLOG_FAILED';
exports[3182] = 'ER_MASTER_KEY_ROTATION_SE_UNAVAILABLE';
exports[3183] = 'ER_TABLESPACE_CANNOT_ENCRYPT';
exports[3184] = 'ER_INVALID_ENCRYPTION_OPTION';
exports[3185] = 'ER_CANNOT_FIND_KEY_IN_KEYRING';
exports[3186] = 'ER_CAPACITY_EXCEEDED_IN_PARSER';
exports[3187] = 'ER_UNSUPPORTED_ALTER_ENCRYPTION_INPLACE';
exports[3188] = 'ER_KEYRING_UDF_KEYRING_SERVICE_ERROR';
exports[3189] = 'ER_USER_COLUMN_OLD_LENGTH';
exports[3190] = 'ER_CANT_RESET_MASTER';
exports[3191] = 'ER_GROUP_REPLICATION_MAX_GROUP_SIZE';
exports[3192] = 'ER_CANNOT_ADD_FOREIGN_BASE_COL_STORED';
exports[3193] = 'ER_TABLE_REFERENCED';
exports[3194] = 'ER_PARTITION_ENGINE_DEPRECATED_FOR_TABLE';
exports[3195] = 'ER_WARN_USING_GEOMFROMWKB_TO_SET_SRID_ZERO';
exports[3196] = 'ER_WARN_USING_GEOMFROMWKB_TO_SET_SRID';
exports[3197] = 'ER_XA_RETRY';
exports[3198] = 'ER_KEYRING_AWS_UDF_AWS_KMS_ERROR';
exports[3199] = 'ER_BINLOG_UNSAFE_XA';
exports[3200] = 'ER_UDF_ERROR';
exports[3201] = 'ER_KEYRING_MIGRATION_FAILURE';
exports[3202] = 'ER_KEYRING_ACCESS_DENIED_ERROR';
exports[3203] = 'ER_KEYRING_MIGRATION_STATUS';
exports[3204] = 'ER_PLUGIN_FAILED_TO_OPEN_TABLES';
exports[3205] = 'ER_PLUGIN_FAILED_TO_OPEN_TABLE';
exports[3206] = 'ER_AUDIT_LOG_NO_KEYRING_PLUGIN_INSTALLED';
exports[3207] = 'ER_AUDIT_LOG_ENCRYPTION_PASSWORD_HAS_NOT_BEEN_SET';
exports[3208] = 'ER_AUDIT_LOG_COULD_NOT_CREATE_AES_KEY';
exports[3209] = 'ER_AUDIT_LOG_ENCRYPTION_PASSWORD_CANNOT_BE_FETCHED';
exports[3210] = 'ER_AUDIT_LOG_JSON_FILTERING_NOT_ENABLED';
exports[3211] = 'ER_AUDIT_LOG_UDF_INSUFFICIENT_PRIVILEGE';
exports[3212] = 'ER_AUDIT_LOG_SUPER_PRIVILEGE_REQUIRED';
exports[3213] = 'ER_COULD_NOT_REINITIALIZE_AUDIT_LOG_FILTERS';
exports[3214] = 'ER_AUDIT_LOG_UDF_INVALID_ARGUMENT_TYPE';
exports[3215] = 'ER_AUDIT_LOG_UDF_INVALID_ARGUMENT_COUNT';
exports[3216] = 'ER_AUDIT_LOG_HAS_NOT_BEEN_INSTALLED';
exports[3217] = 'ER_AUDIT_LOG_UDF_READ_INVALID_MAX_ARRAY_LENGTH_ARG_TYPE';
exports[3218] = 'ER_AUDIT_LOG_UDF_READ_INVALID_MAX_ARRAY_LENGTH_ARG_VALUE';
exports[3219] = 'ER_AUDIT_LOG_JSON_FILTER_PARSING_ERROR';
exports[3220] = 'ER_AUDIT_LOG_JSON_FILTER_NAME_CANNOT_BE_EMPTY';
exports[3221] = 'ER_AUDIT_LOG_JSON_USER_NAME_CANNOT_BE_EMPTY';
exports[3222] = 'ER_AUDIT_LOG_JSON_FILTER_DOES_NOT_EXISTS';
exports[3223] = 'ER_AUDIT_LOG_USER_FIRST_CHARACTER_MUST_BE_ALPHANUMERIC';
exports[3224] = 'ER_AUDIT_LOG_USER_NAME_INVALID_CHARACTER';
exports[3225] = 'ER_AUDIT_LOG_HOST_NAME_INVALID_CHARACTER';
exports[3226] = 'WARN_DEPRECATED_MAXDB_SQL_MODE_FOR_TIMESTAMP';
exports[3227] = 'ER_XA_REPLICATION_FILTERS';
exports[3228] = 'ER_CANT_OPEN_ERROR_LOG';
exports[3229] = 'ER_GROUPING_ON_TIMESTAMP_IN_DST';
exports[3230] = 'ER_CANT_START_SERVER_NAMED_PIPE';

// Manually extracted from mysql-5.5.23/include/mysql_com.h
exports.CLIENT_LONG_PASSWORD     = 1; /* new more secure passwords */
exports.CLIENT_FOUND_ROWS        = 2; /* Found instead of affected rows */
exports.CLIENT_LONG_FLAG         = 4; /* Get all column flags */
exports.CLIENT_CONNECT_WITH_DB   = 8; /* One can specify db on connect */
exports.CLIENT_NO_SCHEMA         = 16; /* Don't allow database.table.column */
exports.CLIENT_COMPRESS          = 32; /* Can use compression protocol */
exports.CLIENT_ODBC              = 64; /* Odbc client */
exports.CLIENT_LOCAL_FILES       = 128; /* Can use LOAD DATA LOCAL */
exports.CLIENT_IGNORE_SPACE      = 256; /* Ignore spaces before '(' */
exports.CLIENT_PROTOCOL_41       = 512; /* New 4.1 protocol */
exports.CLIENT_INTERACTIVE       = 1024; /* This is an interactive client */
exports.CLIENT_SSL               = 2048; /* Switch to SSL after handshake */
exports.CLIENT_IGNORE_SIGPIPE    = 4096;    /* IGNORE sigpipes */
exports.CLIENT_TRANSACTIONS      = 8192; /* Client knows about transactions */
exports.CLIENT_RESERVED          = 16384;   /* Old flag for 4.1 protocol  */
exports.CLIENT_SECURE_CONNECTION = 32768;  /* New 4.1 authentication */

exports.CLIENT_MULTI_STATEMENTS = 65536; /* Enable/disable multi-stmt support */
exports.CLIENT_MULTI_RESULTS    = 131072; /* Enable/disable multi-results */
exports.CLIENT_PS_MULTI_RESULTS = 262144; /* Multi-results in PS-protocol */

exports.CLIENT_PLUGIN_AUTH = 524288; /* Client supports plugin authentication */

exports.CLIENT_SSL_VERIFY_SERVER_CERT = 1073741824;
exports.CLIENT_REMEMBER_OPTIONS       = 2147483648;

module.exports = ComStatisticsPacket;
function ComStatisticsPacket() {
  this.command = 0x09;
}

ComStatisticsPacket.prototype.write = function(writer) {
  writer.writeUnsignedNumber(1, this.command);
};

ComStatisticsPacket.prototype.parse = function(parser) {
  this.command = parser.parseUnsignedNumber(1);
};

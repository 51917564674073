module.exports = LocalDataFilePacket;

/**
 * Create a new LocalDataFilePacket
 * @constructor
 * @param {Buffer} data The data contents of the packet
 * @public
 */
function LocalDataFilePacket(data) {
  this.data = data;
}

LocalDataFilePacket.prototype.write = function(writer) {
  writer.writeBuffer(this.data);
};

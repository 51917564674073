/**
 * MySQL type constants
 *
 * Extracted from version 5.7.29
 *
 * !! Generated by generate-type-constants.js, do not modify by hand !!
 */

exports.DECIMAL     = 0;
exports.TINY        = 1;
exports.SHORT       = 2;
exports.LONG        = 3;
exports.FLOAT       = 4;
exports.DOUBLE      = 5;
exports.NULL        = 6;
exports.TIMESTAMP   = 7;
exports.LONGLONG    = 8;
exports.INT24       = 9;
exports.DATE        = 10;
exports.TIME        = 11;
exports.DATETIME    = 12;
exports.YEAR        = 13;
exports.NEWDATE     = 14;
exports.VARCHAR     = 15;
exports.BIT         = 16;
exports.TIMESTAMP2  = 17;
exports.DATETIME2   = 18;
exports.TIME2       = 19;
exports.JSON        = 245;
exports.NEWDECIMAL  = 246;
exports.ENUM        = 247;
exports.SET         = 248;
exports.TINY_BLOB   = 249;
exports.MEDIUM_BLOB = 250;
exports.LONG_BLOB   = 251;
exports.BLOB        = 252;
exports.VAR_STRING  = 253;
exports.STRING      = 254;
exports.GEOMETRY    = 255;

// Lookup-by-number table
exports[0]   = 'DECIMAL';
exports[1]   = 'TINY';
exports[2]   = 'SHORT';
exports[3]   = 'LONG';
exports[4]   = 'FLOAT';
exports[5]   = 'DOUBLE';
exports[6]   = 'NULL';
exports[7]   = 'TIMESTAMP';
exports[8]   = 'LONGLONG';
exports[9]   = 'INT24';
exports[10]  = 'DATE';
exports[11]  = 'TIME';
exports[12]  = 'DATETIME';
exports[13]  = 'YEAR';
exports[14]  = 'NEWDATE';
exports[15]  = 'VARCHAR';
exports[16]  = 'BIT';
exports[17]  = 'TIMESTAMP2';
exports[18]  = 'DATETIME2';
exports[19]  = 'TIME2';
exports[245] = 'JSON';
exports[246] = 'NEWDECIMAL';
exports[247] = 'ENUM';
exports[248] = 'SET';
exports[249] = 'TINY_BLOB';
exports[250] = 'MEDIUM_BLOB';
exports[251] = 'LONG_BLOB';
exports[252] = 'BLOB';
exports[253] = 'VAR_STRING';
exports[254] = 'STRING';
exports[255] = 'GEOMETRY';

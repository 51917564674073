exports.BIG5_CHINESE_CI              = 1;
exports.LATIN2_CZECH_CS              = 2;
exports.DEC8_SWEDISH_CI              = 3;
exports.CP850_GENERAL_CI             = 4;
exports.LATIN1_GERMAN1_CI            = 5;
exports.HP8_ENGLISH_CI               = 6;
exports.KOI8R_GENERAL_CI             = 7;
exports.LATIN1_SWEDISH_CI            = 8;
exports.LATIN2_GENERAL_CI            = 9;
exports.SWE7_SWEDISH_CI              = 10;
exports.ASCII_GENERAL_CI             = 11;
exports.UJIS_JAPANESE_CI             = 12;
exports.SJIS_JAPANESE_CI             = 13;
exports.CP1251_BULGARIAN_CI          = 14;
exports.LATIN1_DANISH_CI             = 15;
exports.HEBREW_GENERAL_CI            = 16;
exports.TIS620_THAI_CI               = 18;
exports.EUCKR_KOREAN_CI              = 19;
exports.LATIN7_ESTONIAN_CS           = 20;
exports.LATIN2_HUNGARIAN_CI          = 21;
exports.KOI8U_GENERAL_CI             = 22;
exports.CP1251_UKRAINIAN_CI          = 23;
exports.GB2312_CHINESE_CI            = 24;
exports.GREEK_GENERAL_CI             = 25;
exports.CP1250_GENERAL_CI            = 26;
exports.LATIN2_CROATIAN_CI           = 27;
exports.GBK_CHINESE_CI               = 28;
exports.CP1257_LITHUANIAN_CI         = 29;
exports.LATIN5_TURKISH_CI            = 30;
exports.LATIN1_GERMAN2_CI            = 31;
exports.ARMSCII8_GENERAL_CI          = 32;
exports.UTF8_GENERAL_CI              = 33;
exports.CP1250_CZECH_CS              = 34;
exports.UCS2_GENERAL_CI              = 35;
exports.CP866_GENERAL_CI             = 36;
exports.KEYBCS2_GENERAL_CI           = 37;
exports.MACCE_GENERAL_CI             = 38;
exports.MACROMAN_GENERAL_CI          = 39;
exports.CP852_GENERAL_CI             = 40;
exports.LATIN7_GENERAL_CI            = 41;
exports.LATIN7_GENERAL_CS            = 42;
exports.MACCE_BIN                    = 43;
exports.CP1250_CROATIAN_CI           = 44;
exports.UTF8MB4_GENERAL_CI           = 45;
exports.UTF8MB4_BIN                  = 46;
exports.LATIN1_BIN                   = 47;
exports.LATIN1_GENERAL_CI            = 48;
exports.LATIN1_GENERAL_CS            = 49;
exports.CP1251_BIN                   = 50;
exports.CP1251_GENERAL_CI            = 51;
exports.CP1251_GENERAL_CS            = 52;
exports.MACROMAN_BIN                 = 53;
exports.UTF16_GENERAL_CI             = 54;
exports.UTF16_BIN                    = 55;
exports.UTF16LE_GENERAL_CI           = 56;
exports.CP1256_GENERAL_CI            = 57;
exports.CP1257_BIN                   = 58;
exports.CP1257_GENERAL_CI            = 59;
exports.UTF32_GENERAL_CI             = 60;
exports.UTF32_BIN                    = 61;
exports.UTF16LE_BIN                  = 62;
exports.BINARY                       = 63;
exports.ARMSCII8_BIN                 = 64;
exports.ASCII_BIN                    = 65;
exports.CP1250_BIN                   = 66;
exports.CP1256_BIN                   = 67;
exports.CP866_BIN                    = 68;
exports.DEC8_BIN                     = 69;
exports.GREEK_BIN                    = 70;
exports.HEBREW_BIN                   = 71;
exports.HP8_BIN                      = 72;
exports.KEYBCS2_BIN                  = 73;
exports.KOI8R_BIN                    = 74;
exports.KOI8U_BIN                    = 75;
exports.LATIN2_BIN                   = 77;
exports.LATIN5_BIN                   = 78;
exports.LATIN7_BIN                   = 79;
exports.CP850_BIN                    = 80;
exports.CP852_BIN                    = 81;
exports.SWE7_BIN                     = 82;
exports.UTF8_BIN                     = 83;
exports.BIG5_BIN                     = 84;
exports.EUCKR_BIN                    = 85;
exports.GB2312_BIN                   = 86;
exports.GBK_BIN                      = 87;
exports.SJIS_BIN                     = 88;
exports.TIS620_BIN                   = 89;
exports.UCS2_BIN                     = 90;
exports.UJIS_BIN                     = 91;
exports.GEOSTD8_GENERAL_CI           = 92;
exports.GEOSTD8_BIN                  = 93;
exports.LATIN1_SPANISH_CI            = 94;
exports.CP932_JAPANESE_CI            = 95;
exports.CP932_BIN                    = 96;
exports.EUCJPMS_JAPANESE_CI          = 97;
exports.EUCJPMS_BIN                  = 98;
exports.CP1250_POLISH_CI             = 99;
exports.UTF16_UNICODE_CI             = 101;
exports.UTF16_ICELANDIC_CI           = 102;
exports.UTF16_LATVIAN_CI             = 103;
exports.UTF16_ROMANIAN_CI            = 104;
exports.UTF16_SLOVENIAN_CI           = 105;
exports.UTF16_POLISH_CI              = 106;
exports.UTF16_ESTONIAN_CI            = 107;
exports.UTF16_SPANISH_CI             = 108;
exports.UTF16_SWEDISH_CI             = 109;
exports.UTF16_TURKISH_CI             = 110;
exports.UTF16_CZECH_CI               = 111;
exports.UTF16_DANISH_CI              = 112;
exports.UTF16_LITHUANIAN_CI          = 113;
exports.UTF16_SLOVAK_CI              = 114;
exports.UTF16_SPANISH2_CI            = 115;
exports.UTF16_ROMAN_CI               = 116;
exports.UTF16_PERSIAN_CI             = 117;
exports.UTF16_ESPERANTO_CI           = 118;
exports.UTF16_HUNGARIAN_CI           = 119;
exports.UTF16_SINHALA_CI             = 120;
exports.UTF16_GERMAN2_CI             = 121;
exports.UTF16_CROATIAN_MYSQL561_CI   = 122;
exports.UTF16_UNICODE_520_CI         = 123;
exports.UTF16_VIETNAMESE_CI          = 124;
exports.UCS2_UNICODE_CI              = 128;
exports.UCS2_ICELANDIC_CI            = 129;
exports.UCS2_LATVIAN_CI              = 130;
exports.UCS2_ROMANIAN_CI             = 131;
exports.UCS2_SLOVENIAN_CI            = 132;
exports.UCS2_POLISH_CI               = 133;
exports.UCS2_ESTONIAN_CI             = 134;
exports.UCS2_SPANISH_CI              = 135;
exports.UCS2_SWEDISH_CI              = 136;
exports.UCS2_TURKISH_CI              = 137;
exports.UCS2_CZECH_CI                = 138;
exports.UCS2_DANISH_CI               = 139;
exports.UCS2_LITHUANIAN_CI           = 140;
exports.UCS2_SLOVAK_CI               = 141;
exports.UCS2_SPANISH2_CI             = 142;
exports.UCS2_ROMAN_CI                = 143;
exports.UCS2_PERSIAN_CI              = 144;
exports.UCS2_ESPERANTO_CI            = 145;
exports.UCS2_HUNGARIAN_CI            = 146;
exports.UCS2_SINHALA_CI              = 147;
exports.UCS2_GERMAN2_CI              = 148;
exports.UCS2_CROATIAN_MYSQL561_CI    = 149;
exports.UCS2_UNICODE_520_CI          = 150;
exports.UCS2_VIETNAMESE_CI           = 151;
exports.UCS2_GENERAL_MYSQL500_CI     = 159;
exports.UTF32_UNICODE_CI             = 160;
exports.UTF32_ICELANDIC_CI           = 161;
exports.UTF32_LATVIAN_CI             = 162;
exports.UTF32_ROMANIAN_CI            = 163;
exports.UTF32_SLOVENIAN_CI           = 164;
exports.UTF32_POLISH_CI              = 165;
exports.UTF32_ESTONIAN_CI            = 166;
exports.UTF32_SPANISH_CI             = 167;
exports.UTF32_SWEDISH_CI             = 168;
exports.UTF32_TURKISH_CI             = 169;
exports.UTF32_CZECH_CI               = 170;
exports.UTF32_DANISH_CI              = 171;
exports.UTF32_LITHUANIAN_CI          = 172;
exports.UTF32_SLOVAK_CI              = 173;
exports.UTF32_SPANISH2_CI            = 174;
exports.UTF32_ROMAN_CI               = 175;
exports.UTF32_PERSIAN_CI             = 176;
exports.UTF32_ESPERANTO_CI           = 177;
exports.UTF32_HUNGARIAN_CI           = 178;
exports.UTF32_SINHALA_CI             = 179;
exports.UTF32_GERMAN2_CI             = 180;
exports.UTF32_CROATIAN_MYSQL561_CI   = 181;
exports.UTF32_UNICODE_520_CI         = 182;
exports.UTF32_VIETNAMESE_CI          = 183;
exports.UTF8_UNICODE_CI              = 192;
exports.UTF8_ICELANDIC_CI            = 193;
exports.UTF8_LATVIAN_CI              = 194;
exports.UTF8_ROMANIAN_CI             = 195;
exports.UTF8_SLOVENIAN_CI            = 196;
exports.UTF8_POLISH_CI               = 197;
exports.UTF8_ESTONIAN_CI             = 198;
exports.UTF8_SPANISH_CI              = 199;
exports.UTF8_SWEDISH_CI              = 200;
exports.UTF8_TURKISH_CI              = 201;
exports.UTF8_CZECH_CI                = 202;
exports.UTF8_DANISH_CI               = 203;
exports.UTF8_LITHUANIAN_CI           = 204;
exports.UTF8_SLOVAK_CI               = 205;
exports.UTF8_SPANISH2_CI             = 206;
exports.UTF8_ROMAN_CI                = 207;
exports.UTF8_PERSIAN_CI              = 208;
exports.UTF8_ESPERANTO_CI            = 209;
exports.UTF8_HUNGARIAN_CI            = 210;
exports.UTF8_SINHALA_CI              = 211;
exports.UTF8_GERMAN2_CI              = 212;
exports.UTF8_CROATIAN_MYSQL561_CI    = 213;
exports.UTF8_UNICODE_520_CI          = 214;
exports.UTF8_VIETNAMESE_CI           = 215;
exports.UTF8_GENERAL_MYSQL500_CI     = 223;
exports.UTF8MB4_UNICODE_CI           = 224;
exports.UTF8MB4_ICELANDIC_CI         = 225;
exports.UTF8MB4_LATVIAN_CI           = 226;
exports.UTF8MB4_ROMANIAN_CI          = 227;
exports.UTF8MB4_SLOVENIAN_CI         = 228;
exports.UTF8MB4_POLISH_CI            = 229;
exports.UTF8MB4_ESTONIAN_CI          = 230;
exports.UTF8MB4_SPANISH_CI           = 231;
exports.UTF8MB4_SWEDISH_CI           = 232;
exports.UTF8MB4_TURKISH_CI           = 233;
exports.UTF8MB4_CZECH_CI             = 234;
exports.UTF8MB4_DANISH_CI            = 235;
exports.UTF8MB4_LITHUANIAN_CI        = 236;
exports.UTF8MB4_SLOVAK_CI            = 237;
exports.UTF8MB4_SPANISH2_CI          = 238;
exports.UTF8MB4_ROMAN_CI             = 239;
exports.UTF8MB4_PERSIAN_CI           = 240;
exports.UTF8MB4_ESPERANTO_CI         = 241;
exports.UTF8MB4_HUNGARIAN_CI         = 242;
exports.UTF8MB4_SINHALA_CI           = 243;
exports.UTF8MB4_GERMAN2_CI           = 244;
exports.UTF8MB4_CROATIAN_MYSQL561_CI = 245;
exports.UTF8MB4_UNICODE_520_CI       = 246;
exports.UTF8MB4_VIETNAMESE_CI        = 247;
exports.UTF8_GENERAL50_CI            = 253;

// short aliases
exports.ARMSCII8 = exports.ARMSCII8_GENERAL_CI;
exports.ASCII    = exports.ASCII_GENERAL_CI;
exports.BIG5     = exports.BIG5_CHINESE_CI;
exports.BINARY   = exports.BINARY;
exports.CP1250   = exports.CP1250_GENERAL_CI;
exports.CP1251   = exports.CP1251_GENERAL_CI;
exports.CP1256   = exports.CP1256_GENERAL_CI;
exports.CP1257   = exports.CP1257_GENERAL_CI;
exports.CP866    = exports.CP866_GENERAL_CI;
exports.CP850    = exports.CP850_GENERAL_CI;
exports.CP852    = exports.CP852_GENERAL_CI;
exports.CP932    = exports.CP932_JAPANESE_CI;
exports.DEC8     = exports.DEC8_SWEDISH_CI;
exports.EUCJPMS  = exports.EUCJPMS_JAPANESE_CI;
exports.EUCKR    = exports.EUCKR_KOREAN_CI;
exports.GB2312   = exports.GB2312_CHINESE_CI;
exports.GBK      = exports.GBK_CHINESE_CI;
exports.GEOSTD8  = exports.GEOSTD8_GENERAL_CI;
exports.GREEK    = exports.GREEK_GENERAL_CI;
exports.HEBREW   = exports.HEBREW_GENERAL_CI;
exports.HP8      = exports.HP8_ENGLISH_CI;
exports.KEYBCS2  = exports.KEYBCS2_GENERAL_CI;
exports.KOI8R    = exports.KOI8R_GENERAL_CI;
exports.KOI8U    = exports.KOI8U_GENERAL_CI;
exports.LATIN1   = exports.LATIN1_SWEDISH_CI;
exports.LATIN2   = exports.LATIN2_GENERAL_CI;
exports.LATIN5   = exports.LATIN5_TURKISH_CI;
exports.LATIN7   = exports.LATIN7_GENERAL_CI;
exports.MACCE    = exports.MACCE_GENERAL_CI;
exports.MACROMAN = exports.MACROMAN_GENERAL_CI;
exports.SJIS     = exports.SJIS_JAPANESE_CI;
exports.SWE7     = exports.SWE7_SWEDISH_CI;
exports.TIS620   = exports.TIS620_THAI_CI;
exports.UCS2     = exports.UCS2_GENERAL_CI;
exports.UJIS     = exports.UJIS_JAPANESE_CI;
exports.UTF16    = exports.UTF16_GENERAL_CI;
exports.UTF16LE  = exports.UTF16LE_GENERAL_CI;
exports.UTF8     = exports.UTF8_GENERAL_CI;
exports.UTF8MB4  = exports.UTF8MB4_GENERAL_CI;
exports.UTF32    = exports.UTF32_GENERAL_CI;

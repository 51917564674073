module.exports = UseOldPasswordPacket;
function UseOldPasswordPacket(options) {
  options = options || {};

  this.firstByte = options.firstByte || 0xfe;
}

UseOldPasswordPacket.prototype.parse = function(parser) {
  this.firstByte = parser.parseUnsignedNumber(1);
};

UseOldPasswordPacket.prototype.write = function(writer) {
  writer.writeUnsignedNumber(1, this.firstByte);
};

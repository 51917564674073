module.exports = AuthSwitchRequestPacket;
function AuthSwitchRequestPacket(options) {
  options = options || {};

  this.status         = 0xfe;
  this.authMethodName = options.authMethodName;
  this.authMethodData = options.authMethodData;
}

AuthSwitchRequestPacket.prototype.parse = function parse(parser) {
  this.status         = parser.parseUnsignedNumber(1);
  this.authMethodName = parser.parseNullTerminatedString();
  this.authMethodData = parser.parsePacketTerminatedBuffer();
};

AuthSwitchRequestPacket.prototype.write = function write(writer) {
  writer.writeUnsignedNumber(1, this.status);
  writer.writeNullTerminatedString(this.authMethodName);
  writer.writeBuffer(this.authMethodData);
};

module.exports = StatisticsPacket;
function StatisticsPacket() {
  this.message      = undefined;
}

StatisticsPacket.prototype.parse = function(parser) {
  this.message      = parser.parsePacketTerminatedString();

  var items = this.message.split(/\s\s/);
  for (var i = 0; i < items.length; i++) {
    var m = items[i].match(/^(.+)\:\s+(.+)$/);
    if (m !== null) {
      this[m[1].toLowerCase().replace(/\s/g, '_')] = Number(m[2]);
    }
  }
};

StatisticsPacket.prototype.write = function(writer) {
  writer.writeString(this.message);
};

var Buffer = require('safe-buffer').Buffer;
var Client = require('../constants/client');

module.exports = HandshakeInitializationPacket;
function HandshakeInitializationPacket(options) {
  options = options || {};

  this.protocolVersion     = options.protocolVersion;
  this.serverVersion       = options.serverVersion;
  this.threadId            = options.threadId;
  this.scrambleBuff1       = options.scrambleBuff1;
  this.filler1             = options.filler1;
  this.serverCapabilities1 = options.serverCapabilities1;
  this.serverLanguage      = options.serverLanguage;
  this.serverStatus        = options.serverStatus;
  this.serverCapabilities2 = options.serverCapabilities2;
  this.scrambleLength      = options.scrambleLength;
  this.filler2             = options.filler2;
  this.scrambleBuff2       = options.scrambleBuff2;
  this.filler3             = options.filler3;
  this.pluginData          = options.pluginData;
  this.protocol41          = options.protocol41;

  if (this.protocol41) {
    // force set the bit in serverCapabilities1
    this.serverCapabilities1 |= Client.CLIENT_PROTOCOL_41;
  }
}

HandshakeInitializationPacket.prototype.parse = function(parser) {
  this.protocolVersion     = parser.parseUnsignedNumber(1);
  this.serverVersion       = parser.parseNullTerminatedString();
  this.threadId            = parser.parseUnsignedNumber(4);
  this.scrambleBuff1       = parser.parseBuffer(8);
  this.filler1             = parser.parseFiller(1);
  this.serverCapabilities1 = parser.parseUnsignedNumber(2);
  this.serverLanguage      = parser.parseUnsignedNumber(1);
  this.serverStatus        = parser.parseUnsignedNumber(2);

  this.protocol41          = (this.serverCapabilities1 & (1 << 9)) > 0;

  if (this.protocol41) {
    this.serverCapabilities2 = parser.parseUnsignedNumber(2);
    this.scrambleLength      = parser.parseUnsignedNumber(1);
    this.filler2             = parser.parseFiller(10);
    // scrambleBuff2 should be 0x00 terminated, but sphinx does not do this
    // so we assume scrambleBuff2 to be 12 byte and treat the next byte as a
    // filler byte.
    this.scrambleBuff2       = parser.parseBuffer(12);
    this.filler3             = parser.parseFiller(1);
  } else {
    this.filler2             = parser.parseFiller(13);
  }

  if (parser.reachedPacketEnd()) {
    return;
  }

  // According to the docs this should be 0x00 terminated, but MariaDB does
  // not do this, so we assume this string to be packet terminated.
  this.pluginData = parser.parsePacketTerminatedString();

  // However, if there is a trailing '\0', strip it
  var lastChar = this.pluginData.length - 1;
  if (this.pluginData[lastChar] === '\0') {
    this.pluginData = this.pluginData.substr(0, lastChar);
  }
};

HandshakeInitializationPacket.prototype.write = function(writer) {
  writer.writeUnsignedNumber(1, this.protocolVersion);
  writer.writeNullTerminatedString(this.serverVersion);
  writer.writeUnsignedNumber(4, this.threadId);
  writer.writeBuffer(this.scrambleBuff1);
  writer.writeFiller(1);
  writer.writeUnsignedNumber(2, this.serverCapabilities1);
  writer.writeUnsignedNumber(1, this.serverLanguage);
  writer.writeUnsignedNumber(2, this.serverStatus);
  if (this.protocol41) {
    writer.writeUnsignedNumber(2, this.serverCapabilities2);
    writer.writeUnsignedNumber(1, this.scrambleLength);
    writer.writeFiller(10);
  }
  writer.writeNullTerminatedBuffer(this.scrambleBuff2);

  if (this.pluginData !== undefined) {
    writer.writeNullTerminatedString(this.pluginData);
  }
};

HandshakeInitializationPacket.prototype.scrambleBuff = function() {
  var buffer = null;

  if (typeof this.scrambleBuff2 === 'undefined') {
    buffer = Buffer.from(this.scrambleBuff1);
  } else {
    buffer = Buffer.allocUnsafe(this.scrambleBuff1.length + this.scrambleBuff2.length);
    this.scrambleBuff1.copy(buffer, 0);
    this.scrambleBuff2.copy(buffer, this.scrambleBuff1.length);
  }

  return buffer;
};

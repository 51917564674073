
/**
 * PoolSelector
 */
var PoolSelector = module.exports = {};

PoolSelector.RR = function PoolSelectorRoundRobin() {
  var index = 0;

  return function(clusterIds) {
    if (index >= clusterIds.length) {
      index = 0;
    }

    var clusterId = clusterIds[index++];

    return clusterId;
  };
};

PoolSelector.RANDOM = function PoolSelectorRandom() {
  return function(clusterIds) {
    return clusterIds[Math.floor(Math.random() * clusterIds.length)];
  };
};

PoolSelector.ORDER = function PoolSelectorOrder() {
  return function(clusterIds) {
    return clusterIds[0];
  };
};
